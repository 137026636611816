import { createAction } from '@reduxjs/toolkit';

const setAllProducts = createAction('products/setAllProducts');
const getProducts = createAction('products/getProducts');
const setProductPage = createAction('products/setProductPage');
const getProductsCategories = createAction('products/getProductsCategories');
const setFilterCategories = createAction('products/setFilterCategories');
const setSelectedFilter = createAction('products/setSelectedFilter');
const setProducts = createAction('products/setProducts');
const removeSelectedFilter = createAction('products/removeSelectedFilter');
const setFilterBrands = createAction('products/setFilterBrands');
const setInitialPricesRange = createAction('products/setInitialPricesRange');
const getProductsBrands = createAction('products/getProductsBrands');
const getAllProducts = createAction('products/getAllProducts');
const productsFiltersSearch = createAction('products/productsFiltersSearch');
const applyMassDiscount = createAction('products/applyMassDiscount');
const removeSelectedCodeFilter = createAction(
  'products/removeSelectedCodeFilter',
);
const setSelectedFilterPriceRange = createAction(
  'products/setSelectedFilterPriceRange',
);
const setProductsSorting = createAction('products/setProductsSorting');
const setCurrentProductsPage = createAction('products/setCurrentProductsPage');
const setProductsFilterCode = createAction('products/setProductsFilterCode');
const getPricesRanges = createAction('products/getPricesRanges');
const getInitialProductsSeries = createAction(
  'products/getInitialProductsSeries',
);
const saveProductInDB = createAction('products/saveProductInDB');
const setFilterSubCategories = createAction('products/setFilterSubCategories');
const publishProduct = createAction('products/publishProduct');
const updateProductsList = createAction('products/updateProductsList');
const updateProductInDB = createAction('products/updateProductInDB');
const getProduct = createAction('products/getProduct');
const setProductPopup = createAction('products/setProductPopup');
const setProductPopupDetailsValue = createAction(
  'products/setProductPopupDetailsValue',
);
const setSelectedProductsExtra = createAction(
  'products/setSelectedProductsExtra',
);

const getProductsPopupCategories = createAction(
  'products/getProductsPopupCategories',
);
const setProductsPopupCategories = createAction(
  'products/setProductsPopupCategories',
);
const getProductsPopupSubCategories = createAction(
  'products/getProductsPopupSubCategories',
);
const setProductsPopupSubCategories = createAction(
  'products/setProductsPopupSubCategories',
);
const getProductPopupBrands = createAction('products/getProductPopupBrands');
const setProductsPopupBrands = createAction('products/setProductsPopupBrands');
const uploadProductImage = createAction('products/uploadProductImage');
const setProductImage = createAction('products/setProductImage');
const updateProductImage = createAction('products/updateProductImage');
const removeProductImage = createAction('products/removeProductImage');
const downloadProductsCsv = createAction('products/downloadProductsCsv');
const changeProductQuantity = createAction('products/changeProductQuantity');
const setProductIsNew = createAction('products/setProductIsNew');
const setBulkProducts = createAction('products/setBulkProducts');

export {
  getProducts,
  setProductPage,
  setFilterCategories,
  setSelectedFilter,
  setProducts,
  removeSelectedFilter,
  setFilterBrands,
  setInitialPricesRange,
  setSelectedFilterPriceRange,
  setProductsSorting,
  setCurrentProductsPage,
  setProductsFilterCode,
  getInitialProductsSeries,
  getPricesRanges,
  getProductsCategories,
  getProductsBrands,
  removeSelectedCodeFilter,
  productsFiltersSearch,
  saveProductInDB,
  setFilterSubCategories,
  publishProduct,
  updateProductsList,
  updateProductInDB,
  getProduct,
  setProductPopup,
  setProductPopupDetailsValue,
  setSelectedProductsExtra,
  getProductsPopupCategories,
  setProductsPopupCategories,
  getProductsPopupSubCategories,
  setProductsPopupSubCategories,
  getProductPopupBrands,
  setProductsPopupBrands,
  uploadProductImage,
  setProductImage,
  updateProductImage,
  removeProductImage,
  downloadProductsCsv,
  changeProductQuantity,
  setProductIsNew,
  setBulkProducts,
  getAllProducts,
  setAllProducts,
  applyMassDiscount,
};
