/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import {
  setGeneralLoading,
  updateSubCategory,
  addSubCategory,
} from 'models/actions/generalActions';
import { allSubCategories } from 'models/selectors/generalSelectors';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const SubcategoryPopup = ({ open, subCategoryId, handleClose }) => {
  const dispatch = useDispatch();
  const subCategories = useSelector(allSubCategories);
  const [subCategoriesPopup, setSubCategoriesPopup] = useState({
    name: '',
    description: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
    setSubCategoriesPopup(
      subCategories?.results?.find(
        (subCategory) => subCategory?.id === subCategoryId,
      ) || subCategoriesPopup,
    );

    return () => {
      dispatch(setGeneralLoading(false));
      setSubCategoriesPopup({
        id: '',
        name: '',
        description: '',
      });
    };
  }, [subCategoryId]);

  const handleSubCategoryUpdate = () => {
    if (subCategoriesPopup && subCategoriesPopup?.name === '') {
      setError('Error');

      return false;
    }

    setError('');
    dispatch(setGeneralLoading(true));
    if (subCategoryId !== '') {
      dispatch(updateSubCategory(subCategoriesPopup));
    } else {
      dispatch(addSubCategory(subCategoriesPopup));
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>
            {subCategoryId === ''
              ? 'ΔΗΜΙΟΥΡΓΙΑ ΥΠΟΚΑΤΗΓΟΡΙΑΣ'
              : subCategoriesPopup?.name}
          </span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="subcategory-name">Όνομα</InputLabel>
                <Input
                  id="subcategory-name"
                  error={error !== ''}
                  value={
                    subCategoriesPopup?.name ? subCategoriesPopup?.name : ''
                  }
                  onChange={(e) =>
                    setSubCategoriesPopup({
                      ...subCategoriesPopup,
                      name: e.target.value,
                    })
                  }
                />
                {error && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε όνομα.
                  </span>
                )}
              </FormControl>
            </div>
            <div className="product-popup-field more-margin-bottom">
              <FormControl>
                <InputLabel htmlFor="product-popup-description">
                  Μικρή περιγραφή υποκατηγορίας
                </InputLabel>
                <Input
                  id="product-popup-description"
                  value={
                    subCategoriesPopup?.description
                      ? subCategoriesPopup?.description
                      : ''
                  }
                  minRows={4}
                  multiline={true}
                  maxRows={4}
                  onChange={(e) =>
                    setSubCategoriesPopup({
                      ...subCategoriesPopup,
                      description: e.target.value,
                    })
                  }
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handleSubCategoryUpdate} className="button add">
            {subCategoryId !== '' ? 'ΕΝΗΜΕΡΩΣΗ' : 'ΔΗΜΙΟΥΡΓΙΑ'}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SubcategoryPopup;
