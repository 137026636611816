/* eslint-disable react-hooks/exhaustive-deps */
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  setOfferTitleLoading,
  getOfferTitle,
} from 'models/actions/settingsActions';
import { offerTitle } from 'models/selectors/settingsSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import OfferTitle from './OfferTitle';
import OfferTitlePopup from './OfferTitlePopup';

const OffersTitle = () => {
  const dispatch = useDispatch();
  const [offersTitlePopupOpen, setOffersTitlePopupOpen] = useState({
    open: false,
    offerTitleOpenForEdit: '',
  });
  const myOfferTitle = useSelector(offerTitle)?.titles;
  const loading = useSelector(offerTitle)?.loading;

  useEffect(() => {
    dispatch(setOfferTitleLoading(true));
    dispatch(getOfferTitle());
  }, []);

  const toggleOTOpenWithId = (kwId) => {
    setOffersTitlePopupOpen({
      ...offersTitlePopupOpen,
      open: true,
      offerTitleOpenForEdit: kwId,
    });
  };

  return (
    <div>
      {offersTitlePopupOpen?.open && (
        <OfferTitlePopup
          open={offersTitlePopupOpen?.open}
          offerTitle={myOfferTitle?.find(
            (ot) => ot?.id === offersTitlePopupOpen?.offerTitleOpenForEdit,
          )}
          handleClose={() =>
            setOffersTitlePopupOpen({
              ...offersTitlePopupOpen,
              open: false,
              offerTitleOpenForEdit: '',
            })
          }
        />
      )}
      {!loading ? (
        <>
          <div className="products-crud-actions single">
            <span>{`(${myOfferTitle?.length || 0} τίτλος για Offers)`}</span>
          </div>
          {myOfferTitle?.length > 0 ? (
            <Table className="products-grid">
              <TableHead>
                <TableRow>
                  <TableCell>ΤΙΤΛΟΣ</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myOfferTitle?.map((ot) => (
                  <OfferTitle
                    key={`offertitle-${ot.id}`}
                    ot={ot}
                    toggleOT={toggleOTOpenWithId}
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="not-found">Δεν βρέθηκαν offer titles!</div>
          )}
        </>
      ) : (
        <>
          <Skeleton width="60%" />
          <Skeleton width="60%" />
          <Skeleton variant="rectangular" height={250} />
        </>
      )}
    </div>
  );
};

export default OffersTitle;
