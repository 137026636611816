/* eslint-disable react-hooks/exhaustive-deps */
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  setKeyWordsLoading,
  getAllKeyWords,
} from 'models/actions/settingsActions';
import { keyWordsSettings } from 'models/selectors/settingsSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import KeyWord from './KeyWord';
import KeyWordPopup from './KeyWordPopup';

const KeyWords = () => {
  const dispatch = useDispatch();
  const [keyWordsPopupOpen, setKeyWordsPopupOpen] = useState({
    open: false,
    keyWordsOpenForEdit: '',
  });
  const keyWords = useSelector(keyWordsSettings)?.words;
  const loading = useSelector(keyWordsSettings)?.loading;

  useEffect(() => {
    dispatch(setKeyWordsLoading(true));
    dispatch(getAllKeyWords());
  }, []);

  const toggleKWOpenWithId = (kwId) => {
    setKeyWordsPopupOpen({
      ...keyWordsPopupOpen,
      open: true,
      keyWordsOpenForEdit: kwId,
    });
  };

  return (
    <div>
      {keyWordsPopupOpen?.open && (
        <KeyWordPopup
          open={keyWordsPopupOpen?.open}
          keyWord={keyWords?.find(
            (kw) => kw?.id === keyWordsPopupOpen?.keyWordsOpenForEdit,
          )}
          handleClose={() =>
            setKeyWordsPopupOpen({
              ...keyWordsPopupOpen,
              open: false,
              keyWordsOpenForEdit: '',
            })
          }
        />
      )}
      {!loading ? (
        <>
          <div className="products-crud-actions single">
            <span>{`(${keyWords?.length || 0} σελίδες)`}</span>
          </div>
          {keyWords?.length > 0 ? (
            <Table className="products-grid">
              <TableHead>
                <TableRow>
                  <TableCell>ΤΙΤΛΟΣ ΣΕΛΙΔΑΣ</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {keyWords?.map((kw) => (
                  <KeyWord key={kw.id} kw={kw} toggleKW={toggleKWOpenWithId} />
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="not-found">Δεν βρέθηκαν σελίδες με keywords!</div>
          )}
        </>
      ) : (
        <>
          <Skeleton width="60%" />
          <Skeleton width="60%" />
          <Skeleton variant="rectangular" height={250} />
        </>
      )}
    </div>
  );
};

export default KeyWords;
