import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import CommonFilterSelect from 'components/common/CommonFilterSelect';
import { setGeneralLoading } from 'models/actions/generalActions';
import { ordersFiltersSearch } from 'models/actions/ordersActions';
import {
  ordersStatuses,
  selectedOrdersFilters,
} from 'models/selectors/ordersSelectors';
import {
  paymentSettings,
  shippingSettings,
} from 'models/selectors/settingsSelectors';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector, useDispatch } from 'react-redux';

const OrdersFilters = ({ setToggleValue }) => {
  const dispatch = useDispatch();
  const statuses = useSelector(ordersStatuses);
  const paymentMethods = useSelector(paymentSettings)?.methods;
  const shippingMethods = useSelector(shippingSettings)?.methods;
  const selectedFilters = useSelector(selectedOrdersFilters);
  const [ordersFilters, setOrdersFilters] = useState({
    selectedOrderCode: selectedFilters?.selectedOrderCode || '',
    selectedStatus: selectedFilters?.selectedStatus || '',
    selectedName: selectedFilters?.selectedName || '',
    selectedEmail: selectedFilters?.selectedEmail || '',
    selectedDate: selectedFilters?.selectedDate || '',
    selectedPaymentMethod: selectedFilters?.selectedPaymentMethod || '',
    selectedShippingMethod: selectedFilters?.selectedShippingMethod || '',
  });

  const {
    selectedOrderCode,
    selectedStatus,
    selectedName,
    selectedEmail,
    selectedDate,
    selectedPaymentMethod,
    selectedShippingMethod,
  } = selectedFilters;

  return (
    <>
      <div className="orders-filters">
        {(selectedOrderCode !== '' ||
          selectedStatus ||
          selectedPaymentMethod ||
          selectedShippingMethod ||
          selectedName !== '' ||
          selectedEmail !== '' ||
          selectedDate !== '') && (
          <Tooltip title="Καθαρισμός φίλτρων">
            <button
              className="button lightblue radius"
              onClick={() => {
                dispatch(setGeneralLoading(true));
                dispatch(
                  ordersFiltersSearch({
                    selectedOrderCode: '',
                    selectedStatus: '',
                    selectedName: '',
                    selectedEmail: '',
                    selectedDate: '',
                    selectedPaymentMethod: '',
                    selectedShippingMethod: '',
                  }),
                );
              }}>
              <i
                className="icon-arrows-cw"
                onClick={setToggleValue('right', false)}
              />
            </button>
          </Tooltip>
        )}
        <div className="products-filter">
          <FormControl>
            <InputLabel htmlFor="filterOrdersByOrderCode">
              Κωδικός παραγγελίας
            </InputLabel>
            <Input
              id="filterOrdersByOrderCode"
              value={ordersFilters?.selectedOrderCode}
              onChange={(e) => {
                setOrdersFilters({
                  ...ordersFilters,
                  selectedOrderCode: e.target.value,
                });
              }}
            />
          </FormControl>
        </div>
        <div className="products-filter">
          <CommonFilterSelect
            label="Κατάσταση"
            filters={statuses}
            type="selectedStatus"
            localFilters={ordersFilters}
            setFilters={setOrdersFilters}
          />
        </div>
        <div className="products-filter">
          <CommonFilterSelect
            label="Τρόπος πληρωμής"
            filters={paymentMethods}
            type="selectedPaymentMethod"
            localFilters={ordersFilters}
            setFilters={setOrdersFilters}
          />
        </div>
        <div className="products-filter">
          <CommonFilterSelect
            label="Τρόπος αποστολής"
            filters={shippingMethods}
            type="selectedShippingMethod"
            localFilters={ordersFilters}
            setFilters={setOrdersFilters}
          />
        </div>
        <div className="products-filter">
          <FormControl>
            <InputLabel htmlFor="filterOrdersByName">Ονομα</InputLabel>
            <Input
              id="filterOrdersByName"
              value={ordersFilters?.selectedName}
              onChange={(e) => {
                setOrdersFilters({
                  ...ordersFilters,
                  selectedName: e.target.value,
                });
              }}
            />
          </FormControl>
        </div>
        <div className="products-filter">
          <FormControl>
            <InputLabel htmlFor="filterOrdersByEmail">Email</InputLabel>
            <Input
              id="filterOrdersByEmail"
              value={ordersFilters?.selectedEmail}
              onChange={(e) => {
                setOrdersFilters({
                  ...ordersFilters,
                  selectedEmail: e.target.value,
                });
              }}
            />
          </FormControl>
        </div>
        <div className="products-filter static-label">
          <FormControl>
            <InputLabel htmlFor="filterOrdersByDate">Ημερομηνία</InputLabel>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={
                ordersFilters?.selectedDate !== ''
                  ? new Date(ordersFilters?.selectedDate)
                  : ''
              }
              onChange={(date) =>
                setOrdersFilters({
                  ...ordersFilters,
                  selectedDate: new Date(date),
                })
              }
            />
          </FormControl>
        </div>
      </div>
      <div className="filter-buttons">
        <button className="button delete">
          <span
            onClick={setToggleValue('right', false)}
            className="display-block">
            ΑΚΥΡΩΣΗ
          </span>
        </button>
        <button
          className="button lightblue"
          onClick={() => {
            dispatch(setGeneralLoading(true));
            dispatch(ordersFiltersSearch(ordersFilters));
          }}>
          <span
            onClick={setToggleValue('right', false)}
            className="display-block">
            ΑΝΑΖΗΤΗΣΗ
          </span>
        </button>
      </div>
    </>
  );
};

export default OrdersFilters;
