/* eslint-disable max-len */
import makeRequest from 'library/makeRequest';
import {
  setGeneralLoading,
  toggleShowAlert,
} from 'models/actions/generalActions';
import { getOrderPopup, getOrders } from 'models/actions/ordersActions';
import { setProductNewQuantityInOrder } from 'models/actions/ordersActions';
import {
  getProducts,
  setCurrentProductsPage,
  setProducts,
  setProductsSorting,
  getInitialProductsSeries,
  getPricesRanges,
  setInitialPricesRange,
  getProductsCategories,
  setFilterCategories,
  getProductsBrands,
  setFilterBrands,
  removeSelectedFilter,
  removeSelectedCodeFilter,
  productsFiltersSearch,
  saveProductInDB,
  setFilterSubCategories,
  publishProduct,
  updateProductsList,
  updateProductInDB,
  downloadProductsCsv,
  changeProductQuantity,
  setProductIsNew,
  setBulkProducts,
  //POPUP
  getProduct,
  setProductPopup,
  getProductsPopupCategories,
  setProductsPopupCategories,
  getProductsPopupSubCategories,
  setProductsPopupSubCategories,
  getProductPopupBrands,
  setProductsPopupBrands,
  updateProductImage,
  setProductImage,
  removeProductImage,
  getAllProducts,
  setAllProducts,
  applyMassDiscount,
} from 'models/actions/productsActions';
import { productImage } from 'models/selectors/productsSelectors';
import { token } from 'models/selectors/userSelectors';
import { combineEpics, ofType } from 'redux-observable';
import { from } from 'rxjs';
import {
  mergeMap,
  concatMap,
  withLatestFrom,
  map,
  debounceTime,
  tap,
  delay,
} from 'rxjs/operators';

import catchErrorOperator from './operators/catchErrorOperator';

const getInitialProductsSeriesEpic = (action$) =>
  action$.pipe(ofType(getInitialProductsSeries.type), map(getPricesRanges));

const applyMassDiscountEpic = (action$, state$) =>
  action$.pipe(
    ofType(applyMassDiscount.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          'products/admin/setMassDiscount',
          'POST',
          JSON.stringify({
            products: payload?.products,
            discount: Number(payload?.discount),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: payload.error,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [setGeneralLoading(false)];
        }),
        delay(500),
        map(() =>
          toggleShowAlert({
            message: `${payload?.message}`,
            show: true,
            type: 'success',
          }),
        ),
        delay(500),
        map(() => getInitialProductsSeries()),
        catchErrorOperator(true),
      ),
    ),
  );

const getPricesRangesEpic = (action$) =>
  action$.pipe(
    ofType(getPricesRanges.type),
    mergeMap(() =>
      from(makeRequest('prices', 'GET', '')).pipe(
        concatMap((payload) => [
          setInitialPricesRange(payload),
          toggleShowAlert({ message: '', show: false, type: 'error' }),
          getProductsCategories(),
        ]),
        catchErrorOperator(true),
      ),
    ),
  );

const getProductsCategoriesEpic = (action$) =>
  action$.pipe(
    ofType(getProductsCategories.type),
    mergeMap(() =>
      from(makeRequest('categories', 'GET', '')).pipe(
        concatMap((payload) => [
          setFilterCategories(payload),
          toggleShowAlert({ message: '', show: false, type: 'error' }),
        ]),
        catchErrorOperator(false),
      ),
    ),
  );

const getProductsSubCategoriesEpic = (action$) =>
  action$.pipe(
    ofType(setFilterCategories.type),
    mergeMap(() =>
      from(makeRequest('subcategories', 'GET', '')).pipe(
        concatMap((payload) => [
          setFilterSubCategories(payload),
          toggleShowAlert({ message: '', show: false, type: 'error' }),
          getProductsBrands(),
        ]),
        catchErrorOperator(false),
      ),
    ),
  );

const getProductsBrandsEpic = (action$) =>
  action$.pipe(
    ofType(getProductsBrands.type),
    mergeMap(() =>
      from(makeRequest('brands', 'GET', '')).pipe(
        concatMap((payload) => [
          setFilterBrands(payload),
          toggleShowAlert({ message: '', show: false, type: 'error' }),
          getProducts(),
        ]),
        catchErrorOperator(false),
      ),
    ),
  );

const getProductsEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      getProducts.type,
      setCurrentProductsPage.type,
      setProductsSorting.type,
      removeSelectedFilter.type,
      removeSelectedCodeFilter.type,
      productsFiltersSearch.type,
      updateProductsList.type,
    ),
    withLatestFrom(state$),
    mergeMap(
      ([
        ,
        {
          productsReducer: { filters, sorting, catalog },
        },
      ]) => {
        let requestCategory = '';
        let requestSubCategory = '';
        let requestBrand = '';
        let requestPriceRange = '';
        let requestCode = '';
        let requestIsActive = '';
        let requestIsNew = '';
        const requestPage = catalog.pagination.currentPage;

        const {
          selectedCategory,
          selectedSubCategory,
          selectedBrand,
          selectedPriceRange,
          selectedCode,
          selectedIsActive,
          selectedIsNew,
        } = filters;

        if (selectedCategory) {
          requestCategory = selectedCategory;
        }
        if (selectedSubCategory) {
          requestSubCategory = selectedSubCategory;
        }

        if (selectedBrand) {
          requestBrand = selectedBrand;
        }

        if (selectedPriceRange.length > 0) {
          requestPriceRange = selectedPriceRange.join();
        }

        if (selectedCode !== '') {
          requestCode = selectedCode;
        }

        if (selectedIsActive !== '' && selectedIsActive) {
          requestIsActive = selectedIsActive === 1 ? 1 : 0;
        }

        if (selectedIsNew !== '' && selectedIsNew) {
          requestIsNew = selectedIsNew === 1 ? 1 : 0;
        }

        return from(
          makeRequest(
            `products/admin?isNew=${requestIsNew}&active=${requestIsActive}&category=${requestCategory}&subCategory=${requestSubCategory}&brand=${requestBrand}&prices=${requestPriceRange}&page=${requestPage}&sort=${sorting}&code=${requestCode}`,
            'GET',
            '',
            token(state$.value),
          ),
        ).pipe(
          concatMap((payload) => [
            setProducts(payload),
            setGeneralLoading(false),
          ]),
          catchErrorOperator(true),
        );
      },
    ),
  );

const saveProductInDBEpic = (action$, state$) =>
  action$.pipe(
    ofType(saveProductInDB.type),
    mergeMap(({ payload: { productDetails, largeDescription, image } }) => {
      const {
        product,
        products_categories,
        products_subcategories,
        products_brands,
      } = productDetails;

      const newPayload = {
        product: {
          ...product,
          initialPrice: product?.initialPrice
            ? Number(product?.initialPrice)
            : 0,
          price: Number(product?.price),
          stock: Number(product?.stock ? product?.stock : 0),
          isActive: Boolean(product?.isActive),
          imgHref: 'https://placedog.net/400?r',
          productLargeDescription: largeDescription,
        },
        categories: products_categories?.map((c) => c?.id).join(','),
        subCategories: products_subcategories?.map((s) => s?.id).join(','),
        brands: products_brands?.map((b) => b?.id).join(','),
        image,
      };

      return from(
        makeRequest(
          'products/admin/add',
          'POST',
          JSON.stringify(newPayload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
              getProducts(),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getProducts(),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      );
    }),
  );

const updateProductInDBEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateProductInDB.type),
    mergeMap(({ payload: { productDetails, largeDescription, image } }) => {
      const {
        product,
        products_categories,
        products_subcategories,
        products_brands,
      } = productDetails;

      const newPayload = {
        productId: product?.productId,
        product: {
          ...product,
          isActive: Boolean(product?.isActive),
          productLargeDescription: largeDescription,
          related_products: product.related_products,
        },
        categories: products_categories?.map((c) => c?.id).join(','),
        subCategories: products_subcategories?.map((s) => s?.id).join(','),
        brands: products_brands?.map((b) => b?.id).join(','),
        image: `${image}#`,
      };

      return from(
        makeRequest(
          'products/admin/update',
          'POST',
          JSON.stringify(newPayload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
              getProducts(),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getProducts(),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      );
    }),
  );

const publishProductEpic = (action$, state$) =>
  action$.pipe(
    ofType(publishProduct.type),
    mergeMap(({ payload }) => {
      const { id, active } = payload;

      return from(
        makeRequest(
          'products/admin/publish',
          'POST',
          JSON.stringify({ id, active }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload.error}`,
                show: true,
                type: 'error',
              }),
              setGeneralLoading(false),
            ];
          }

          return [
            updateProductsList(payload),
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      );
    }),
  );

const setBulkProductsEpic = (action$, state$) =>
  action$.pipe(
    ofType(setBulkProducts.type),
    mergeMap(({ payload }) => {
      const { bulkProducts } = payload;

      return from(
        makeRequest(
          'products/admin/bulk',
          'POST',
          JSON.stringify({ bulkProducts }, null, 2),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload.error}`,
                show: true,
                type: 'error',
              }),
              setGeneralLoading(false),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      );
    }),
  );

const getAllProductsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllProducts.type),
    mergeMap(() =>
      from(
        makeRequest(
          `products/admin/getAllProducts`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          return [setAllProducts(payload?.products)];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const setProductIsNewEpic = (action$, state$) =>
  action$.pipe(
    ofType(setProductIsNew.type),
    mergeMap(({ payload }) => {
      const { id, isNew } = payload;

      return from(
        makeRequest(
          'products/admin/new',
          'POST',
          JSON.stringify({ id, isNew }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload.error}`,
                show: true,
                type: 'error',
              }),
              setGeneralLoading(false),
            ];
          }

          return [
            updateProductsList(payload),
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      );
    }),
  );

const getProductEpic = (action$, state$) =>
  action$.pipe(
    ofType(getProduct.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `products/admin/product/${payload}`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          return [setProductPopup(payload), setGeneralLoading(false)];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getProductsPopupCategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(getProductsPopupCategories.type),
    debounceTime(500),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `categories/admin/search?cat=${payload}`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => [
          setProductsPopupCategories(payload?.result),
          setGeneralLoading(false),
        ]),
        catchErrorOperator(true),
      ),
    ),
  );

const getProductsPopupSubCategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(getProductsPopupSubCategories.type),
    debounceTime(500),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `subcategories/admin/search?subcat=${payload}`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => [
          setProductsPopupSubCategories(payload?.result),
          setGeneralLoading(false),
        ]),
        catchErrorOperator(true),
      ),
    ),
  );

const getProductPopupBrandsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getProductPopupBrands.type),
    debounceTime(500),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `brands/admin/search?brand=${payload}`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => [
          setProductsPopupBrands(payload?.result),
          setGeneralLoading(false),
        ]),
        catchErrorOperator(true),
      ),
    ),
  );

const downloadProductsCsvEpic = (action$, state$) =>
  action$.pipe(
    ofType(downloadProductsCsv.type),
    mergeMap(() =>
      from(
        fetch(`${process.env.REACT_APP_API}/products/admin/download`, {
          method: 'POST',
          body: {},
          headers: {
            Authorization: `Bearer ${token(state$.value)}`,
          },
        }).then((response) => response.blob()),
      ).pipe(
        tap((payload) => {
          // 2. Create blob link to download
          const url = window.URL.createObjectURL(new Blob([payload]));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', `products.csv`);
          // 3. Append to html page
          document.body.appendChild(link);
          // 4. Force download
          link.click();
          // 5. Clean up and remove the link
          link.parentNode.removeChild(link);
        }),
        concatMap(() => [setGeneralLoading(false)]),
        catchErrorOperator(true),
      ),
    ),
  );

const changeProductQuantityEpic = (action$, state$) =>
  action$.pipe(
    ofType(changeProductQuantity.type),
    withLatestFrom(state$),
    map(
      ([
        {
          payload: { id, total, orderId },
        },
        {
          ordersReducer: {
            singleOrder: { products },
          },
        },
      ]) => {
        const newProducts = products.map((product) =>
          product?.productId !== id
            ? { ...product }
            : { ...product, items: total },
        );

        return setProductNewQuantityInOrder({
          products: newProducts,
          id,
          total,
          orderId,
        });
      },
    ),
  );

const setProductNewQuantityInOrderEpic = (action$, state$) =>
  action$.pipe(
    ofType(setProductNewQuantityInOrder.type),
    debounceTime(500),
    mergeMap(({ payload: { id, total, orderId } }) =>
      from(
        makeRequest(
          'order/admin/product/updatetotals',
          'POST',
          JSON.stringify({
            productId: Number(id),
            total: Number(total),
            orderId: Number(orderId),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
              getOrderPopup(Number(orderId)),
              getOrders(),
              setGeneralLoading(false),
            ];
          }

          return [
            getOrderPopup(Number(orderId)),
            getOrders(),
            setGeneralLoading(false),
          ];
        }),
      ),
    ),
  );

const updateProductImageEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateProductImage.type),
    map(({ payload }) => {
      const currentProductImages = productImage(state$.value);

      const newProductImage = `${currentProductImages}${payload}#`;

      return setProductImage(newProductImage);
    }),
  );

const removeProductImageEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeProductImage.type),
    mergeMap(({ payload: { id, image } }) => {
      return from(
        makeRequest(
          'products/admin/update/onlyimage',
          'POST',
          JSON.stringify({
            id: Number(id),
            image,
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
              setGeneralLoading(false),
            ];
          }

          return [getProduct(id), setGeneralLoading(false)];
        }),
      );
    }),
  );

export {
  getProductsEpic,
  getInitialProductsSeriesEpic,
  getPricesRangesEpic,
  getProductsCategoriesEpic,
  getProductsBrandsEpic,
  saveProductInDBEpic,
  getProductsSubCategoriesEpic,
  publishProductEpic,
  updateProductInDBEpic,
  downloadProductsCsvEpic,
  changeProductQuantityEpic,
  setProductNewQuantityInOrderEpic,
  setProductIsNewEpic,
  setBulkProductsEpic,
  //POPUP
  getProductEpic,
  getProductsPopupCategoriesEpic,
  getProductsPopupSubCategoriesEpic,
  getProductPopupBrandsEpic,
  updateProductImageEpic,
  removeProductImageEpic,
  getAllProductsEpic,
  applyMassDiscountEpic,
};

export default combineEpics(
  getProductsEpic,
  getInitialProductsSeriesEpic,
  getPricesRangesEpic,
  getProductsCategoriesEpic,
  getProductsBrandsEpic,
  saveProductInDBEpic,
  getProductsSubCategoriesEpic,
  publishProductEpic,
  updateProductInDBEpic,
  downloadProductsCsvEpic,
  changeProductQuantityEpic,
  setProductNewQuantityInOrderEpic,
  setProductIsNewEpic,
  setBulkProductsEpic,
  //POPUP
  getProductEpic,
  getProductsPopupCategoriesEpic,
  getProductsPopupSubCategoriesEpic,
  getProductPopupBrandsEpic,
  updateProductImageEpic,
  removeProductImageEpic,
  getAllProductsEpic,
  applyMassDiscountEpic,
);
