/* eslint-disable react-hooks/exhaustive-deps */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  getAllSubCategories,
  removeSubCategory,
  setGeneralLoading,
} from 'models/actions/generalActions';
import { allSubCategories } from 'models/selectors/generalSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Subcategory from './Subcategory';
import SubcategoryPopup from './SubcategoryPopup';

const Subcategories = () => {
  const dispatch = useDispatch();
  const subCategories = useSelector(allSubCategories);
  const [subCategoryPopupOpen, setSubCategoryPopupOpen] = useState({
    open: false,
    subCategoryOpenForEdit: '',
  });

  useEffect(() => {
    dispatch(setGeneralLoading(true));
    dispatch(getAllSubCategories());
  }, []);

  const toggleSubCategoryOpenWithId = (subCategoryId) => {
    setSubCategoryPopupOpen({
      ...subCategoryPopupOpen,
      open: true,
      subCategoryOpenForEdit: subCategoryId,
    });
  };

  const toggleSubCategoryWithRemove = (subCategoryId, isActive) => {
    setSubCategoryPopupOpen({
      ...subCategoryPopupOpen,
      open: false,
      subCategoryOpenForEdit: '',
    });
    dispatch(setGeneralLoading(true));
    dispatch(removeSubCategory({ id: subCategoryId, isActive }));
  };

  return (
    <div className="content general-page">
      {subCategoryPopupOpen?.open && (
        <SubcategoryPopup
          open={subCategoryPopupOpen?.open}
          subCategoryId={subCategoryPopupOpen?.subCategoryOpenForEdit}
          handleClose={() =>
            setSubCategoryPopupOpen({
              ...subCategoryPopupOpen,
              open: false,
              subCategoryOpenForEdit: '',
            })
          }
        />
      )}
      <div className="row">
        <div className="wrapper">
          <div className="page-title">Υποκατηγορίες</div>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <div className="products-crud-actions double">
            <span>{`(${subCategories.total} υποκατηγορίες)`}</span>
            <button
              className="button add"
              onClick={() =>
                setSubCategoryPopupOpen({
                  ...subCategoryPopupOpen,
                  open: true,
                  subCategoryOpenForEdit: '',
                })
              }>
              ΔΗΜΙΟΥΡΓΙΑ
            </button>
          </div>
        </div>
      </div>
      {subCategories.total > 0 ? (
        <>
          <div className="row">
            <div className="wrapper">
              <div className="products">
                <Table className="products-grid">
                  <TableHead>
                    <TableRow>
                      <TableCell>ΟΝΟΜΑ</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subCategories?.results?.map((subCategory) => (
                      <Subcategory
                        toggleSubCategory={toggleSubCategoryOpenWithId}
                        toggleSubCategoryWithRemove={() =>
                          toggleSubCategoryWithRemove(
                            subCategory?.id,
                            !subCategory?.isActive,
                          )
                        }
                        subCategory={subCategory}
                        key={subCategory?.id}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="not-found">Δεν βρέθηκαν υποκατηγορίες!</div>
      )}
    </div>
  );
};

export default Subcategories;
