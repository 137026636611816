/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { setGeneralLoading } from 'models/actions/generalActions';
import { updateCoupon, addCoupon } from 'models/actions/settingsActions';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const CouponPopup = ({ open, id, coupon, handleClose }) => {
  const dispatch = useDispatch();

  const [couponPopup, setCouponPopup] = useState({
    code: '',
    hours: 0,
    discount: 0,
  });

  const [error, setError] = useState('');

  useEffect(() => {
    setCouponPopup(coupon || couponPopup);

    return () => {
      setCouponPopup({
        code: '',
        hours: 0,
        discount: 0,
      });
      dispatch(setGeneralLoading(false));
    };
  }, []);

  const handleCouponUpdate = () => {
    if (couponPopup && couponPopup?.code === '') {
      setError('Error');

      return false;
    }

    setError('');
    dispatch(setGeneralLoading(true));
    if (id !== '') {
      dispatch(
        updateCoupon({
          ...couponPopup,
          id,
        }),
      );
    } else {
      dispatch(addCoupon({ ...couponPopup }));
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>{id === '' ? 'ΔΗΜΙΟΥΡΓΙΑ ΚΟΥΠΟΝΙΟΥ' : couponPopup?.code}</span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="coupon-code">Κωδικός</InputLabel>
                <Input
                  id="coupon-code"
                  error={error !== ''}
                  value={couponPopup?.code ? couponPopup?.code : ''}
                  onChange={(e) =>
                    setCouponPopup({
                      ...couponPopup,
                      code: e.target.value,
                    })
                  }
                />
                {error && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε κωδικό.
                  </span>
                )}
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="coupon-hours">Διαθέσιμο για</InputLabel>
                <Input
                  id="coupon-hours"
                  type="number"
                  inputProps={{ min: 0 }}
                  value={couponPopup?.hours ? couponPopup?.hours : 0}
                  onChange={(e) =>
                    setCouponPopup({
                      ...couponPopup,
                      hours: Number(e.target.value),
                    })
                  }
                />
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="coupon-discount">
                  Ποσοστιαία έκπτωση
                </InputLabel>
                <Input
                  id="coupon-discount"
                  type="number"
                  inputProps={{ min: 0 }}
                  value={couponPopup?.discount ? couponPopup?.discount : 0}
                  onChange={(e) =>
                    setCouponPopup({
                      ...couponPopup,
                      discount: Number(e.target.value),
                    })
                  }
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handleCouponUpdate} className="button add">
            {id !== '' ? 'ΕΝΗΜΕΡΩΣΗ' : 'ΔΗΜΙΟΥΡΓΙΑ'}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CouponPopup;
