/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import formatMoney from 'library/formatMoney';
import {
  getDashBoard,
  setDashboardYear,
} from 'models/actions/dashboardActions';
import { setGeneralLoading } from 'models/actions/generalActions';
import { downloadNewsletterUsers } from 'models/actions/userActions';
import {
  newsletterUsers,
  registeredUsers,
  totalOrders,
  totalProducts,
  totalOrdersPending,
  netOrders,
  pmsmTotals,
  dashboardYear,
  couponsDiscountsTotal,
} from 'models/selectors/dashboardSelectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DashBoardGraph from './DashBoardGraph';

const Dashboard = () => {
  const dispatch = useDispatch();
  const yearSelected = useSelector(dashboardYear);
  const registUsers = useSelector(registeredUsers);
  const newslUsers = useSelector(newsletterUsers);
  const allOrders = useSelector(totalOrders);
  const allProducts = useSelector(totalProducts);
  const allOrdersPending = useSelector(totalOrdersPending);
  const allNetOrders = useSelector(netOrders);
  const allPmsmTotals = useSelector(pmsmTotals);
  const allCouponsDiscountsTotal = useSelector(couponsDiscountsTotal);
  const currentYear = new Date().getFullYear();
  const minimumYear = currentYear - 10;

  useEffect(() => {
    dispatch(setGeneralLoading(true));
    dispatch(getDashBoard());
  }, []);

  console.log(allCouponsDiscountsTotal);

  return (
    <div className="content dashboard-page">
      <div className="row">
        <div className="wrapper">
          <div className="dashboard-container">
            <div className="dashboard-section graph">
              <div className="dashboard-box net-orders">
                <div>
                  <FormControl
                    variant="standard"
                    className="select-year-dashboard">
                    <InputLabel id="select-year">Έτος</InputLabel>
                    <Select
                      labelId="select-year"
                      autoWidth
                      id="select-year"
                      value={yearSelected}
                      label="Έτος"
                      onChange={(e) => {
                        dispatch(setGeneralLoading(true));
                        dispatch(setDashboardYear(e.target.value));
                      }}>
                      {Array(currentYear - minimumYear + 1)
                        .fill()
                        .map((_, idx) => minimumYear + idx)
                        ?.reverse()
                        ?.map((item) => (
                          <MenuItem key={item} value={Number(item)}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="dashboard-box-title">
                  Συνολικό κέρδος {yearSelected}
                </div>
                <div>
                  <div className="dashboard-box-value">
                    {formatMoney.format(allNetOrders)}
                  </div>
                  <div className="dashboard-box-small-value">
                    Εξαιρούνται κόστη μεταφοράς και αντικαταβολής:&nbsp;
                    <strong>{formatMoney.format(allPmsmTotals)}</strong>
                  </div>
                  <div className="dashboard-box-small-value">
                    Εξαιρούνται εκπτώσεις από κουπόνια:&nbsp;
                    <strong>
                      {formatMoney.format(allCouponsDiscountsTotal)}
                    </strong>
                  </div>
                </div>
                <i>€</i>
              </div>
              <DashBoardGraph />
            </div>
            <div className="dashboard-section boxes">
              <div className="dashboard-box total-orders">
                <div className="dashboard-box-title">Παραγγελίες</div>
                <div>
                  <div className="dashboard-box-value">{allOrders}</div>
                  <div className="dashboard-box-small-value">
                    Εκκρεμμούν: {allOrdersPending}
                  </div>
                </div>
                <i className="icon-basket" />
              </div>
              <div className="dashboard-box total-products">
                <div className="dashboard-box-title">Προϊόντα</div>
                <div className="dashboard-box-value">{allProducts}</div>
                <i className="icon-sitemap" />
              </div>
              <div className="dashboard-box registered-users">
                <div className="dashboard-box-title">Εγγεγραμμένοι χρήστες</div>
                <div className="dashboard-box-value">{registUsers}</div>
                <i className="icon-users" />
              </div>
              <div className="dashboard-box newsletter-users">
                <div className="dashboard-box-title">Χρήστες newsletter</div>
                <div className="download-users">
                  <div className="dashboard-box-value">{newslUsers}</div>
                  <div className="dashboard-box-small-value">
                    <Tooltip title="Κατεβάστε σε αρχείο">
                      <i
                        onClick={() => {
                          dispatch(setGeneralLoading(true));
                          dispatch(downloadNewsletterUsers());
                        }}
                        className="icon-file-excel"
                      />
                    </Tooltip>
                  </div>
                </div>
                <i className="icon-mail" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
