/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  updatePaymentMethod,
  addPaymentMethod,
} from 'models/actions/settingsActions';
import { paymentSettings } from 'models/selectors/settingsSelectors';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const PaymentMethodPopup = ({ open, pmId, handleClose }) => {
  const dispatch = useDispatch();
  const costRef = useRef();
  const paymentMethods = useSelector(paymentSettings)?.methods;
  const [paymentMethodPopup, setPaymentMethodPopup] = useState({
    name: '',
    description: '',
    cost: 0,
  });
  const [error, setError] = useState('');

  useEffect(() => {
    setPaymentMethodPopup(
      paymentMethods?.find((pm) => pm?.id === pmId) || paymentMethodPopup,
    );

    return () => {
      setPaymentMethodPopup({
        id: '',
        name: '',
        description: '',
        cost: 0,
      });
      dispatch(setGeneralLoading(false));
    };
  }, [pmId]);

  const handlePaymentMethodUpdate = () => {
    if (paymentMethodPopup && paymentMethodPopup?.name === '') {
      setError('Error');

      return false;
    }

    setError('');
    dispatch(setGeneralLoading(true));
    if (pmId !== '') {
      dispatch(updatePaymentMethod(paymentMethodPopup));
    } else {
      dispatch(addPaymentMethod(paymentMethodPopup));
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>
            {pmId === ''
              ? 'ΔΗΜΙΟΥΡΓΙΑ ΤΡΟΠΟΥ ΠΛΗΡΩΜΗΣ'
              : paymentMethodPopup?.name}
          </span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="payment-method-name">Όνομα</InputLabel>
                <Input
                  id="payment-method-name"
                  error={error !== ''}
                  value={
                    paymentMethodPopup?.name ? paymentMethodPopup?.name : ''
                  }
                  onChange={(e) =>
                    setPaymentMethodPopup({
                      ...paymentMethodPopup,
                      name: e.target.value,
                    })
                  }
                />
                {error !== '' && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε όνομα.
                  </span>
                )}
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <InputLabel
                  htmlFor="payment-method-popup-stock"
                  className="differentLabel">
                  Κόστος
                </InputLabel>
                <Input
                  id="payment-method-popup-stock"
                  inputRef={costRef}
                  value={
                    paymentMethodPopup?.cost ? paymentMethodPopup?.cost : 0
                  }
                  type="number"
                  inputProps={{ min: 0 }}
                  onClick={() => costRef.current.select()}
                  onChange={(e) =>
                    setPaymentMethodPopup({
                      ...paymentMethodPopup,
                      cost: e.target.value,
                    })
                  }
                />
              </FormControl>
            </div>
            <div className="product-popup-field more-margin-bottom">
              <FormControl>
                <InputLabel htmlFor="payment-method-popup-description">
                  Μικρή περιγραφή τρόπου πληρωμής
                </InputLabel>
                <Input
                  id="payment-method-popup-description"
                  value={
                    paymentMethodPopup?.description
                      ? paymentMethodPopup?.description
                      : ''
                  }
                  minRows={4}
                  multiline={true}
                  maxRows={4}
                  onChange={(e) =>
                    setPaymentMethodPopup({
                      ...paymentMethodPopup,
                      description: e.target.value,
                    })
                  }
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handlePaymentMethodUpdate} className="button add">
            {pmId !== '' ? 'ΕΝΗΜΕΡΩΣΗ' : 'ΔΗΜΙΟΥΡΓΙΑ'}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentMethodPopup;
