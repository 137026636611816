import config from 'config/config';
import React from 'react';
import { NavLink } from 'react-router-dom';

const MainMenu = ({ setToggleValue }) => {
  return (
    <div className="main-menu-container">
      <div className="top-main-menu">
        <i
          className="icon-cancel-circled"
          onClick={setToggleValue('left', false)}
        />
      </div>
      <div className="menu-options-container">
        <ul>
          {config?.menu?.map((mn, index) => (
            <li key={`${mn.text}_${index}`}>
              <NavLink
                className={({ isActive }) => (isActive ? ' active' : '')}
                to={mn?.path}
                {...(mn.path === '/' && { end: true })}
                onClick={setToggleValue('left', false)}>
                <i className={`${mn?.icon} menu-item-icon`} />
                {mn?.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MainMenu;
