const ordersPagination = ({ ordersReducer }) =>
  ordersReducer?.orders?.pagination;

const ordersStatuses = ({ ordersReducer }) => ordersReducer?.filterStatuses;
const selectedOrdersFilters = ({ ordersReducer }) => ordersReducer?.filters;
const adminOrders = ({ ordersReducer }) => ordersReducer?.orders?.results;
const singleOrder = ({ ordersReducer }) => ordersReducer?.singleOrder;
const selectedProductToAdd = ({ ordersReducer }) =>
  ordersReducer?.selectedProductToAdd;
const productsToAddToOrder = ({ ordersReducer }) =>
  ordersReducer?.productsToAddToOrder;
const orderProductSearchTerm = ({ ordersReducer }) =>
  ordersReducer?.orderProductSearchTerm;
const selectedProductToAddTotal = ({ ordersReducer }) =>
  ordersReducer?.selectedProductToAddTotal;
const ordersSorting = ({ ordersReducer }) => ordersReducer?.sorting;
const doys = ({ ordersReducer }) => ordersReducer?.doys;

export {
  ordersPagination,
  ordersStatuses,
  selectedOrdersFilters,
  adminOrders,
  singleOrder,
  selectedProductToAdd,
  productsToAddToOrder,
  orderProductSearchTerm,
  selectedProductToAddTotal,
  ordersSorting,
  doys,
};
