/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Popover from '@mui/material/Popover';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  cancelOrder,
  setOrderPopup,
  getOrderPopup,
  setOrderProductSearchTerm,
  setProductToAdd,
  getOrderProducts,
  setOrderProducts,
  addProductToOrder,
  setNewProductTotal,
} from 'models/actions/ordersActions';
import {
  singleOrder,
  ordersStatuses,
  selectedProductToAdd,
  productsToAddToOrder,
  orderProductSearchTerm,
  selectedProductToAddTotal,
  doys,
} from 'models/selectors/ordersSelectors';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BillingShippingInfos from './BillingShippingInfos';
import OrderProduct from './OrderProduct';
import OrderTotals from './OrderTotals';

const OrderPopup = ({ orderId, handleClose }) => {
  const dispatch = useDispatch();
  const statuses = useSelector(ordersStatuses);
  const myDoys = useSelector(doys);
  const singleOrderPopup = useSelector(singleOrder);
  const productToAdd = useSelector(selectedProductToAdd);
  const productToAddTotal = useSelector(selectedProductToAddTotal);
  const orderProducts = useSelector(productsToAddToOrder);
  const productSearchText = useSelector(orderProductSearchTerm);
  const [anchorEl, setAnchorEl] = useState(null);
  const [createNewProductOpen, setCreateNewProductOpen] = useState(false);

  const handleClickPastStatuses = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePastStatuses = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(setGeneralLoading(true));
    dispatch(getOrderPopup(orderId));

    return () => {
      dispatch(setGeneralLoading(false));
      dispatch(
        setOrderPopup({
          orderCode: '',
          dateCreated: '',
          statusId: '',
          statusName: '',
          paymentMethodCost: 0,
          shippingMethodCost: 0,
          piAfm: '',
          piEponymia: '',
          piDoy: null,
          piFirstName: '',
          piLastName: '',
          piAddress: '',
          piPhone: '',
          piPostCode: '',
          siFirstName: '',
          siLastName: '',
          siAddress: '',
          siPhone: '',
          siPostCode: '',
          products: [],
          pastStatuses: [],
          couponDiscount: 0,
        }),
      );
    };
  }, []);

  const {
    statusId,
    orderCode,
    dateCreated,
    pastStatuses,
    products,
    paymentMethodCost,
    shippingMethodCost,
    piAfm,
    piEponymia,
    piDoy,
    piFirstName,
    piLastName,
    piAddress,
    piPhone,
    piPostCode,
    siFirstName,
    siLastName,
    siAddress,
    siPhone,
    siPostCode,
    couponDiscount,
  } = singleOrderPopup;

  const id = Boolean(anchorEl) ? 'simple-popover' : undefined;
  const totalRef = useRef();

  return (
    <div>
      <Dialog
        fullScreen
        open={orderId !== ''}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <div className="column-flex">
            <span>
              <strong>Παραγγελία: </strong>
              {orderCode}
            </span>
            <span>
              <strong>Ημερομηνία: </strong>
              {new Date(dateCreated).toLocaleString()}
            </span>
            <div className="flex alignCenter">
              <strong>Κατάσταση:&nbsp;</strong>
              <Chip
                label={statuses?.find((st) => st.id === statusId)?.name}
                className={`chip chip${statusId}`}
              />
              <Tooltip title="Δες την πορεία της παραγγελίας">
                <i
                  onClick={handleClickPastStatuses}
                  className="icon-info-circled see-order-steps"
                />
              </Tooltip>
              <Popover
                id={id}
                className="past-statuses"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePastStatuses}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                {pastStatuses?.map((pst, index) => (
                  <div key={pst?.id}>
                    {index + 1}.
                    <Chip label={pst.name} className={`chip chip${pst.id}`} />
                    {new Date(pst.dateCreated).toLocaleString()}
                  </div>
                ))}
              </Popover>
              {statusId === 1 && (
                <>
                  <Tooltip title={'Ακύρωση παραγγελίας'}>
                    <i
                      onClick={() => {
                        dispatch(setGeneralLoading(true));
                        dispatch(cancelOrder({ orderId }));
                      }}
                      className="icon-right-circled delete rotate next-order-step"
                    />
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          {products?.length > 0 ? (
            <div className="products">
              <div className="order-products-title">
                <h3>Προϊόντα στην παραγγελία</h3>
                {statusId === 1 && (
                  <button
                    onClick={() => {
                      dispatch(setOrderProducts({ result: [] }));
                      dispatch(setProductToAdd({}));
                      dispatch(setOrderProductSearchTerm(''));
                      setCreateNewProductOpen(!createNewProductOpen);
                    }}
                    className="button add">
                    ΠΡΟΣΘΗΚΗ ΠΡΟΪΟΝΤΩΝ
                  </button>
                )}
              </div>
              {createNewProductOpen && (
                <div className="add-product-in-order-container">
                  <div className="add-product-fields">
                    <div className="add-product-field">
                      <FormControl>
                        <Autocomplete
                          clearOnBlur={false}
                          id="product-popup-subcategories"
                          options={orderProducts || []}
                          getOptionLabel={(option) =>
                            option
                              ? `${option?.['productTitle']} (${option?.['stock']})`
                              : ''
                          }
                          value={
                            productToAdd || {
                              stock: '',
                              productTitle: '',
                            }
                          }
                          inputValue={productSearchText || ''}
                          noOptionsText="Δεν βρέθηκαν επιλογές"
                          onChange={(e, product) => {
                            dispatch(setProductToAdd({ ...product }));
                          }}
                          onInputChange={(e, value) => {
                            if (e) {
                              dispatch(
                                setOrderProductSearchTerm(
                                  value !== '' ? value : '',
                                ),
                              );
                              if (value !== '') {
                                dispatch(getOrderProducts(value));
                              }
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Προϊόντα"
                              placeholder="Επιλογή"
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                    <div className="add-product-field">
                      <FormControl>
                        <InputLabel htmlFor="new-product-total">
                          Τεμάχια
                        </InputLabel>
                        <Input
                          id="new-product-total"
                          inputRef={totalRef}
                          error={false}
                          value={productToAddTotal}
                          type="number"
                          inputProps={{
                            min: 1,
                            max: productToAdd?.stock,
                          }}
                          onClick={() => totalRef.current.select()}
                          onChange={(e) =>
                            dispatch(setNewProductTotal(e.target.value))
                          }
                        />
                      </FormControl>
                    </div>
                    <div className="add-product-field">
                      <i
                        className={`icon-ok-circle ${
                          !productToAdd ||
                          Object.keys(productToAdd).length === 0 ||
                          productToAddTotal === 0
                            ? 'disabled'
                            : ''
                        }`}
                        onClick={() => {
                          dispatch(setGeneralLoading(true));
                          dispatch(
                            addProductToOrder({
                              orderId,
                              total: productToAddTotal,
                              product: productToAdd,
                            }),
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <Table className="products-grid">
                <TableHead>
                  <TableRow className="order-product-row">
                    <TableCell>ΤΙΤΛΟΣ</TableCell>
                    <TableCell>ΚΩΔΙΚΟΣ</TableCell>
                    <TableCell>ΑΡΧΙΚΗ ΤΙΜΗ</TableCell>
                    <TableCell>ΤΙΜΗ</TableCell>
                    <TableCell>ΤΕΜΑΧΙΑ</TableCell>
                    {statusId === 1 && <TableCell></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products?.map((product) => (
                    <OrderProduct
                      orderId={orderId}
                      statusId={statusId}
                      product={product}
                      key={product?.productId}
                    />
                  ))}
                </TableBody>
              </Table>
              <OrderTotals
                pmCost={paymentMethodCost}
                smCost={shippingMethodCost}
                couponDiscount={couponDiscount}
                products={products}
              />
              <div className="billing-shipping-container">
                <BillingShippingInfos
                  options={{
                    billing: {
                      firstName: piFirstName,
                      lastName: piLastName,
                      address: piAddress,
                      phone: piPhone,
                      postCode: piPostCode,
                      afm: piAfm,
                      eponymia: piEponymia,
                      doy: myDoys?.find((doy) => doy?.id === piDoy)?.label,
                    },
                    shipping: {
                      firstName: siFirstName,
                      lastName: siLastName,
                      address: siAddress,
                      phone: siPhone,
                      postCode: siPostCode,
                    },
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="not-found">
              Δεν βρέθηκαν προϊόντα στην παραγγελία!
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderPopup;
