import { createReducer } from '@reduxjs/toolkit';
import {
  setStaticContent,
  setSocialNetworks,
  setBusinessDetails,
} from 'models/actions/staticActions';
import { resetState } from 'models/actions/userActions';

const initialState = {
  pages: [],
  socialNetworks: [],
  businessDetails: {},
};

const staticReducer = createReducer(initialState, {
  [setStaticContent.type]: (state, action) => ({
    ...state,
    pages: action.payload,
  }),
  [setSocialNetworks.type]: (state, action) => ({
    ...state,
    socialNetworks: action.payload,
  }),
  [setBusinessDetails.type]: (state, action) => ({
    ...state,
    businessDetails: action.payload?.[0],
  }),
  [resetState.type]: (state, action) => ({ ...initialState }),
});

export default staticReducer;
