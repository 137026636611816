const adminProducts = ({ productsReducer }) =>
  productsReducer?.catalog?.results;

const productsPagination = ({ productsReducer }) =>
  productsReducer.catalog.pagination;

const productsSorting = ({ productsReducer }) => productsReducer?.sorting;
const allProducts = ({ productsReducer }) => productsReducer?.allProducts;

const productsSelectedFilters = ({ productsReducer }) =>
  productsReducer?.filters;

const filterCategories = ({ productsReducer }) =>
  productsReducer?.filterCategories;
const filterSubCategories = ({ productsReducer }) =>
  productsReducer?.filterSubCategories;
const filterBrands = ({ productsReducer }) => productsReducer?.filterBrands;

const filterPricesRange = ({ productsReducer }) =>
  productsReducer.filterPricesRange;

// PRODUCT POPUP
const productPopupDetails = ({ productsReducer }) =>
  productsReducer.productPopupDetails;
const productPopupDetailsExtra = ({ productsReducer }) =>
  productsReducer.productPopupDetailsExtra;
const productImage = ({ productsReducer }) => productsReducer.productImage;

export {
  adminProducts,
  productsPagination,
  productsSorting,
  productsSelectedFilters,
  filterCategories,
  filterPricesRange,
  filterBrands,
  filterSubCategories,
  // PRODUCT POPUP
  productPopupDetails,
  productPopupDetailsExtra,
  productImage,
  allProducts,
};
