import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { ordersStatuses } from 'models/selectors/ordersSelectors';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import CourierDialog from './CourierDialog';
import formatMoney from '../../library/formatMoney';

const Order = ({
  order,
  toggleOrderPopup,
  sendOrderMail,
  sendCourierTrack,
}) => {
  const [open, setOpen] = useState(false);
  const [courierLink, setCourierLink] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setCourierLink('');
    setOpen(false);
  };
  const statuses = useSelector(ordersStatuses);
  const {
    id,
    order_code,
    dateCreated,
    paymentFirstName,
    paymentLastName,
    paymentEmail,
    totalItems,
    totalOrderPrice,
    paymentMethodCost,
    shippingMethodCost,
    statusId,
    paymentName,
    shippingName,
    couponDiscount,
  } = order;

  const message = `Κωδικός ${order_code}.`;

  return (
    <TableRow className="product-row extra">
      <TableCell>
        <span className="hidden-column">Κωδικός Παραγγελίας:&nbsp;</span>
        <Tooltip title={order_code}>
          <span>{order_code}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Ημ/νια:&nbsp;</span>
        <Tooltip title={new Date(dateCreated).toLocaleString()}>
          <span>{new Date(dateCreated).toLocaleString()}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Όνομα:&nbsp;</span>
        <Tooltip title={`${paymentFirstName} ${paymentLastName}`}>
          <span>
            {paymentFirstName} {paymentLastName}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Email:&nbsp;</span>
        <Tooltip title={paymentEmail}>
          <span>{paymentEmail}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Πληρωμή:&nbsp;</span>
        <Tooltip title={paymentName}>
          <span>{paymentName}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Αποστολή:&nbsp;</span>
        <Tooltip title={shippingName}>
          <span>{shippingName}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Τιμή:&nbsp;</span>
        <Tooltip
          title={formatMoney.format(
            ((Number(totalOrderPrice) +
              Number(paymentMethodCost) +
              Number(shippingMethodCost)) *
              (100 - couponDiscount)) /
              100,
          )}>
          <span>
            {formatMoney.format(
              ((Number(totalOrderPrice) +
                Number(paymentMethodCost) +
                Number(shippingMethodCost)) *
                (100 - couponDiscount)) /
                100,
            )}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Τεμάχια:&nbsp;</span>
        <Tooltip title={totalItems}>
          <span>{totalItems}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Κατάσταση:&nbsp;</span>
        <Tooltip title={statuses?.find((st) => st?.id === statusId)?.name}>
          <Chip
            className={`chip chip${statusId}`}
            label={statuses?.find((st) => st?.id === statusId)?.name}
          />
        </Tooltip>
      </TableCell>
      <TableCell>
        <button className="button action" onClick={() => toggleOrderPopup(id)}>
          <Tooltip title="Δες την παραγγελία">
            <i className="icon-pencil" />
          </Tooltip>
        </button>
        <button className="button action" onClick={() => sendOrderMail(id)}>
          <Tooltip title="Στείλτε το mail">
            <i className="icon-mail" />
          </Tooltip>
        </button>
        <button className="button action" onClick={handleOpen}>
          <Tooltip title="Στείλτε courier track">
            <span className="info-icon">&#9992;</span>
          </Tooltip>
        </button>
      </TableCell>
      <CourierDialog
        open={open}
        message={message}
        handleClose={handleClose}
        courierLink={courierLink}
        setCourierLink={setCourierLink}
        sendCourierTrack={() => sendCourierTrack(order, courierLink, message)}
      />
    </TableRow>
  );
};

export default Order;
