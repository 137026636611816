import makeRequest from 'library/makeRequest';
import {
  setGeneralLoading,
  toggleShowAlert,
} from 'models/actions/generalActions';
import {
  uploadSingleImage,
  getOfferTitle,
  setOfferTitle,
  setOfferTitleLoading,
  updateOfferTitle,
  // LOGO
  uploadLogoImage,
  setLogoImage,
  saveLogoImageDB,
  getLogo,
  // PAYMENTS
  getAllPaymentMethods,
  setPaymentMethodsLoading,
  setAllPaymentMethods,
  updatePaymentMethod,
  addPaymentMethod,
  removePaymentMethod,
  // SHIPPING
  getAllShippingMethods,
  setShippingMethodsLoading,
  setAllShippingMethods,
  updateShippingMethod,
  addShippingMethod,
  removeShippingMethod,
  getSingleShippingMethod,
  setSingleShippingMethod,
  updateLocalShippingMethod,
  // HOME TABS
  getAllHomeTabs,
  setAllHomeTabs,
  setHomeTabsLoading,
  removeHomeTab,
  getHomeTabRelatedProducts,
  setHomeTabsSearchedProducts,
  updateHomeTab,
  addHomeTab,
  // HOME SECTIONS
  getAllHomeSections,
  setAllHomeSections,
  setHomeSectionsLoading,
  removeHomeSection,
  getHomeSectionRelatedProducts,
  setHomeSectionsSearchedProducts,
  updateHomeSection,
  addHomeSection,
  // HOME BANNERS
  getAllHomeBanners,
  setAllHomeBanners,
  setHomeBannersLoading,
  removeHomeBanners,
  addHomeBanner,
  uploadHomeBannerImage,
  updateHomeBanner,
  // HOME LAST BANNERS
  getAllHomeLastBanners,
  setAllHomeLastBanners,
  setHomeLastBannersLoading,
  removeHomeLastBanners,
  addHomeLastBanner,
  uploadHomeLastBannerImage,
  updateHomeLastBanner,
  // KEYWORDS
  getAllKeyWords,
  setAllKeyWords,
  setKeyWordsLoading,
  updateKeyWord,
  // COUPONS
  getAllCoupons,
  setCouponsLoading,
  setAllCoupons,
  removeCoupon,
  addCoupon,
  updateCoupon,
  // NEWSLETTERCOUPON
  getNewsletterCoupon,
  setNewsletterCoupon,
  setNewsletterCouponLoading,
  toggleNewsletterCoupon,
} from 'models/actions/settingsActions';
import { token } from 'models/selectors/userSelectors';
import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  mergeMap,
  concatMap,
  catchError,
  debounceTime,
  ignoreElements,
  map,
  withLatestFrom,
} from 'rxjs/operators';

import catchErrorOperator from './operators/catchErrorOperator';
// OFFER TITLES
const updateOfferTitleEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateOfferTitle.type),
    mergeMap(({ payload: { data } }) =>
      from(
        makeRequest(
          'offertitle/update',
          'POST',
          JSON.stringify(data),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getOfferTitle(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

// KEYWORDS
const updateKeyWordEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateKeyWord.type),
    mergeMap(({ payload: { data } }) =>
      from(
        makeRequest(
          'staticcontent/keywords/admin/update',
          'POST',
          JSON.stringify(data),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllKeyWords(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getAllKeyWordsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllKeyWords.type),
    mergeMap(() =>
      from(
        makeRequest(
          'staticcontent/keywords/admin/list',
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap(({ keywords }) => {
          return [setAllKeyWords(keywords), setKeyWordsLoading(false)];
        }),
        catchError((error) =>
          of(
            toggleShowAlert({
              message: `${error}`,
              show: true,
              type: 'error',
            }),
            setKeyWordsLoading(false),
          ),
        ),
      ),
    ),
  );

// COUPONS
const addCouponEpic = (action$, state$) =>
  action$.pipe(
    ofType(addCoupon.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `staticcontent/coupons/admin/add`,
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllCoupons(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const updateCouponEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateCoupon.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `staticcontent/coupons/admin/update`,
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllCoupons(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const removeCouponEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeCoupon.type),
    mergeMap(({ payload: { id, isActive } }) =>
      from(
        makeRequest(
          'staticcontent/coupons/admin/delete',
          'POST',
          JSON.stringify({ couponId: Number(id), isActive: Number(isActive) }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllCoupons(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getAllCouponsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllCoupons.type),
    mergeMap(() =>
      from(
        makeRequest(
          'staticcontent/coupons/admin/list',
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap(({ coupons }) => {
          return [setAllCoupons(coupons), setCouponsLoading(false)];
        }),
        catchError((error) =>
          of(
            toggleShowAlert({
              message: `${error}`,
              show: true,
              type: 'error',
            }),
            setCouponsLoading(false),
          ),
        ),
      ),
    ),
  );

// OFFER TITLE
const getOfferTitleEpic = (action$) =>
  action$.pipe(
    ofType(getOfferTitle.type),
    mergeMap(() =>
      from(makeRequest('offertitle', 'GET', '')).pipe(
        concatMap((payload) => [
          setOfferTitle(payload),
          setOfferTitleLoading(false),
        ]),
      ),
    ),
  );
// LOGO
const getLogoEpic = (action$) =>
  action$.pipe(
    ofType(getLogo.type),
    mergeMap(() =>
      from(makeRequest('logo', 'GET', '')).pipe(
        concatMap((payload) => [setLogoImage(payload?.logo)]),
      ),
    ),
  );

const saveLogoImageDBEpic = (action$, state$) =>
  action$.pipe(
    ofType(saveLogoImageDB.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          'logo/admin/add',
          'POST',
          JSON.stringify({ image: payload }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const uploadLogoImageEpic = (action$, state$) =>
  action$.pipe(
    ofType(uploadLogoImage.type),
    mergeMap(({ payload }) => {
      // image is the image for uploading
      const image = { ...payload };
      const formData = new FormData();

      formData.append('file', image.data);

      return from(
        fetch(`${process.env.REACT_APP_API}/logo/admin/image`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${token(state$.value)}`,
          },
        }),
      ).pipe(map(() => setLogoImage(image)));
    }),
  );

// PAYMENT METHODS
const getAllPaymentMethodsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllPaymentMethods.type),
    mergeMap(() =>
      from(
        makeRequest('paymentmethods/admin', 'GET', '', token(state$.value)),
      ).pipe(
        concatMap((payload) => {
          return [
            setAllPaymentMethods(payload),
            setPaymentMethodsLoading(false),
          ];
        }),
        catchError((error) =>
          of(
            toggleShowAlert({
              message: `${error}`,
              show: true,
              type: 'error',
            }),
            setPaymentMethodsLoading(false),
          ),
        ),
      ),
    ),
  );

const updatePaymentMethodEpic = (action$, state$) =>
  action$.pipe(
    ofType(updatePaymentMethod.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          'paymentmethods/admin/update',
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllPaymentMethods(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const addPaymentMethodEpic = (action$, state$) =>
  action$.pipe(
    ofType(addPaymentMethod.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          'paymentmethods/admin/add',
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllPaymentMethods(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const removePaymentMethodEpic = (action$, state$) =>
  action$.pipe(
    ofType(removePaymentMethod.type),
    mergeMap(({ payload: { id, isActive } }) =>
      from(
        makeRequest(
          'paymentmethods/admin/delete',
          'POST',
          JSON.stringify({ pmID: Number(id), isActive: Number(isActive) }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllPaymentMethods(),
            getAllShippingMethods(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

// SHIPPING METHODS

const updateLocalShippingMethodEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateLocalShippingMethod.type),
    withLatestFrom(state$),
    map(
      ([
        {
          payload: { field, value, id, cost },
        },
        {
          settingsReducer: {
            settings: {
              shipping: { singleMethod },
            },
          },
        },
      ]) => {
        let newSingleShippingMethod = {};

        if (field === 'name' || field === 'description') {
          newSingleShippingMethod = {
            ...singleMethod,
            [field]: value,
          };
        } else if (field === 'prefecture') {
          newSingleShippingMethod = {
            ...singleMethod,
            selectedPrefectures: singleMethod?.selectedPrefectures?.find(
              (p) => p?.id === id,
            )
              ? singleMethod?.selectedPrefectures?.filter((p) => p?.id !== id)
              : singleMethod?.selectedPrefectures?.length > 0
              ? [...singleMethod?.selectedPrefectures, { id, cost }]
              : [{ id, cost }],
          };
        } else {
          newSingleShippingMethod = {
            ...singleMethod,
            selectedPrefectures: singleMethod?.selectedPrefectures?.map((p) =>
              p?.id !== id ? { ...p } : { ...p, cost: value },
            ),
          };
        }

        return setSingleShippingMethod(newSingleShippingMethod);
      },
    ),
  );

const getSingleShippingMethodEpic = (action$, state$) =>
  action$.pipe(
    ofType(getSingleShippingMethod.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `shippingmethods/admin/method/${payload}`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          const { shippingMethod } = payload;
          const newShippingMethod = {
            id: shippingMethod?.[0]?.shippingMethodId,
            name: shippingMethod?.[0]?.name,
            description: shippingMethod?.[0]?.description,
            selectedPrefectures: shippingMethod?.map((sm) => {
              return { id: sm.prefectureId, cost: sm?.cost };
            }),
          };

          return [
            setSingleShippingMethod(newShippingMethod),
            setGeneralLoading(false),
          ];
        }),
        catchError((error) =>
          of(
            toggleShowAlert({
              message: `${error}`,
              show: true,
              type: 'error',
            }),
            setShippingMethodsLoading(false),
          ),
        ),
      ),
    ),
  );

const getAllShippingMethodsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllShippingMethods.type),
    mergeMap(() =>
      from(
        makeRequest('shippingmethods/admin', 'GET', '', token(state$.value)),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setAllShippingMethods(payload),
            setShippingMethodsLoading(false),
          ];
        }),
        catchError((error) =>
          of(
            toggleShowAlert({
              message: `${error}`,
              show: true,
              type: 'error',
            }),
            setShippingMethodsLoading(false),
          ),
        ),
      ),
    ),
  );

const updateShippingMethodEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateShippingMethod.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          'shippingmethods/admin/update',
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllShippingMethods(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const addShippingMethodEpic = (action$, state$) =>
  action$.pipe(
    ofType(addShippingMethod.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          'shippingmethods/admin/add',
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllShippingMethods(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const removeShippingMethodEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeShippingMethod.type),
    mergeMap(({ payload: { id, isActive } }) =>
      from(
        makeRequest(
          'shippingmethods/admin/delete',
          'POST',
          JSON.stringify({ smID: Number(id), isActive: Number(isActive) }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllShippingMethods(),
            getAllPaymentMethods(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

// HOME TABS
const getAllHomeTabsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllHomeTabs.type),
    mergeMap(() =>
      from(makeRequest('home/tabs/admin', 'GET', '', token(state$.value))).pipe(
        concatMap(({ tabs }) => {
          return [setAllHomeTabs(tabs), setHomeTabsLoading(false)];
        }),
        catchError((error) =>
          of(
            toggleShowAlert({
              message: `${error}`,
              show: true,
              type: 'error',
            }),
            setHomeTabsLoading(false),
          ),
        ),
      ),
    ),
  );

const removeHomeTabEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeHomeTab.type),
    mergeMap(({ payload: { id, isActive } }) =>
      from(
        makeRequest(
          'home/tabs/admin/delete',
          'POST',
          JSON.stringify({ htID: Number(id), isActive: Number(isActive) }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllHomeTabs(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getHomeTabRelatedProductsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getHomeTabRelatedProducts.type),
    debounceTime(500),
    mergeMap(({ payload }) =>
      from(makeRequest(`products/search/all?term=${payload}`, 'GET', '')).pipe(
        concatMap((payload) => {
          return [setHomeTabsSearchedProducts(payload?.data)];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const updateHomeTabEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateHomeTab.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `home/tabs/admin/update`,
          'POST',
          JSON.stringify({ ...payload }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllHomeTabs(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const addHomeTabEpic = (action$, state$) =>
  action$.pipe(
    ofType(addHomeTab.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `home/tabs/admin/add`,
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllHomeTabs(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

// HOME SECTIONS
const getAllHomeSectionsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllHomeSections.type),
    mergeMap(() =>
      from(
        makeRequest('home/sections/admin', 'GET', '', token(state$.value)),
      ).pipe(
        concatMap(({ sections }) => {
          return [setAllHomeSections(sections), setHomeSectionsLoading(false)];
        }),
        catchError((error) =>
          of(
            toggleShowAlert({
              message: `${error}`,
              show: true,
              type: 'error',
            }),
            setHomeSectionsLoading(false),
          ),
        ),
      ),
    ),
  );

const getHomeSectionRelatedProductsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getHomeSectionRelatedProducts.type),
    debounceTime(500),
    mergeMap(({ payload }) =>
      from(makeRequest(`products/search/all?term=${payload}`, 'GET', '')).pipe(
        concatMap((payload) => {
          return [setHomeSectionsSearchedProducts(payload?.data)];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const addHomeSectionEpic = (action$, state$) =>
  action$.pipe(
    ofType(addHomeSection.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `home/sections/admin/add`,
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllHomeSections(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const removeHomeSectionEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeHomeSection.type),
    mergeMap(({ payload: { id, isActive } }) =>
      from(
        makeRequest(
          'home/sections/admin/delete',
          'POST',
          JSON.stringify({ hsID: Number(id), isActive: Number(isActive) }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllHomeSections(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const updateHomeSectionEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateHomeSection.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `home/sections/admin/update`,
          'POST',
          JSON.stringify({ ...payload }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllHomeSections(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getAllHomeBannersEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllHomeBanners.type),
    mergeMap(() =>
      from(
        makeRequest('home/banners/admin', 'GET', '', token(state$.value)),
      ).pipe(
        concatMap(({ banners }) => {
          const newBanners = banners?.map((banner) => ({
            ...banner,
            product: {
              productId: banner?.productId,
              productTitle: banner?.productTitle,
            },
          }));

          return [setAllHomeBanners(newBanners), setHomeBannersLoading(false)];
        }),
        catchError((error) =>
          of(
            toggleShowAlert({
              message: `${error}`,
              show: true,
              type: 'error',
            }),
            setHomeBannersLoading(false),
          ),
        ),
      ),
    ),
  );

const removeHomeBannersEpic = (action$, state$) =>
  action$?.pipe(
    ofType(removeHomeBanners.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          'home/banners/admin/delete',
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllHomeBanners(),
          ];
        }),
        catchError((error) =>
          of(
            toggleShowAlert({
              message: `${error}`,
              show: true,
              type: 'error',
            }),
            setHomeBannersLoading(false),
          ),
        ),
      ),
    ),
  );

const addHomeBannerEpic = (action$, state$) =>
  action$.pipe(
    ofType(addHomeBanner.type),
    mergeMap(({ payload }) => {
      const formData = new FormData();

      const homeBannerImage = payload?.image;

      formData.append('file', homeBannerImage?.data);

      return from(
        makeRequest(
          'home/banners/admin/add',
          'POST',
          JSON.stringify({
            title: payload?.title,
            link: payload?.link,
            image: homeBannerImage?.data?.name,
            isVideo: Boolean(payload?.isVideo),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
              getAllHomeBanners(),
              setGeneralLoading(false),
            ];
          }

          if (homeBannerImage.data?.size) {
            return [
              toggleShowAlert({
                message: `${payload?.message}`,
                show: true,
                type: 'success',
              }),
              getAllHomeBanners(),
              uploadHomeBannerImage(formData),
              setGeneralLoading(false),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllHomeBanners(),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      );
    }),
  );

const uploadSingleImageEpic = (action$, state$) =>
  action$.pipe(
    ofType(uploadSingleImage.type),
    mergeMap(({ payload }) => {
      const formData = new FormData();

      const singleImage = payload?.image;

      formData.append('file', singleImage?.data);

      return from(
        fetch(`${process.env.REACT_APP_API}/home/single/admin/image`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${token(state$.value)}`,
          },
        }),
      ).pipe(
        map(() => {
          return setGeneralLoading(false);
        }),
      );
    }),
  );

const uploadHomeBannerImageEpic = (action$, state$) =>
  action$.pipe(
    ofType(uploadHomeBannerImage.type),
    mergeMap(({ payload }) =>
      from(
        fetch(`${process.env.REACT_APP_API}/home/admin/image`, {
          method: 'POST',
          body: payload,
          headers: {
            Authorization: `Bearer ${token(state$.value)}`,
          },
        }),
      ).pipe(ignoreElements()),
    ),
  );

const updateHomeBannerEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateHomeBanner.type),
    mergeMap(({ payload }) => {
      const formData = new FormData();

      const homeBannerImage = payload?.image;

      formData.append('file', homeBannerImage?.data);

      return from(
        makeRequest(
          'home/banners/admin/update',
          'POST',
          JSON.stringify({
            id: payload?.id,
            title: payload?.title,
            link: payload?.link,
            isVideo: Boolean(payload?.isVideo),
            image: homeBannerImage?.data?.name,
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
              getAllHomeBanners(),
              setGeneralLoading(false),
            ];
          }

          if (homeBannerImage.data?.size) {
            return [
              toggleShowAlert({
                message: `${payload?.message}`,
                show: true,
                type: 'success',
              }),
              getAllHomeBanners(),
              uploadHomeBannerImage(formData),
              setGeneralLoading(false),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllHomeBanners(),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      );
    }),
  );

const getAllHomeLastBannersEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllHomeLastBanners.type),
    mergeMap(() =>
      from(
        makeRequest('home/lastbanners/admin', 'GET', '', token(state$.value)),
      ).pipe(
        concatMap(({ lastBanners }) => {
          return [
            setAllHomeLastBanners(lastBanners),
            setHomeLastBannersLoading(false),
          ];
        }),
        catchError((error) =>
          of(
            toggleShowAlert({
              message: `${error}`,
              show: true,
              type: 'error',
            }),
            setHomeLastBannersLoading(false),
          ),
        ),
      ),
    ),
  );

const removeHomeLastBannersEpic = (action$, state$) =>
  action$?.pipe(
    ofType(removeHomeLastBanners.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          'home/lastbanners/admin/delete',
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllHomeLastBanners(),
          ];
        }),
        catchError((error) =>
          of(
            toggleShowAlert({
              message: `${error}`,
              show: true,
              type: 'error',
            }),
            setHomeLastBannersLoading(false),
          ),
        ),
      ),
    ),
  );

const updateHomeLastBannerEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateHomeLastBanner.type),
    mergeMap(({ payload }) => {
      const formData = new FormData();

      const homeBannerImage = payload?.image;

      formData.append('file', homeBannerImage?.data);

      return from(
        makeRequest(
          'home/lastbanners/admin/update',
          'POST',
          JSON.stringify({
            id: payload?.id,
            description: payload?.description,
            link: payload?.link,
            image: homeBannerImage?.data?.name,
            isVideo: Boolean(payload?.isVideo),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
              getAllHomeLastBanners(),
              setGeneralLoading(false),
            ];
          }

          if (homeBannerImage.data?.size) {
            return [
              toggleShowAlert({
                message: `${payload?.message}`,
                show: true,
                type: 'success',
              }),
              getAllHomeLastBanners(),
              uploadHomeLastBannerImage(formData),
              setGeneralLoading(false),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllHomeLastBanners(),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      );
    }),
  );

const uploadHomeLastBannerImageEpic = (action$, state$) =>
  action$.pipe(
    ofType(uploadHomeLastBannerImage.type),
    mergeMap(({ payload }) =>
      from(
        fetch(`${process.env.REACT_APP_API}/home/admin/image`, {
          method: 'POST',
          body: payload,
          headers: {
            Authorization: `Bearer ${token(state$.value)}`,
          },
        }),
      ).pipe(ignoreElements()),
    ),
  );

const addHomeLastBannerEpic = (action$, state$) =>
  action$.pipe(
    ofType(addHomeLastBanner.type),
    mergeMap(({ payload }) => {
      const formData = new FormData();

      const homeBannerImage = payload?.image;

      formData.append('file', homeBannerImage?.data);

      return from(
        makeRequest(
          'home/lastbanners/admin/add',
          'POST',
          JSON.stringify({
            description: payload?.description,
            link: payload?.link,
            isVideo: Boolean(payload?.isVideo),
            image: homeBannerImage?.data?.name,
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
              getAllHomeLastBanners(),
              setGeneralLoading(false),
            ];
          }

          if (homeBannerImage.data?.size) {
            return [
              toggleShowAlert({
                message: `${payload?.message}`,
                show: true,
                type: 'success',
              }),
              getAllHomeLastBanners(),
              uploadHomeLastBannerImage(formData),
              setGeneralLoading(false),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllHomeLastBanners(),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      );
    }),
  );

const getNewsletterCouponEpic = (action$, state$) =>
  action$.pipe(
    ofType(getNewsletterCoupon.type),
    mergeMap(() =>
      from(
        makeRequest(
          'newsletter/admin/newslettercoupon',
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap(({ newsletterCouponIsActive }) => [
          toggleShowAlert({
            message: ``,
            type: 'error',
            show: false,
          }),
          setNewsletterCouponLoading(false),
          setNewsletterCoupon(newsletterCouponIsActive),
        ]),
        catchErrorOperator(true),
      ),
    ),
  );

const toggleNewsletterCouponEpic = (action$, state$) =>
  action$.pipe(
    ofType(toggleNewsletterCoupon.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          'newsletter/admin/newslettercoupon/toggle',
          'POST',
          JSON.stringify({ isActive: payload }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload.error) {
            return [
              toggleShowAlert({
                message: payload.error,
                type: 'error',
                show: true,
              }),
              setNewsletterCouponLoading(false),
            ];
          }

          return [
            toggleShowAlert({
              message: ``,
              type: 'error',
              show: false,
            }),
            setNewsletterCouponLoading(false),
            setNewsletterCoupon(payload.newsletterCouponIsActive),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

export {
  getOfferTitleEpic,
  updateOfferTitleEpic,
  // LOGO,
  uploadLogoImageEpic,
  saveLogoImageDBEpic,
  getLogoEpic,
  // PAYMENTS
  getAllPaymentMethodsEpic,
  updatePaymentMethodEpic,
  addPaymentMethodEpic,
  removePaymentMethodEpic,
  // SHIPPING
  getAllShippingMethodsEpic,
  updateShippingMethodEpic,
  addShippingMethodEpic,
  removeShippingMethodEpic,
  getSingleShippingMethodEpic,
  updateLocalShippingMethodEpic,
  // HOME TABS
  getAllHomeTabsEpic,
  removeHomeTabEpic,
  getHomeTabRelatedProductsEpic,
  updateHomeTabEpic,
  addHomeTabEpic,
  // HOME SECTIONS
  getAllHomeSectionsEpic,
  getHomeSectionRelatedProductsEpic,
  addHomeSectionEpic,
  removeHomeSectionEpic,
  updateHomeSectionEpic,
  // HOME BANNERS
  getAllHomeBannersEpic,
  removeHomeBannersEpic,
  addHomeBannerEpic,
  uploadHomeBannerImageEpic,
  updateHomeBannerEpic,
  // HOME LAST BANNERS
  getAllHomeLastBannersEpic,
  removeHomeLastBannersEpic,
  updateHomeLastBannerEpic,
  uploadHomeLastBannerImageEpic,
  addHomeLastBannerEpic,
  // KEYWORDS
  getAllKeyWordsEpic,
  updateKeyWordEpic,
  // COUPONS
  getAllCouponsEpic,
  removeCouponEpic,
  addCouponEpic,
  updateCouponEpic,
  uploadSingleImageEpic,
  // NEWSLETTERCOUPON
  getNewsletterCouponEpic,
  toggleNewsletterCouponEpic,
};

export default combineEpics(
  getOfferTitleEpic,
  updateOfferTitleEpic,
  // LOGO,
  uploadLogoImageEpic,
  saveLogoImageDBEpic,
  getLogoEpic,
  // PAYMENTS
  getAllPaymentMethodsEpic,
  updatePaymentMethodEpic,
  addPaymentMethodEpic,
  removePaymentMethodEpic,
  // SHIPPING
  getAllShippingMethodsEpic,
  updateShippingMethodEpic,
  addShippingMethodEpic,
  removeShippingMethodEpic,
  getSingleShippingMethodEpic,
  updateLocalShippingMethodEpic,
  // HOME TABS
  getAllHomeTabsEpic,
  removeHomeTabEpic,
  getHomeTabRelatedProductsEpic,
  updateHomeTabEpic,
  addHomeTabEpic,
  // HOME SECTIONS
  getAllHomeSectionsEpic,
  getHomeSectionRelatedProductsEpic,
  addHomeSectionEpic,
  removeHomeSectionEpic,
  updateHomeSectionEpic,
  // HOME BANNERS
  getAllHomeBannersEpic,
  removeHomeBannersEpic,
  addHomeBannerEpic,
  uploadHomeBannerImageEpic,
  updateHomeBannerEpic,
  // HOME LAST BANNERS
  getAllHomeLastBannersEpic,
  removeHomeLastBannersEpic,
  updateHomeLastBannerEpic,
  uploadHomeLastBannerImageEpic,
  addHomeLastBannerEpic,
  // KEYWORDS
  getAllKeyWordsEpic,
  updateKeyWordEpic,
  // COUPONS
  getAllCouponsEpic,
  removeCouponEpic,
  addCouponEpic,
  updateCouponEpic,
  uploadSingleImageEpic,
  // NEWSLETTERCOUPON
  getNewsletterCouponEpic,
  toggleNewsletterCouponEpic,
);
