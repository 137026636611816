/* eslint-disable react-hooks/exhaustive-deps */
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  getAllPaymentMethods,
  setPaymentMethodsLoading,
  removePaymentMethod,
} from 'models/actions/settingsActions';
import { paymentSettings } from 'models/selectors/settingsSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PaymentMethod from './PaymentMethod';
import PaymentMethodPopup from './PaymentMethodPopup';

const PaymentMethods = () => {
  const dispatch = useDispatch();
  const paymentMethods = useSelector(paymentSettings)?.methods;
  const loading = useSelector(paymentSettings)?.loading;
  const [paymentMethodPopupOpen, setPaymentMethodPopupOpen] = useState({
    open: false,
    paymentMethodOpenForEdit: '',
  });

  useEffect(() => {
    dispatch(setPaymentMethodsLoading(true));
    dispatch(getAllPaymentMethods());
  }, []);

  const togglePMOpenWithId = (pmId) => {
    setPaymentMethodPopupOpen({
      ...paymentMethodPopupOpen,
      open: true,
      paymentMethodOpenForEdit: pmId,
    });
  };

  const togglePMWithRemove = (pmId, isActive) => {
    setPaymentMethodPopupOpen({
      ...paymentMethodPopupOpen,
      open: false,
      paymentMethodOpenForEdit: '',
    });
    dispatch(setGeneralLoading(true));
    dispatch(removePaymentMethod({ id: pmId, isActive }));
  };

  return (
    <div>
      {paymentMethodPopupOpen?.open && (
        <PaymentMethodPopup
          open={paymentMethodPopupOpen?.open}
          pmId={paymentMethodPopupOpen?.paymentMethodOpenForEdit}
          handleClose={() =>
            setPaymentMethodPopupOpen({
              ...paymentMethodPopupOpen,
              open: false,
              paymentMethodOpenForEdit: '',
            })
          }
        />
      )}
      {!loading ? (
        <>
          <div className="products-crud-actions double auto">
            <span>{`(${paymentMethods.length || 0} τρόποι πληρωμής)`}</span>
            <button
              className="button add"
              onClick={() =>
                setPaymentMethodPopupOpen({
                  ...paymentMethodPopupOpen,
                  open: true,
                  paymentMethodOpenForEdit: '',
                })
              }>
              ΔΗΜΙΟΥΡΓΙΑ
            </button>
          </div>
          {paymentMethods?.length > 0 ? (
            <Table className="products-grid">
              <TableHead>
                <TableRow>
                  <TableCell>ΟΝΟΜΑ</TableCell>
                  <TableCell>ΚΟΣΤΟΣ</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentMethods?.map((pm) => (
                  <PaymentMethod
                    key={pm.id}
                    pm={pm}
                    togglePM={togglePMOpenWithId}
                    togglePMWithRemove={() =>
                      togglePMWithRemove(pm?.id, !pm?.isActive)
                    }
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="not-found">Δεν βρέθηκαν τρόποι πληρωμής!</div>
          )}
        </>
      ) : (
        <>
          <Skeleton width="60%" />
          <Skeleton width="60%" />
          <Skeleton variant="rectangular" height={250} />
        </>
      )}
    </div>
  );
};

export default PaymentMethods;
