/* eslint-disable react-hooks/exhaustive-deps */
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  getAllHomeSections,
  setHomeSectionsLoading,
  removeHomeSection,
} from 'models/actions/settingsActions';
import { homeSectionsSettings } from 'models/selectors/settingsSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HomeSection from './HomeSection';
import HomeSectionsPopup from './HomeSectionsPopup';

const HomeSectionsSettings = () => {
  const dispatch = useDispatch();
  const homeSections = useSelector(homeSectionsSettings)?.sections;
  const loading = useSelector(homeSectionsSettings)?.loading;
  const [homeSectionPopupOpen, setHomeSectionPopupOpen] = useState({
    open: false,
    homeSectionOpenForEdit: '',
  });

  useEffect(() => {
    dispatch(setHomeSectionsLoading(true));
    dispatch(getAllHomeSections());
  }, []);

  const toggleHSOpenWithId = (hsId) => {
    setHomeSectionPopupOpen({
      ...homeSectionPopupOpen,
      open: true,
      homeSectionOpenForEdit: hsId,
    });
  };

  const toggleHSWithRemove = (hsId, isActive) => {
    setHomeSectionPopupOpen({
      ...homeSectionPopupOpen,
      open: false,
      homeSectionOpenForEdit: '',
    });
    dispatch(setGeneralLoading(true));
    dispatch(removeHomeSection({ id: hsId, isActive }));
  };

  return (
    <div>
      {homeSectionPopupOpen?.open && (
        <HomeSectionsPopup
          open={homeSectionPopupOpen?.open}
          hsId={homeSectionPopupOpen?.homeSectionOpenForEdit}
          handleClose={() =>
            setHomeSectionPopupOpen({
              ...homeSectionPopupOpen,
              open: false,
              homeSectionOpenForEdit: '',
            })
          }
        />
      )}
      {!loading ? (
        <>
          <div className="products-crud-actions double auto">
            <span>{`(${homeSections?.length || 0} περιοχές προϊόντων)`}</span>
            <button
              className="button add"
              onClick={() =>
                setHomeSectionPopupOpen({
                  ...homeSectionPopupOpen,
                  open: true,
                  homeSectionOpenForEdit: '',
                })
              }>
              ΔΗΜΙΟΥΡΓΙΑ
            </button>
          </div>
          {homeSections?.length > 0 ? (
            <Table className="products-grid">
              <TableHead>
                <TableRow>
                  <TableCell>ΤΙΤΛΟΣ</TableCell>
                  <TableCell>ΑΡΙΘΜΟΣ ΠΡΟΪΟΝΤΩΝ</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {homeSections?.map((hs) => (
                  <HomeSection
                    key={hs.id}
                    hs={hs}
                    toggleHS={toggleHSOpenWithId}
                    toggleHSWithRemove={() =>
                      toggleHSWithRemove(hs?.id, !hs?.isActive)
                    }
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="not-found">Δεν βρέθηκαν περιοχές!</div>
          )}
        </>
      ) : (
        <>
          <Skeleton width="60%" />
          <Skeleton width="60%" />
          <Skeleton variant="rectangular" height={250} />
        </>
      )}
    </div>
  );
};

export default HomeSectionsSettings;
