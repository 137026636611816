/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import {
  getCategoryDetails,
  setGeneralLoading,
  setSubCatSearchTerm,
  setSubCategoriesForCategoryId,
  getSubCategories,
  setAvailableSubCategories,
  updateCategory,
  addCategory,
} from 'models/actions/generalActions';
import {
  allCategories,
  subCategorySearchText,
  allSubCategoriesForCategoryId,
  allAvailableSubCategories,
} from 'models/selectors/generalSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const CategoryPopup = ({ categoryId, handleClose, open }) => {
  const dispatch = useDispatch();
  const categories = useSelector(allCategories);
  const subCategoriesForCategoryId = useSelector(allSubCategoriesForCategoryId);
  const subCatSearchText = useSelector(subCategorySearchText);
  const availableSubCategories = useSelector(allAvailableSubCategories);
  const [categoryPopup, setCategoryPopup] = useState({
    name: '',
    description: '',
    subCategories: [],
  });
  const [error, setError] = useState('');

  useEffect(() => {
    dispatch(setSubCategoriesForCategoryId([]));
    dispatch(setAvailableSubCategories([]));
    dispatch(setSubCatSearchTerm(''));
    if (categoryId !== '') {
      setCategoryPopup(
        categories?.results?.find((category) => category?.id === categoryId) ||
          categoryPopup,
      );
      dispatch(setGeneralLoading(true));
      dispatch(getCategoryDetails(categoryId));
    }

    return () => {
      dispatch(setGeneralLoading(false));
      setCategoryPopup({ name: '', description: '', id: '' });
    };
  }, [categoryId]);

  const handleCategoryUpdate = () => {
    if (categoryPopup && categoryPopup?.name === '') {
      setError('Error');

      return false;
    }

    setError('');
    dispatch(setGeneralLoading(true));
    if (categoryId !== '') {
      dispatch(
        updateCategory({
          categoryId: categoryPopup?.id,
          categoryName: categoryPopup?.name,
          categoryDescription: categoryPopup?.description,
          subCategories: subCategoriesForCategoryId,
        }),
      );
    } else {
      dispatch(
        addCategory({
          name: categoryPopup?.name,
          description: categoryPopup?.description,
          subCategories: subCategoriesForCategoryId,
        }),
      );
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>
            {categoryId === '' ? 'ΔΗΜΙΟΥΡΓΙΑ ΚΑΤΗΓΟΡΙΑΣ' : categoryPopup?.name}
          </span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="category-name">Όνομα</InputLabel>
                <Input
                  id="category-name"
                  error={error !== ''}
                  value={categoryPopup?.name ? categoryPopup?.name : ''}
                  onChange={(e) =>
                    setCategoryPopup({
                      ...categoryPopup,
                      name: e.target.value,
                    })
                  }
                />
                {error && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε όνομα.
                  </span>
                )}
              </FormControl>
            </div>
            <div className="product-popup-field more-margin-bottom">
              <FormControl>
                <InputLabel htmlFor="category-popup-description">
                  Μικρή περιγραφή κατηγορίας
                </InputLabel>
                <Input
                  id="category-popup-description"
                  value={
                    categoryPopup?.description ? categoryPopup?.description : ''
                  }
                  minRows={4}
                  multiline={true}
                  maxRows={4}
                  onChange={(e) =>
                    setCategoryPopup({
                      ...categoryPopup,
                      description: e.target.value,
                    })
                  }
                />
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <Autocomplete
                  multiple
                  clearOnBlur={false}
                  className={
                    subCategoriesForCategoryId?.length > 0 ? 'filled' : ''
                  }
                  id="product-popup-subcategories"
                  options={availableSubCategories || []}
                  getOptionLabel={(option) => `${option['name']}`}
                  value={subCategoriesForCategoryId || []}
                  inputValue={subCatSearchText}
                  noOptionsText="Δεν βρέθηκαν επιλογές"
                  onChange={(e, subcategory) =>
                    dispatch(setSubCategoriesForCategoryId([...subcategory]))
                  }
                  onInputChange={(e, value) => {
                    dispatch(setSubCatSearchTerm(value));
                    dispatch(getSubCategories(value));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Υποκατηγορίες"
                      placeholder="Επιλογή"
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handleCategoryUpdate} className="button add">
            {categoryId !== '' ? 'ΕΝΗΜΕΡΩΣΗ' : 'ΔΗΜΙΟΥΡΓΙΑ'}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CategoryPopup;
