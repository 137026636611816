/* eslint-disable react-hooks/exhaustive-deps */
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  setCouponsLoading,
  getAllCoupons,
  removeCoupon,
} from 'models/actions/settingsActions';
import { couponsSettings } from 'models/selectors/settingsSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Coupon from './Coupon';
import CouponPopup from './CouponPopup';

const Coupons = () => {
  const dispatch = useDispatch();
  const [couponsPopupOpen, setCouponsPopupOpen] = useState({
    open: false,
    couponOpenForEdit: '',
  });
  const coupons = useSelector(couponsSettings)?.coupons;
  const loading = useSelector(couponsSettings)?.loading;

  useEffect(() => {
    dispatch(setCouponsLoading(true));
    dispatch(getAllCoupons());
  }, []);

  const toggleCouponOpenWithId = (kwId) => {
    setCouponsPopupOpen({
      ...couponsPopupOpen,
      open: true,
      couponOpenForEdit: kwId,
    });
  };

  const toggleCouponWithRemove = (hbId, isActive) => {
    dispatch(setGeneralLoading(true));
    dispatch(removeCoupon({ id: hbId, isActive: Number(isActive) }));
  };

  return (
    <div>
      {couponsPopupOpen?.open && (
        <CouponPopup
          id={couponsPopupOpen?.couponOpenForEdit}
          open={couponsPopupOpen?.open}
          coupon={coupons?.find(
            (coupon) => coupon?.id === couponsPopupOpen?.couponOpenForEdit,
          )}
          handleClose={() =>
            setCouponsPopupOpen({
              ...couponsPopupOpen,
              open: false,
              couponOpenForEdit: '',
            })
          }
        />
      )}
      {!loading ? (
        <>
          <div className="products-crud-actions double auto">
            <span>{`(${coupons?.length || 0} κουπόνια)`}</span>
            <button
              className="button add"
              onClick={() =>
                setCouponsPopupOpen({
                  ...couponsPopupOpen,
                  open: true,
                  couponOpenForEdit: '',
                })
              }>
              ΔΗΜΙΟΥΡΓΙΑ
            </button>
          </div>
          {coupons?.length > 0 ? (
            <Table className="products-grid">
              <TableHead>
                <TableRow>
                  <TableCell>ΚΩΔΙΚΟΣ</TableCell>
                  <TableCell>ΔΙΑΡΚΕΙΑ (ώρες)</TableCell>
                  <TableCell>ΠΟΣΟΣΤΙΑΙΑ ΕΚΠΤΩΣΗ</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {coupons?.map((coupon) => (
                  <Coupon
                    key={coupon.id}
                    cp={coupon}
                    toggleCouponWithRemove={() =>
                      toggleCouponWithRemove(coupon?.id, !coupon?.isActive)
                    }
                    toggleCoupon={toggleCouponOpenWithId}
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="not-found">Δεν βρέθηκαν σελίδες με coupons!</div>
          )}
        </>
      ) : (
        <>
          <Skeleton width="60%" />
          <Skeleton width="60%" />
          <Skeleton variant="rectangular" height={250} />
        </>
      )}
    </div>
  );
};

export default Coupons;
