import { createAction } from '@reduxjs/toolkit';

const getStaticContent = createAction('general/getStaticContent');
const setStaticContent = createAction('general/setStaticContent');
const getSocialNetworks = createAction('general/getSocialNetworks');
const setSocialNetworks = createAction('general/setSocialNetworks');
const updateSocial = createAction('general/updateSocial');
const addSocial = createAction('general/addSocial');
const removeSocial = createAction('general/removeSocial');
const updatePage = createAction('general/updatePage');
const addPage = createAction('general/addPage');
const removePage = createAction('general/removePage');
const togglePageInMainMenu = createAction('general/togglePageInMainMenu');
const togglePageInFooter = createAction('general/togglePageInFooter');
const getBusinessDetails = createAction('general/getBusinessDetails');
const setBusinessDetails = createAction('general/setBusinessDetails');
const saveBusinessDetails = createAction('general/saveBusinessDetails');

export {
  getStaticContent,
  setStaticContent,
  updatePage,
  addPage,
  removePage,
  togglePageInMainMenu,
  togglePageInFooter,
  getSocialNetworks,
  setSocialNetworks,
  updateSocial,
  addSocial,
  removeSocial,
  getBusinessDetails,
  setBusinessDetails,
  saveBusinessDetails,
};
