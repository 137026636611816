import { createAction } from '@reduxjs/toolkit';

const getOfferTitle = createAction('settings/getOfferTitle');
const setOfferTitle = createAction('settings/setOfferTitle');
const setOfferTitleLoading = createAction('settings/setOfferTitleLoading');
const updateOfferTitle = createAction('settings/updateOfferTitle');
const uploadSingleImage = createAction('settings/uploadSingleImage');

// LOGO
const uploadLogoImage = createAction('settings/uploadLogoImage');
const setLogoImage = createAction('settings/setLogoImage');
const saveLogoImageDB = createAction('settings/saveLogoImageDB');
const getLogo = createAction('settings/getLogo');

// COUNTRIES
const setCountries = createAction('settings/setCountries');

// PREFECTURES
const setPrefectures = createAction('settings/setPrefectures');

// PAYMENTS
const getAllPaymentMethods = createAction('settings/getAllPaymentMethods');
const setAllPaymentMethods = createAction('settings/setAllPaymentMethods');
const setPaymentMethodsLoading = createAction(
  'settings/setPaymentMethodsLoading',
);
const updatePaymentMethod = createAction('settings/updatePaymentMethod');
const addPaymentMethod = createAction('settings/addPaymentMethod');
const removePaymentMethod = createAction('settings/removePaymentMethod');

// SHIPPING
const getAllShippingMethods = createAction('settings/getAllShippingMethods');
const setAllShippingMethods = createAction('settings/setAllShippingMethods');
const setShippingMethodsLoading = createAction(
  'settings/setShippingMethodsLoading',
);
const getSingleShippingMethod = createAction(
  'settings/getSingleShippingMethod',
);
const setSingleShippingMethod = createAction(
  'settings/setSingleShippingMethod',
);
const updateLocalShippingMethod = createAction(
  'settings/updateLocalShippingMethod',
);

const updateShippingMethod = createAction('settings/updateShippingMethod');
const addShippingMethod = createAction('settings/addShippingMethod');
const removeShippingMethod = createAction('settings/removeShippingMethod');

// HOME TABS
const setHomeTabsLoading = createAction('settings/setHomeTabsLoading');
const getAllHomeTabs = createAction('settings/getAllHomeTabs');
const setAllHomeTabs = createAction('settings/setAllHomeTabs');
const removeHomeTab = createAction('settings/removeHomeTab');
const updateHomeTab = createAction('settings/updateHomeTab');
const addHomeTab = createAction('settings/addHomeTab');
const getHomeTabRelatedProducts = createAction(
  'settings/getHomeTabRelatedProducts',
);
const setHomeTabsSearchedProducts = createAction(
  'settings/setHomeTabsSearchedProducts',
);

// HOME SECTIONS
const setHomeSectionsLoading = createAction('settings/setHomeSectionsLoading');
const getAllHomeSections = createAction('settings/getAllHomeSections');
const setAllHomeSections = createAction('settings/setAllHomeSections');
const removeHomeSection = createAction('settings/removeHomeSection');
const updateHomeSection = createAction('settings/updateHomeSection');
const addHomeSection = createAction('settings/addHomeSection');
const getHomeSectionRelatedProducts = createAction(
  'settings/getHomeSectionRelatedProducts',
);
const setHomeSectionsSearchedProducts = createAction(
  'settings/setHomeSectionsSearchedProducts',
);

// HOME BANNERS
const getAllHomeBanners = createAction('settings/getAllHomeBanners');
const removeHomeBanners = createAction('settings/removeHomeBanners');
const setHomeBannersLoading = createAction('settings/setHomeBannersLoading');
const setAllHomeBanners = createAction('settings/setAllHomeBanners');
const setHomeBannersSearchedProducts = createAction(
  'settings/setHomeBannersSearchedProducts',
);
const updateHomeBanner = createAction('settings/updateHomeBanner');
const addHomeBanner = createAction('settings/addHomeBanner');
const setHomeBannerImage = createAction('settings/setHomeBannerImage');

const uploadHomeBannerImage = createAction('settings/uploadHomeBannerImage');

// HOME LAST BANNERS
const getAllHomeLastBanners = createAction('settings/getAllHomeLastBanners');
const removeHomeLastBanners = createAction('settings/removeHomeLastBanners');
const setHomeLastBannersLoading = createAction(
  'settings/setHomeLastBannersLoading',
);
const setAllHomeLastBanners = createAction('settings/setAllHomeLastBanners');
const updateHomeLastBanner = createAction('settings/updateHomeLastBanner');
const addHomeLastBanner = createAction('settings/addHomeLastBanner');
const setHomeLastBannerImage = createAction('settings/setHomeLastBannerImage');
const uploadHomeLastBannerImage = createAction(
  'settings/uploadHomeLastBannerImage',
);

// KEYWORDS
const getAllKeyWords = createAction('settings/getAllKeyWords');
const setAllKeyWords = createAction('settings/setAllKeyWords');
const setKeyWordsLoading = createAction('settings/setKeyWordsLoading');
const updateKeyWord = createAction('settings/updateKeyWord');

// COUPONS
const getAllCoupons = createAction('settings/getAllCoupons');
const setAllCoupons = createAction('settings/setAllCoupons');
const setCouponsLoading = createAction('settings/setCouponsLoading');
const updateCoupon = createAction('settings/updateCoupon');
const removeCoupon = createAction('settings/removeCoupon');
const addCoupon = createAction('settings/addCoupon');

// NEWSLETTERCOUPON
const getNewsletterCoupon = createAction('settings/getNewsletterCoupon');
const setNewsletterCoupon = createAction('settings/setNewsletterCoupon');
const toggleNewsletterCoupon = createAction('settings/toggleNewsletterCoupon');
const setNewsletterCouponLoading = createAction(
  'settings/setNewsletterCouponLoading',
);

export {
  getOfferTitle,
  setOfferTitle,
  setOfferTitleLoading,
  updateOfferTitle,
  // LOGO
  uploadLogoImage,
  setLogoImage,
  saveLogoImageDB,
  getLogo,
  // COUNTRIES
  setCountries,
  // PREFECTURES
  setPrefectures,
  // PAYMENTS
  getAllPaymentMethods,
  setPaymentMethodsLoading,
  setAllPaymentMethods,
  updatePaymentMethod,
  addPaymentMethod,
  removePaymentMethod,
  // SHIPPING
  getAllShippingMethods,
  setAllShippingMethods,
  setShippingMethodsLoading,
  updateShippingMethod,
  addShippingMethod,
  removeShippingMethod,
  getSingleShippingMethod,
  setSingleShippingMethod,
  updateLocalShippingMethod,
  // HOME TABS
  setHomeTabsLoading,
  getAllHomeTabs,
  setAllHomeTabs,
  removeHomeTab,
  updateHomeTab,
  addHomeTab,
  getHomeTabRelatedProducts,
  setHomeTabsSearchedProducts,
  // HOME SECTIONS
  setHomeSectionsLoading,
  getAllHomeSections,
  setAllHomeSections,
  removeHomeSection,
  updateHomeSection,
  addHomeSection,
  getHomeSectionRelatedProducts,
  setHomeSectionsSearchedProducts,
  // HOME BANNERS
  setHomeBannersLoading,
  setAllHomeBanners,
  setHomeBannersSearchedProducts,
  getAllHomeBanners,
  removeHomeBanners,
  updateHomeBanner,
  addHomeBanner,
  setHomeBannerImage,
  uploadHomeBannerImage,
  // HOME LAST BANNERS
  setHomeLastBannersLoading,
  setAllHomeLastBanners,
  getAllHomeLastBanners,
  removeHomeLastBanners,
  updateHomeLastBanner,
  addHomeLastBanner,
  setHomeLastBannerImage,
  uploadHomeLastBannerImage,
  // KEYWORDS
  getAllKeyWords,
  setAllKeyWords,
  setKeyWordsLoading,
  updateKeyWord,
  // COUPONS
  getAllCoupons,
  setAllCoupons,
  setCouponsLoading,
  updateCoupon,
  removeCoupon,
  addCoupon,
  uploadSingleImage,
  // NEWSLETTERCOUPON
  getNewsletterCoupon,
  setNewsletterCoupon,
  setNewsletterCouponLoading,
  toggleNewsletterCoupon,
};
