/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  updateShippingMethod,
  addShippingMethod,
  getSingleShippingMethod,
  updateLocalShippingMethod,
} from 'models/actions/settingsActions';
import {
  countriesWithPrefectures,
  shippingSettings,
} from 'models/selectors/settingsSelectors';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ShippingMethodPopup = ({ open, smId, handleClose }) => {
  const dispatch = useDispatch();
  const allCountriesWithPrefectures = useSelector(countriesWithPrefectures);
  const shippingMethod = useSelector(shippingSettings)?.singleMethod;

  const [collapsedCountry, setCollapsedCountry] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (smId) {
      dispatch(setGeneralLoading(true));
      dispatch(getSingleShippingMethod(smId));
    }

    return () => {
      dispatch(setGeneralLoading(false));
    };
  }, [smId]);

  const handleShippingMethodUpdate = () => {
    if (shippingMethod?.name === '') {
      setError('Error');

      return false;
    }

    setError('');
    dispatch(setGeneralLoading(true));
    if (smId !== '') {
      dispatch(updateShippingMethod(shippingMethod));
    } else {
      dispatch(addShippingMethod(shippingMethod));
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => handleClose()}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>
            {smId === '' ? 'ΔΗΜΙΟΥΡΓΙΑ ΤΡΟΠΟΥ ΑΠΟΣΤΟΛΗΣ' : shippingMethod?.name}
          </span>
          <IconButton
            className="p0 iconbutton"
            aria-label="close"
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="shipping-method-name">Όνομα</InputLabel>
                <Input
                  id="shipping-method-name"
                  error={error !== ''}
                  value={shippingMethod?.name}
                  onChange={(e) =>
                    dispatch(
                      updateLocalShippingMethod({
                        field: 'name',
                        value: e.target.value,
                      }),
                    )
                  }
                />
                {error && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε όνομα.
                  </span>
                )}
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="shipping-method-popup-description">
                  Μικρή περιγραφή τρόπου αποστολής
                </InputLabel>
                <Input
                  id="shipping-method-popup-description"
                  value={shippingMethod?.description || ''}
                  minRows={4}
                  multiline={true}
                  maxRows={4}
                  onChange={(e) =>
                    dispatch(
                      updateLocalShippingMethod({
                        field: 'description',
                        value: e.target.value,
                      }),
                    )
                  }
                />
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <InputLabel className="label-for-countries">
                  Χώρες/Νομοί/Κόστη
                </InputLabel>
                <List className="countries-list">
                  {Object.keys(allCountriesWithPrefectures)?.map((country) => (
                    <React.Fragment key={country}>
                      <ListItem>
                        <div>
                          <Checkbox id={country} />
                          <label htmlFor={country}>
                            {
                              allCountriesWithPrefectures?.[country]
                                ?.country_name
                            }
                          </label>
                        </div>
                        {allCountriesWithPrefectures?.[country]?.prefectures
                          ?.length > 0 && (
                          <i
                            className={
                              country !== collapsedCountry
                                ? 'icon-down-dir'
                                : 'icon-up-dir'
                            }
                            onClick={() => setCollapsedCountry(country)}
                          />
                        )}
                      </ListItem>
                      <Collapse
                        in={country === collapsedCountry}
                        timeout="auto"
                        unmountOnExit>
                        {allCountriesWithPrefectures?.[
                          country
                        ]?.prefectures?.map((prefecture) => (
                          <List key={prefecture?.id}>
                            <ListItem>
                              <Checkbox
                                checked={
                                  shippingMethod?.selectedPrefectures?.filter(
                                    (p) => prefecture?.id === p.id,
                                  )?.length > 0
                                }
                                id={`prefecture_${prefecture?.id}`}
                                onChange={(e) =>
                                  dispatch(
                                    updateLocalShippingMethod({
                                      field: 'prefecture',
                                      value: e.target.checked,
                                      id: prefecture?.id,
                                      cost:
                                        shippingMethod?.selectedPrefectures?.find(
                                          (p) => p?.id === prefecture?.id,
                                        )?.cost || 0,
                                    }),
                                  )
                                }
                              />
                              <label htmlFor={`prefecture_${prefecture?.id}`}>
                                {prefecture?.prefecture_name}
                              </label>
                              <FormControl>
                                <Input
                                  value={
                                    shippingMethod?.selectedPrefectures?.find(
                                      (p) => p?.id === prefecture?.id,
                                    )?.cost || 0
                                  }
                                  disabled={
                                    shippingMethod?.selectedPrefectures?.filter(
                                      (p) => prefecture?.id === p.id,
                                    )?.length === 0
                                  }
                                  onChange={(e) =>
                                    dispatch(
                                      updateLocalShippingMethod({
                                        field: 'cost',
                                        value: e.target.value,
                                        id: prefecture?.id,
                                      }),
                                    )
                                  }
                                />
                              </FormControl>
                              <span>€</span>
                            </ListItem>
                          </List>
                        ))}
                      </Collapse>
                    </React.Fragment>
                  ))}
                </List>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handleShippingMethodUpdate} className="button add">
            {smId !== '' ? 'ΕΝΗΜΕΡΩΣΗ' : 'ΔΗΜΙΟΥΡΓΙΑ'}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShippingMethodPopup;
