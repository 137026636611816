import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const Subcategory = ({
  subCategory,
  toggleSubCategory,
  toggleSubCategoryWithRemove,
}) => {
  const { id, name, isActive } = subCategory;

  return (
    <TableRow className="product-row extra">
      <TableCell>{name}</TableCell>

      <TableCell>
        <Tooltip title="Ενημέρωση κατηγορίας">
          <i
            onClick={() => toggleSubCategory(id)}
            className="icon-pencil edit"
          />
        </Tooltip>
        <Tooltip title="Διαγραφή κατηγορίας">
          <i
            onClick={() => toggleSubCategoryWithRemove(id)}
            className={`${
              isActive
                ? 'icon-toggle-on add-icon'
                : 'icon-toggle-off delete-icon'
            }`}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default Subcategory;
