/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import {
  setGeneralLoading,
  updateBrand,
  addBrand,
} from 'models/actions/generalActions';
import { allBrands } from 'models/selectors/generalSelectors';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const BrandPopup = ({ open, brandId, handleClose }) => {
  const dispatch = useDispatch();
  const brands = useSelector(allBrands);
  const [brandPopup, setBrandPopup] = useState({
    name: '',
    description: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    setBrandPopup(
      brands?.results?.find((brand) => brand?.id === brandId) || brandPopup,
    );

    return () => {
      dispatch(setGeneralLoading(false));
      setBrandPopup({ id: '', name: '', description: '' });
    };
  }, [brandId]);

  const handleBrandUpdate = () => {
    if (brandPopup && brandPopup?.name === '') {
      setError('Error');

      return false;
    }

    setError('');
    dispatch(setGeneralLoading(true));
    if (brandId !== '') {
      dispatch(updateBrand(brandPopup));
    } else {
      dispatch(addBrand(brandPopup));
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>{brandId === '' ? 'ΔΗΜΙΟΥΡΓΙΑ BRAND' : brandPopup?.name}</span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="brand-name">Όνομα</InputLabel>
                <Input
                  id="brand-name"
                  error={error !== ''}
                  value={brandPopup?.name ? brandPopup?.name : ''}
                  onChange={(e) =>
                    setBrandPopup({ ...brandPopup, name: e.target.value })
                  }
                />
                {error && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε όνομα.
                  </span>
                )}
              </FormControl>
            </div>
            <div className="product-popup-field more-margin-bottom">
              <FormControl>
                <InputLabel htmlFor="brand-popup-description">
                  Μικρή περιγραφή brand
                </InputLabel>
                <Input
                  id="brand-popup-description"
                  value={brandPopup?.description ? brandPopup?.description : ''}
                  minRows={4}
                  multiline={true}
                  maxRows={4}
                  onChange={(e) =>
                    setBrandPopup({
                      ...brandPopup,
                      description: e.target.value,
                    })
                  }
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handleBrandUpdate} className="button add">
            {brandId !== '' ? 'ΕΝΗΜΕΡΩΣΗ' : 'ΔΗΜΙΟΥΡΓΙΑ'}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BrandPopup;
