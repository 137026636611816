import makeRequest from 'library/makeRequest';
import {
  setGeneralLoading,
  toggleShowAlert,
  // CATEGORIES
  getAllCategories,
  setGeneralCategories,
  getCategoryDetails,
  setSubCategoriesForCategoryId,
  getSubCategories,
  setAvailableSubCategories,
  updateCategory,
  addCategory,
  setSubCatSearchTerm,
  removeCategory,
  // SUBCATEGORIES
  getAllSubCategories,
  setGeneralSubCategories,
  updateSubCategory,
  addSubCategory,
  removeSubCategory,
  // BRANDS
  getAllBrands,
  setGeneralBrands,
  updateBrand,
  addBrand,
  removeBrand,
} from 'models/actions/generalActions';
import { token } from 'models/selectors/userSelectors';
import { combineEpics, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, concatMap, filter } from 'rxjs/operators';

import catchErrorOperator from './operators/catchErrorOperator';

// CATEGORIES
const getAllCategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllCategories.type),
    mergeMap(() =>
      from(
        makeRequest('categories/admin', 'GET', '', token(state$.value)),
      ).pipe(
        concatMap(({ total, results }) => {
          return [
            setGeneralCategories({ total, results }),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getCategoryDetailsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getCategoryDetails.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `categories/admin/category/${payload}`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap(({ subCategories }) => {
          return [
            setSubCategoriesForCategoryId(subCategories),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getSubCategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(getSubCategories.type),
    filter(({ payload }) => payload !== ''),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `subcategories/admin/search?subcat=${payload}`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap(({ result }) => [
          setAvailableSubCategories(result),
          setGeneralLoading(false),
        ]),
        catchErrorOperator(true),
      ),
    ),
  );

const updateCategoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateCategory.type),
    mergeMap(
      ({
        payload: {
          categoryId,
          categoryName,
          categoryDescription,
          subCategories,
        },
      }) =>
        from(
          makeRequest(
            'categories/admin/update',
            'POST',
            JSON.stringify({
              categoryId,
              categoryName,
              categoryDescription,
              subCategories,
            }),
            token(state$.value),
          ),
        ).pipe(
          concatMap((payload) => {
            if (payload?.error) {
              return [
                setGeneralLoading(false),
                toggleShowAlert({
                  message: `${payload?.error}`,
                  show: true,
                  type: 'error',
                }),
              ];
            }

            return [
              toggleShowAlert({
                message: `${payload?.message}`,
                show: true,
                type: 'success',
              }),
              setGeneralLoading(false),
              getAllCategories(),
            ];
          }),
          catchErrorOperator(true),
        ),
    ),
  );

const addCategoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(addCategory.type),
    mergeMap(({ payload: { name, description, subCategories } }) =>
      from(
        makeRequest(
          'categories/admin/add',
          'POST',
          JSON.stringify({ name, description, subCategories }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            setSubCatSearchTerm(''),
            setAvailableSubCategories([]),
            setSubCategoriesForCategoryId([]),
            getAllCategories(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const removeCategoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeCategory.type),
    mergeMap(({ payload: { id, isActive } }) =>
      from(
        makeRequest(
          'categories/admin/delete',
          'POST',
          JSON.stringify({
            categoryId: Number(id),
            isActive: Number(isActive),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            setSubCatSearchTerm(''),
            setAvailableSubCategories([]),
            setSubCategoriesForCategoryId([]),
            getAllCategories(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

// SUBCATEGORIES
const getAllSubCategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllSubCategories.type),
    mergeMap(() =>
      from(
        makeRequest('subcategories/admin', 'GET', '', token(state$.value)),
      ).pipe(
        concatMap(({ total, results }) => {
          return [
            setGeneralSubCategories({ total, results }),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const updateSubCategoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateSubCategory.type),
    mergeMap(({ payload: { id, name, description } }) =>
      from(
        makeRequest(
          'subcategories/admin/update',
          'POST',
          JSON.stringify({
            subCategoryId: id,
            subCategoryName: name,
            subCategoryDescription: description,
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllSubCategories(),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const addSubCategoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(addSubCategory.type),
    mergeMap(({ payload: { name, description } }) =>
      from(
        makeRequest(
          'subcategories/admin/add',
          'POST',
          JSON.stringify({ name, description }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllSubCategories(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const removeSubCategoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeSubCategory.type),
    mergeMap(({ payload: { id, isActive } }) =>
      from(
        makeRequest(
          'subcategories/admin/delete',
          'POST',
          JSON.stringify({
            subCategoryId: Number(id),
            isActive: Number(isActive),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllSubCategories(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

// BRANDS
const getAllBrandsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getAllBrands.type),
    mergeMap(() =>
      from(makeRequest('brands/admin', 'GET', '', token(state$.value))).pipe(
        concatMap(({ total, results }) => {
          return [
            setGeneralBrands({ total, results }),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const updateBrandEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateBrand.type),
    mergeMap(({ payload: { id, name, description } }) =>
      from(
        makeRequest(
          'brands/admin/update',
          'POST',
          JSON.stringify({
            brandId: id,
            brandName: name,
            brandDescription: description,
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            getAllBrands(),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const addBrandEpic = (action$, state$) =>
  action$.pipe(
    ofType(addBrand.type),
    mergeMap(({ payload: { name, description } }) =>
      from(
        makeRequest(
          'brands/admin/add',
          'POST',
          JSON.stringify({ name, description }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllBrands(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const removeBrandEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeBrand.type),
    mergeMap(({ payload: { id, isActive } }) =>
      from(
        makeRequest(
          'brands/admin/delete',
          'POST',
          JSON.stringify({ brandId: Number(id), isActive: Number(isActive) }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
            setGeneralLoading(false),
            getAllBrands(),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

export {
  // CATEGORIES
  getAllCategoriesEpic,
  getCategoryDetailsEpic,
  getSubCategoriesEpic,
  updateCategoryEpic,
  addCategoryEpic,
  removeCategoryEpic,
  // SUBCATEGORIES
  getAllSubCategoriesEpic,
  updateSubCategoryEpic,
  addSubCategoryEpic,
  removeSubCategoryEpic,
  // BRANDS
  getAllBrandsEpic,
  updateBrandEpic,
  addBrandEpic,
  removeBrandEpic,
};

export default combineEpics(
  // CATEGORIES
  getAllCategoriesEpic,
  getCategoryDetailsEpic,
  getSubCategoriesEpic,
  updateCategoryEpic,
  addCategoryEpic,
  removeCategoryEpic,
  // SUBCATEGORIES
  getAllSubCategoriesEpic,
  updateSubCategoryEpic,
  addSubCategoryEpic,
  removeSubCategoryEpic,
  // BRANDS
  getAllBrandsEpic,
  updateBrandEpic,
  addBrandEpic,
  removeBrandEpic,
);
