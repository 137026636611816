/* eslint-disable comma-dangle */
import { configureStore } from '@reduxjs/toolkit';
import rootEpics from 'models/epics/rootEpics';
import dashboardReducer from 'models/reducers/dashboardReducer';
import generalReducer from 'models/reducers/generalReducer';
import ordersReducer from 'models/reducers/ordersReducer';
import productsReducer from 'models/reducers/productsReducer';
import settingsReducer from 'models/reducers/settingsReducer';
import staticReducer from 'models/reducers/staticReducer';
import userReducer from 'models/reducers/userReducer';
import { combineReducers } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

const persistConfig = {
  key: 'adminstate',
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    userReducer,
    generalReducer,
    productsReducer,
    ordersReducer,
    dashboardReducer,
    settingsReducer,
    staticReducer,
  }),
);

const epicMiddleWare = createEpicMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: [epicMiddleWare],
  devTools: process.env.NODE_ENV !== 'production',
});

epicMiddleWare.run(rootEpics);
export default store;
