import makeRequest from 'library/makeRequest';
import {
  setGeneralLoading,
  toggleShowAlert,
} from 'models/actions/generalActions';
import {
  getStaticContent,
  setStaticContent,
  addPage,
  updatePage,
  removePage,
  addSocial,
  updateSocial,
  removeSocial,
  togglePageInMainMenu,
  togglePageInFooter,
  getSocialNetworks,
  setSocialNetworks,
  getBusinessDetails,
  setBusinessDetails,
  saveBusinessDetails,
} from 'models/actions/staticActions';
import { token } from 'models/selectors/userSelectors';
import { combineEpics, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, concatMap } from 'rxjs/operators';

import catchErrorOperator from './operators/catchErrorOperator';

const getStaticContentEpic = (action$) =>
  action$.pipe(
    ofType(getStaticContent.type),
    mergeMap(() =>
      from(makeRequest(`staticcontent`, 'GET', '')).pipe(
        concatMap((payload) => {
          return [setStaticContent(payload?.data), setGeneralLoading(false)];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getBusinessDetailsEpic = (action$) =>
  action$.pipe(
    ofType(getBusinessDetails.type),
    mergeMap(() =>
      from(makeRequest(`staticcontent/businessdetails`, 'GET', '')).pipe(
        concatMap((payload) => {
          return [setBusinessDetails(payload?.data), setGeneralLoading(false)];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getSocialNetworksEpic = (action$) =>
  action$.pipe(
    ofType(getSocialNetworks.type),
    mergeMap(() =>
      from(makeRequest(`staticcontent/socialnetworks`, 'GET', '')).pipe(
        concatMap((payload) => {
          return [setSocialNetworks(payload?.data), setGeneralLoading(false)];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const addPageEpic = (action$, state$) =>
  action$.pipe(
    ofType(addPage.type),
    mergeMap(({ payload: { data: { title, description, isActive } } }) =>
      from(
        makeRequest(
          'staticcontent/admin/add',
          'POST',
          JSON.stringify({ title, description, isActive: Number(isActive) }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            getStaticContent(),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const saveBusinessDetailsEpic = (action$, state$) =>
  action$.pipe(
    ofType(saveBusinessDetails.type),
    mergeMap(({ payload: { data: { hours, phone } } }) =>
      from(
        makeRequest(
          'staticcontent/businessdetails/admin/add',
          'POST',
          JSON.stringify({ hours, phone }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            getBusinessDetails(),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const addSocialEpic = (action$, state$) =>
  action$.pipe(
    ofType(addSocial.type),
    mergeMap(({ payload: { data: { name, link, isActive } } }) =>
      from(
        makeRequest(
          'staticcontent/socialnetworks/admin/add',
          'POST',
          JSON.stringify({ name, link, isActive: Number(isActive) }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            getSocialNetworks(),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const updatePageEpic = (action$, state$) =>
  action$.pipe(
    ofType(updatePage.type),
    mergeMap(({ payload: { data: { id, title, description, isActive } } }) =>
      from(
        makeRequest(
          'staticcontent/admin/update',
          'POST',
          JSON.stringify({
            id,
            title,
            description,
            isActive: Number(isActive),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            getStaticContent(),
            toggleShowAlert({
              message: `${payload.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const updateSocialEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateSocial.type),
    mergeMap(({ payload: { data: { id, name, link, isActive } } }) =>
      from(
        makeRequest(
          'staticcontent/socialnetworks/admin/update',
          'POST',
          JSON.stringify({
            id,
            name,
            link,
            isActive: Number(isActive),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            getSocialNetworks(),
            toggleShowAlert({
              message: `${payload.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const removePageEpic = (action$, state$) =>
  action$.pipe(
    ofType(removePage.type),
    mergeMap(({ payload: { id, isActive } }) =>
      from(
        makeRequest(
          'staticcontent/admin/delete',
          'POST',
          JSON.stringify({
            id,
            isActive: Number(isActive),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            getStaticContent(),
            toggleShowAlert({
              message: `${payload.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const removeSocialEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeSocial.type),
    mergeMap(({ payload: { id, isActive } }) =>
      from(
        makeRequest(
          'staticcontent/socialnetworks/admin/delete',
          'POST',
          JSON.stringify({
            id,
            isActive: Number(isActive),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            getSocialNetworks(),
            toggleShowAlert({
              message: `${payload.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const togglePageInMainMenuEpic = (action$, state$) =>
  action$.pipe(
    ofType(togglePageInMainMenu.type),
    mergeMap(({ payload: { id, isMenu } }) =>
      from(
        makeRequest(
          'staticcontent/admin/toggleinmenu',
          'POST',
          JSON.stringify({
            id,
            isMenu: Number(isMenu),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            getStaticContent(),
            toggleShowAlert({
              message: `${payload.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const togglePageInFooterEpic = (action$, state$) =>
  action$.pipe(
    ofType(togglePageInFooter.type),
    mergeMap(({ payload: { id, isFooter } }) =>
      from(
        makeRequest(
          'staticcontent/admin/toggleinfooter',
          'POST',
          JSON.stringify({
            id,
            isFooter: Number(isFooter),
          }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            getStaticContent(),
            toggleShowAlert({
              message: `${payload.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

export {
  getStaticContentEpic,
  addPageEpic,
  updatePageEpic,
  removePageEpic,
  togglePageInMainMenuEpic,
  togglePageInFooterEpic,
  getSocialNetworksEpic,
  updateSocialEpic,
  removeSocialEpic,
  addSocialEpic,
  getBusinessDetailsEpic,
  saveBusinessDetailsEpic,
};

export default combineEpics(
  getStaticContentEpic,
  addPageEpic,
  updatePageEpic,
  removePageEpic,
  togglePageInMainMenuEpic,
  togglePageInFooterEpic,
  getSocialNetworksEpic,
  updateSocialEpic,
  removeSocialEpic,
  addSocialEpic,
  getBusinessDetailsEpic,
  saveBusinessDetailsEpic,
);
