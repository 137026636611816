import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CommonFilterSelect from 'components/common/CommonFilterSelect';
import dayjs from 'dayjs';
import { setGeneralLoading } from 'models/actions/generalActions';
import { userFiltersSearch } from 'models/actions/userActions';
import { usersSelectedFilters } from 'models/selectors/userSelectors';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const Filters = ({ setToggleValue }) => {
  const selectedFilters = useSelector(usersSelectedFilters);
  const [userFilters, setUsersFilters] = useState({
    email: selectedFilters?.email || '',
    isActive: selectedFilters?.isActive || '',
    birthDate: selectedFilters?.birthDate || null,
  });

  const dispatch = useDispatch();

  const { email, isActive, birthDate } = selectedFilters;

  return (
    <>
      <div className="products-filters">
        {(email !== '' || isActive !== '' || birthDate) && (
          <Tooltip title="Καθαρισμός φίλτρων">
            <button
              className="button lightblue radius"
              onClick={() => {
                dispatch(setGeneralLoading(true));
                dispatch(
                  userFiltersSearch({
                    email: '',
                    isActive: '',
                    birthDate: null,
                  }),
                );
              }}>
              <i
                className="icon-arrows-cw"
                onClick={setToggleValue('right', false)}
              />
            </button>
          </Tooltip>
        )}
        <div className="products-filter">
          <CommonFilterSelect
            label="Ενεργός"
            filters={[
              { id: 1, name: 'ΝΑΙ' },
              { id: 2, name: 'ΟΧΙ' },
            ]}
            type="isActive"
            localFilters={userFilters}
            setFilters={setUsersFilters}
          />
        </div>
        <div className="products-filter">
          <FormControl>
            <InputLabel htmlFor="user-email">Αναζήτηση email</InputLabel>
            <Input
              id="user-email"
              value={userFilters?.email}
              onChange={(e) => {
                setUsersFilters({
                  ...userFilters,
                  email: e.target.value,
                });
              }}
            />
          </FormControl>
        </div>
        <div className="products-filter">
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Birthdate"
                value={dayjs(userFilters?.birthDate) || null}
                onChange={(newValue) => {
                  setUsersFilters({
                    ...userFilters,
                    birthDate: new Date(
                      dayjs(newValue).toDate(),
                    ).toLocaleDateString(),
                  });
                }}
                emptyLabel="custom label"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </div>
      </div>
      <div className="filter-buttons">
        <button className="button delete">
          <span
            onClick={setToggleValue('right', false)}
            className="display-block">
            ΑΚΥΡΩΣΗ
          </span>
        </button>
        <button
          className="button lightblue"
          onClick={() => {
            dispatch(setGeneralLoading(true));
            dispatch(userFiltersSearch(userFilters));
          }}>
          <span
            onClick={setToggleValue('right', false)}
            className="display-block">
            ΑΝΑΖΗΤΗΣΗ
          </span>
        </button>
      </div>
    </>
  );
};

export default Filters;
