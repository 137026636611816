/* eslint-disable react-hooks/exhaustive-deps */
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import { setGeneralLoading } from 'models/actions/generalActions';
import { uploadSingleImage } from 'models/actions/settingsActions';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

const UploadSinglePhoto = () => {
  const dispatch = useDispatch();
  const [myImage, setMyImage] = useState({
    preview: '',
    data: '',
  });

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    setMyImage(img);
  };

  const onFileClick = () => {
    inputFileRef.current.click();
  };

  const inputFileRef = useRef(null);

  return (
    <div className="logo-admin-container">
      <FormControl>
        <Box
          gap={2}
          display="flex"
          justifyContent="flex-start"
          alignItems="center">
          <span onClick={onFileClick}>Επιλέξτε φωτό</span>
          <Input value={myImage?.data?.name || ''} readOnly />
          <input
            type="file"
            accept="images/*"
            ref={inputFileRef}
            onChange={handleFileChange}
            hidden
          />
        </Box>
      </FormControl>
      {myImage?.data?.name && (
        <FormControl>
          <button
            className="button add"
            onClick={() => {
              dispatch(setGeneralLoading(true));
              dispatch(uploadSingleImage({ image: myImage }));
            }}>
            ΑΠΟΘΗΚΕΥΣΗ
          </button>
        </FormControl>
      )}
    </div>
  );
};

export default UploadSinglePhoto;
