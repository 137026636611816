import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const Page = ({ page, togglePage, togglePageWithRemove }) => {
  const { id, name, isActive } = page;

  return (
    <TableRow className="product-row extra">
      <TableCell>{name}</TableCell>
      <TableCell>
        <Tooltip title="Ενημέρωση κοινωνικού δικτύου">
          <i onClick={() => togglePage(id)} className="icon-pencil edit" />
        </Tooltip>
        <Tooltip title="Διαγραφή κοινωνικού δικτύου">
          <i
            onClick={() => togglePageWithRemove(id)}
            className={`${
              isActive
                ? 'icon-toggle-on add-icon'
                : 'icon-toggle-off delete-icon'
            }`}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default Page;
