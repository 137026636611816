import { DialogContentText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import React from 'react';

const CourierDialog = ({
  open,
  courierLink,
  setCourierLink,
  handleClose,
  message,
  sendCourierTrack,
}) => {
  return (
    <Dialog
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}>
      <DialogTitle>Add Courier Link</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className="dialog-content">
            <div className="dialog-message">{message}</div>
            <div className="product-popup-fields">
              <div className="product-popup-field">
                <FormControl>
                  <InputLabel htmlFor="product-popup-text"></InputLabel>
                  <TextField
                    rows={3}
                    id="product-popup-text"
                    value={courierLink}
                    onChange={(e) => setCourierLink(e.target.value)}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button onClick={handleClose} className="button delete">
          ΑΚΥΡΩΣΗ
        </button>
        <button
          disabled={!courierLink}
          onClick={sendCourierTrack}
          className="button add">
          ΑΠΟΣΤΟΛΗ COURIER MAIL
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default CourierDialog;
