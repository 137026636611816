import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  publishProduct,
  setProductIsNew,
} from 'models/actions/productsActions';
import React from 'react';
import { useDispatch } from 'react-redux';

const Product = ({ product, toggleProductOpen }) => {
  const dispatch = useDispatch();
  const {
    productId,
    productTitle,
    initialPrice,
    price,
    stock,
    isActive,
    code,
    isNew,
  } = product;

  return (
    <TableRow className="product-row">
      <TableCell>
        <span className="hidden-column">Τίτλος:&nbsp;</span>
        <Tooltip title={productTitle}>
          <span>{productTitle}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Κωδικός:&nbsp;</span>
        <Tooltip title={code}>
          <span>{code}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Αρχική τιμή:&nbsp;</span>
        <Tooltip title={initialPrice}>
          <span>{initialPrice}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Τελική τιμή:&nbsp;</span>
        <Tooltip title={price}>
          <span>{price}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Απόθεμα:&nbsp;</span>
        <Tooltip title={stock}>
          <span>{stock}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <span className="hidden-column">Καινούριο:&nbsp;</span>
        <Tooltip title={isNew}>
          <span>{isNew ? 'ΝΑΙ' : 'ΟΧΙ'}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <button
          className="button action"
          onClick={() => toggleProductOpen(productId)}>
          <Tooltip title="Ενημέρωσε το προϊόν">
            <i className="icon-pencil" />
          </Tooltip>
        </button>
        <button
          className={`button action ${!isNew ? 'publish' : 'delete'}`}
          onClick={() => {
            dispatch(setGeneralLoading(true));
            dispatch(setProductIsNew({ id: productId, isNew: !isNew }));
          }}>
          <Tooltip
            title={
              !isNew ? 'Κάνε το προϊόν καινούριο' : 'Κάνε το προϊόν παλιό'
            }>
            <i
              className={
                !isNew ? 'icon-flash delete-icon' : 'icon-flash add-icon'
              }
            />
          </Tooltip>
        </button>
        <button
          className={`button action ${!isActive ? 'publish' : 'delete'}`}
          onClick={() => {
            dispatch(setGeneralLoading(true));
            dispatch(publishProduct({ id: productId, active: !isActive }));
          }}>
          <Tooltip
            title={!isActive ? 'Ανέβασε το προϊόν' : 'Κατέβασε το προϊόν'}>
            <i
              className={
                !isActive
                  ? 'icon-toggle-off delete-icon'
                  : 'icon-toggle-on add-icon'
              }
            />
          </Tooltip>
        </button>
      </TableCell>
    </TableRow>
  );
};

export default Product;
