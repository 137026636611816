import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import withToggle from 'components/hocs/withToggle';
import MainMenu from 'components/menu/MainMenu';
import { setGeneralLoading } from 'models/actions/generalActions';
import { logoutUser } from 'models/actions/userActions';
import { user } from 'models/selectors/userSelectors';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

const Header = ({ toggleValue, setToggleValue }) => {
  const adminUser = useSelector(user);
  const dispatch = useDispatch();

  return (
    <>
      <AppBar position="static">
        <Toolbar className="header-toolbar">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className="burger-menu"
            onClick={setToggleValue('left', true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className="header-user-name">
            Καλωσήρθες {adminUser?.userLoggedIn?.username}
          </Typography>
          <div className="logout-btn">
            <button
              className="button lightblue"
              onClick={() => {
                dispatch(setGeneralLoading(true));
                dispatch(logoutUser());
              }}>
              Αποσύνδεση...
            </button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={toggleValue?.left}
        onClose={setToggleValue('left', false)}>
        <MainMenu setToggleValue={setToggleValue} />
      </Drawer>
    </>
  );
};

export default withToggle(Header);
