import formatMoney from 'library/formatMoney';
import { earningsPerMonth } from 'models/selectors/dashboardSelectors';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts';

const months = {
  1: 'Ιαν.',
  2: 'Φεβ.',
  3: 'Μαρ.',
  4: 'Απρ.',
  5: 'Μαι.',
  6: 'Ιουν.',
  7: 'Ιουλ.',
  8: 'Αυγ.',
  9: 'Σεπ.',
  10: 'Οκτ.',
  11: 'Νοε.',
  12: 'Δεκ.',
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${months?.[label]}: ${formatMoney.format(
          payload[0].value,
        )}`}</p>
      </div>
    );
  }

  return null;
};

const DashBoardGraph = () => {
  const earnings = useSelector(earningsPerMonth);

  return (
    <ResponsiveContainer width="99%" aspect={3}>
      <LineChart
        data={earnings}
        margin={{ top: 5, right: 5, left: 35, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          dataKey="month"
          ticks={Object.keys(months)}
          tickFormatter={(value) => months?.[value]}
        />
        <YAxis tickFormatter={(value) => formatMoney.format(value)} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          name="Κέρδη"
          dataKey="netOrders"
          stroke="#f26522"
          strokeWidth={2}
          dot={false}
          activeDot={{ stroke: 'red', strokeWidth: 2, r: 2 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DashBoardGraph;
