/* eslint-disable react-hooks/exhaustive-deps */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { setGeneralLoading } from 'models/actions/generalActions';
import { getSocialNetworks, removeSocial } from 'models/actions/staticActions';
import { socialNetworks } from 'models/selectors/staticSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Page from './Page';
import PagePopup from './PagePopup';

const SocialNetworks = () => {
  const dispatch = useDispatch();
  const allSocialNetworks = useSelector(socialNetworks);
  const [pagePopupOpen, setPagePopupOpen] = useState({
    open: false,
    pageOpenForEdit: '',
  });

  useEffect(() => {
    dispatch(setGeneralLoading(true));
    dispatch(getSocialNetworks());
  }, []);

  const togglePageOpenWithId = (pageId) => {
    setPagePopupOpen({
      ...pagePopupOpen,
      open: true,
      pageOpenForEdit: pageId,
    });
  };

  const togglePageWithRemove = (pageId, isActive) => {
    setPagePopupOpen({
      ...pagePopupOpen,
      open: false,
      pageOpenForEdit: '',
    });
    dispatch(setGeneralLoading(true));
    dispatch(removeSocial({ id: pageId, isActive }));
  };

  return (
    <div className="content general-page">
      {pagePopupOpen?.open && (
        <PagePopup
          open={pagePopupOpen?.open}
          pageId={pagePopupOpen?.pageOpenForEdit}
          handleClose={() =>
            setPagePopupOpen({
              ...pagePopupOpen,
              open: false,
              pageOpenForEdit: '',
            })
          }
        />
      )}
      <div className="row">
        <div className="wrapper">
          <div className="page-title">Κοινωνικά Δίκτυα</div>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <div className="products-crud-actions single">
            <button
              className="button add"
              onClick={() =>
                setPagePopupOpen({
                  ...pagePopupOpen,
                  open: true,
                  pageOpenForEdit: '',
                })
              }>
              ΔΗΜΙΟΥΡΓΙΑ
            </button>
          </div>
        </div>
      </div>
      {allSocialNetworks?.length > 0 ? (
        <>
          <div className="row">
            <div className="wrapper">
              <div className="products">
                <Table className="products-grid">
                  <TableHead>
                    <TableRow>
                      <TableCell>ΤΙΤΛΟΣ</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allSocialNetworks?.map((page) => (
                      <Page
                        togglePage={togglePageOpenWithId}
                        togglePageWithRemove={() =>
                          togglePageWithRemove(page?.id, !page?.isActive)
                        }
                        page={page}
                        key={page?.id}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="not-found">Δεν βρέθηκε κοινωνικά δίκτυα!</div>
      )}
    </div>
  );
};

export default SocialNetworks;
