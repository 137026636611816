/* eslint-disable react-hooks/exhaustive-deps */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  getAllCategories,
  removeCategory,
  setGeneralLoading,
} from 'models/actions/generalActions';
import { allCategories } from 'models/selectors/generalSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Category from './Category';
import CategoryPopup from './CategoryPopup';

const Categories = () => {
  const dispatch = useDispatch();
  const categories = useSelector(allCategories);
  const [categoryPopupOpen, setCategoryPopupOpen] = useState({
    open: false,
    categoryOpenForEdit: '',
  });

  useEffect(() => {
    dispatch(setGeneralLoading(true));
    dispatch(getAllCategories());
  }, []);

  const toggleCategoryOpenWithId = (categoryId) => {
    setCategoryPopupOpen({
      ...categoryPopupOpen,
      open: true,
      categoryOpenForEdit: categoryId,
    });
  };

  const toggleCategoryWithRemove = (categoryId, isActive) => {
    setCategoryPopupOpen({
      ...categoryPopupOpen,
      open: false,
      categoryOpenForEdit: '',
    });
    dispatch(setGeneralLoading(true));
    dispatch(removeCategory({ id: categoryId, isActive }));
  };

  return (
    <div className="content general-page">
      {categoryPopupOpen.open && (
        <CategoryPopup
          open={categoryPopupOpen?.open}
          categoryId={categoryPopupOpen?.categoryOpenForEdit}
          handleClose={() =>
            setCategoryPopupOpen({
              ...categoryPopupOpen,
              open: false,
              categoryOpenForEdit: '',
            })
          }
        />
      )}
      <div className="row">
        <div className="wrapper">
          <div className="page-title">Κατηγορίες</div>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <div className="products-crud-actions double">
            <span>{`(${categories.total} κατηγορίες)`}</span>
            <button
              className="button add"
              onClick={() =>
                setCategoryPopupOpen({
                  ...categoryPopupOpen,
                  open: true,
                  categoryOpenForEdit: '',
                })
              }>
              ΔΗΜΙΟΥΡΓΙΑ
            </button>
          </div>
        </div>
      </div>
      {categories.total > 0 ? (
        <>
          <div className="row">
            <div className="wrapper">
              <div className="products">
                <Table className="products-grid">
                  <TableHead>
                    <TableRow>
                      <TableCell>ΟΝΟΜΑ</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categories?.results?.map((category) => (
                      <Category
                        toggleCategory={toggleCategoryOpenWithId}
                        toggleCategoryWithRemove={() =>
                          toggleCategoryWithRemove(
                            category?.id,
                            !category?.isActive,
                          )
                        }
                        category={category}
                        key={category?.id}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="not-found">Δεν βρέθηκαν κατηγορίες!</div>
      )}
    </div>
  );
};

export default Categories;
