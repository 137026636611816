import { setGeneralLoading } from 'models/actions/generalActions';
import React from 'react';
import { useDispatch } from 'react-redux';

const SelectedFilters = ({ selectedFilters }) => {
  const dispatch = useDispatch();

  return (
    <div className="selected-products-filters-container">
      <div className="selected-products-filters-title">
        <h4>ΕΠΙΛΕΓΜΕΝΑ ΦΙΛΤΡΑ</h4>
      </div>
      <div className="selected-products-filters">
        {selectedFilters?.map((sf, index) => {
          return sf?.condition === '&&'
            ? sf?.value && sf?.value !== '' && (
                <div className="selected-filter" key={`${sf?.type}_${index}`}>
                  <span>
                    <strong>{sf?.label}:</strong>{' '}
                    {sf?.neededArray
                      ? sf?.neededArray?.find((c) => c?.id === sf?.value)?.name
                      : sf?.value.length && sf?.value.indexOf(':') !== -1
                      ? new Date(sf?.value).toLocaleDateString()
                      : sf?.value}
                    <i
                      className="icon-cancel-circled"
                      onClick={() => {
                        dispatch(setGeneralLoading(true));
                        dispatch(sf?.removeFn({ type: sf?.type }));
                      }}
                    />
                  </span>
                </div>
              )
            : sf?.value && sf?.value !== 0 && (
                <div className="selected-filter" key={`${sf?.type}_${index}`}>
                  <span>
                    <strong>{sf?.label}:</strong>{' '}
                    {sf?.value === 1 ? 'ΝΑΙ' : 'ΟΧΙ'}
                    <i
                      className="icon-cancel-circled"
                      onClick={() => {
                        dispatch(setGeneralLoading(true));
                        dispatch(sf?.removeFn({ type: sf?.type }));
                      }}
                    />
                  </span>
                </div>
              );
        })}
      </div>
    </div>
  );
};

export default SelectedFilters;
