import { loading } from 'models/selectors/generalSelectors';
import React from 'react';
import { useSelector } from 'react-redux';

const Loader = () => {
  const show = useSelector(loading);

  return (
    show && (
      <div className="loading full">
        <div className="loading-icon">
          <div className="actual-loading-icon"></div>
        </div>
      </div>
    )
  );
};

export default Loader;
