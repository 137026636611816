import MenuIcon from '@mui/icons-material/Menu';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const Page = ({
  page,
  togglePage,
  togglePageWithRemove,
  toggleStaticPageMenu,
  toggleStaticPageFooter,
}) => {
  const { id, title, isActive, isMenu, isFooter } = page;

  return (
    <TableRow className="product-row extra">
      <TableCell>{title}</TableCell>
      <TableCell>
        <Tooltip title="Ενημέρωση στατικού περιεχομένου">
          <i onClick={() => togglePage(id)} className="icon-pencil edit" />
        </Tooltip>
        <Tooltip title="Διαγραφή στατικού περιεχομένου">
          <i
            onClick={() => togglePageWithRemove(id)}
            className={`${
              isActive
                ? 'icon-toggle-on add-icon'
                : 'icon-toggle-off delete-icon'
            }`}
          />
        </Tooltip>
        <Tooltip title={isMenu ? 'Διαγραφή από το menu' : 'Εισαγωγή στο menu'}>
          <MenuIcon
            className={`mui-icon ${
              isMenu ? 'icon-toggle-on add-icon' : 'icon-toggle-off delete-icon'
            }`}
            onClick={() => toggleStaticPageMenu(id)}
          />
        </Tooltip>
        <Tooltip
          title={isFooter ? 'Διαγραφή από το footer' : 'Εισαγωγή στο footer'}>
          <MenuIcon
            className={`mui-icon ${
              isFooter
                ? 'icon-toggle-on add-icon'
                : 'icon-toggle-off delete-icon'
            }`}
            onClick={() => toggleStaticPageFooter(id)}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default Page;
