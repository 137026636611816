/* eslint-disable react-hooks/exhaustive-deps */
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  setHomeBannersLoading,
  getAllHomeBanners,
  removeHomeBanners,
} from 'models/actions/settingsActions';
import { homeBannersSettings } from 'models/selectors/settingsSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HomeBanner from './HomeBanner';
import HomeBannerPopup from './HomeBannerPopup';

const HomeBanners = () => {
  const dispatch = useDispatch();
  const homeBanners = useSelector(homeBannersSettings)?.banners;
  const loading = useSelector(homeBannersSettings)?.loading;
  const [homeBannersPopupOpen, setHomeBannersPopupOpen] = useState({
    open: false,
    homeBannersOpenForEdit: '',
  });

  useEffect(() => {
    dispatch(setHomeBannersLoading(true));
    dispatch(getAllHomeBanners());
  }, []);

  const toggleHBOpenWithId = (hbId) => {
    setHomeBannersPopupOpen({
      ...homeBannersPopupOpen,
      open: true,
      homeBannersOpenForEdit: hbId,
    });
  };

  const toggleHBWithRemove = (hbId, isActive) => {
    setHomeBannersPopupOpen({
      ...homeBannersPopupOpen,
      open: false,
      homeBannersOpenForEdit: '',
    });
    dispatch(setGeneralLoading(true));
    dispatch(removeHomeBanners({ id: hbId, isActive: Number(isActive) }));
  };

  return (
    <div>
      {homeBannersPopupOpen?.open && (
        <HomeBannerPopup
          open={homeBannersPopupOpen?.open}
          homeBanner={homeBanners?.find(
            (hb) => hb?.id === homeBannersPopupOpen?.homeBannersOpenForEdit,
          )}
          hbId={homeBannersPopupOpen?.homeBannersOpenForEdit}
          handleClose={() =>
            setHomeBannersPopupOpen({
              ...homeBannersPopupOpen,
              open: false,
              homeBannersOpenForEdit: '',
            })
          }
        />
      )}
      {!loading ? (
        <>
          <div className="products-crud-actions double auto">
            <span>{`(${homeBanners?.length || 0} κεντρικά slides)`}</span>
            <button
              className="button add"
              onClick={() =>
                setHomeBannersPopupOpen({
                  ...homeBannersPopupOpen,
                  open: true,
                  homeBannersOpenForEdit: '',
                })
              }>
              ΔΗΜΙΟΥΡΓΙΑ
            </button>
          </div>
          {homeBanners?.length > 0 ? (
            <Table className="products-grid">
              <TableHead>
                <TableRow>
                  <TableCell>ΤΙΤΛΟΣ</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {homeBanners?.map((hb) => (
                  <HomeBanner
                    key={hb.id}
                    hb={hb}
                    toggleHB={toggleHBOpenWithId}
                    toggleHBWithRemove={() =>
                      toggleHBWithRemove(hb?.id, !hb?.isActive)
                    }
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="not-found">Δεν βρέθηκαν κεντρικά slides!</div>
          )}
        </>
      ) : (
        <>
          <Skeleton width="60%" />
          <Skeleton width="60%" />
          <Skeleton variant="rectangular" height={250} />
        </>
      )}
    </div>
  );
};

export default HomeBanners;
