import PageNotFound from 'components/404/PageNotFound';
import Alert from 'components/alert/Alert';
import Brands from 'components/brands/Brands';
import BusinessDetails from 'components/business-details/BusinessDetails';
import Categories from 'components/categories/Categories';
import Dashboard from 'components/dashboard/Dashboard';
import Header from 'components/header/Header';
import Loader from 'components/loader/Loader';
import Login from 'components/login/Login';
import Orders from 'components/orders/Orders';
import Products from 'components/products/Products';
import ProtectedRoute from 'components/protected-route/ProtectedRoute';
import Settings from 'components/settings/Settings';
import SocialNetworks from 'components/social-networks/SocialNetworks';
import StaticPages from 'components/static-pages/StaticPages';
import Subcategories from 'components/subcategories/Subcategories';
import Users from 'components/users/Users';
import { token } from 'models/selectors/userSelectors';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'fontello/css/fontello.css';

const App = () => {
  const userToken = useSelector(token);

  return (
    <HelmetProvider>
      <Router>
        {userToken && <Header />}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute isAllowed={userToken}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/orders"
            element={
              <ProtectedRoute isAllowed={userToken}>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/products"
            element={
              <ProtectedRoute isAllowed={userToken}>
                <Products />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/categories"
            element={
              <ProtectedRoute isAllowed={userToken}>
                <Categories />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/users"
            element={
              <ProtectedRoute isAllowed={userToken}>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/subcategories"
            element={
              <ProtectedRoute isAllowed={userToken}>
                <Subcategories />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/brands"
            element={
              <ProtectedRoute isAllowed={userToken}>
                <Brands />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/static-pages"
            element={
              <ProtectedRoute isAllowed={userToken}>
                <StaticPages />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/social-networks"
            element={
              <ProtectedRoute isAllowed={userToken}>
                <SocialNetworks />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/business-details"
            element={
              <ProtectedRoute isAllowed={userToken}>
                <BusinessDetails />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <ProtectedRoute isAllowed={userToken}>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Loader />
        <Alert />
      </Router>
    </HelmetProvider>
  );
};

export default App;
