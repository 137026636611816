import { createReducer } from '@reduxjs/toolkit';
import {
  setLoggedInUser,
  setAllUsers,
  userFiltersSearch,
  setCurrentPagesPage,
  removeSelectedFilter,
} from 'models/actions/userActions';

const initialState = {
  user: {},
  users: {
    pagination: {
      total: 0,
      currentPage: 1,
      perPage: process.env.REACT_APP_PER_PAGE,
    },
    results: [],
  },
  usersSelectedFilters: {
    email: '',
    isActive: '', // '' equals all users
    birthDate: null,
  },
};
const userReducer = createReducer(initialState, {
  [setLoggedInUser.type]: (state, action) => ({
    ...state,
    user: action.payload,
  }),
  [setAllUsers.type]: (state, action) => ({
    ...state,
    users: {
      ...state.users,
      results: action.payload.results,
      pagination: {
        ...state.users.pagination,
        total: action.payload.total,
      },
    },
  }),
  [userFiltersSearch.type]: (state, action) => ({
    ...state,
    usersSelectedFilters: {
      ...action.payload,
    },
    users: {
      ...state.users,
      pagination: {
        ...state.users.pagination,
        currentPage: 1,
        total: 0,
      },
    },
  }),
  [removeSelectedFilter.type]: (state, action) => ({
    ...state,
    usersSelectedFilters: {
      ...state?.usersSelectedFilters,
      [action.payload.type]: action.payload.type !== 'birthDate' ? '' : null,
    },
    users: {
      ...state.users,
      pagination: {
        ...state.users.pagination,
        currentPage: 1,
        total: 0,
      },
    },
  }),
  [setCurrentPagesPage.type]: (state, action) => ({
    ...state,
    users: {
      ...state.users,
      pagination: {
        ...state.users.pagination,
        currentPage: action.payload,
      },
    },
  }),
});

export default userReducer;
