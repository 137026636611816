import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const User = ({ user, toggleUser, toggleUserWithRemove }) => {
  const { id, email, isActive, isAdmin } = user;

  return (
    <TableRow className="product-row extra">
      <TableCell>
        <span className="hidden-column">Email:&nbsp;</span>
        {email}
      </TableCell>
      <TableCell>
        <span className="hidden-column">Ενεργοποιημένος:&nbsp;</span>
        {isActive ? 'ΝΑΙ' : 'ΟΧΙ'}
      </TableCell>
      <TableCell>
        <span className="hidden-column">Διαχειριστής:&nbsp;</span>
        {isAdmin ? 'ΝΑΙ' : '-'}
      </TableCell>

      <TableCell>
        <Tooltip title="Ενημέρωση χρήστη">
          <i onClick={() => toggleUser(id)} className="icon-pencil edit" />
        </Tooltip>
        <Tooltip title="Διαγραφή χρήστη">
          <i
            onClick={() => toggleUserWithRemove(id, isActive ? '0' : '1')}
            className={`${
              isActive
                ? 'icon-toggle-on add-icon'
                : 'icon-toggle-off delete-icon'
            }`}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default User;
