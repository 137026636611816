/* eslint-disable max-len */
import { createReducer } from '@reduxjs/toolkit';
import {
  //   setProductPage,
  setOrders,
  removeSelectedOrderFilter,
  setOrderStatuses,
  setOrdersCurrentPage,
  ordersFiltersSearch,
  setOrderPopup,
  setOrderProductSearchTerm,
  setProductToAdd,
  setOrderProducts,
  setNewProductTotal,
  setOrdersSorting,
  setProductNewQuantityInOrder,
  setDOYS,
} from 'models/actions/ordersActions';
import { resetState } from 'models/actions/userActions';

const initialState = {
  doys: [],
  sorting: 2, // date asc, then date desc equals 2
  singleOrder: {
    orderCode: '',
    dateCreated: '',
    statusId: '',
    statusName: '',
    paymentMethodCost: 0,
    shippingMethodCost: 0,
    piAfm: '',
    piEponymia: '',
    piDoy: null,
    piFirstName: '',
    piLastName: '',
    piAddress: '',
    piPhone: '',
    piPostCode: '',
    siFirstName: '',
    siLastName: '',
    siAddress: '',
    siPhone: '',
    siPostCode: '',
    products: [],
    pastStatuses: [],
  },
  selectedProductToAdd: {},
  productsToAddToOrder: [],
  orderProductSearchTerm: '',
  selectedProductToAddTotal: 0,
  filterStatuses: [],
  filters: {
    selectedStatus: '',
    selectedName: '',
    selectedEmail: '',
    selectedDate: '',
    selectedOrderCode: '',
    selectedPaymentMethod: '',
    selectedShippingMethod: '',
  },
  orders: {
    pagination: {
      total: 0,
      currentPage: 1,
      perPage: process.env.REACT_APP_PER_PAGE,
    },
    results: [],
  },
};

const ordersReducer = createReducer(initialState, {
  [setOrdersSorting.type]: (state, action) => ({
    ...state,
    sorting: action.payload,
    orders: {
      ...state.orders,
      pagination: {
        ...state.orders.pagination,
        currentPage: 1,
        total: 0,
      },
    },
  }),
  [setProductNewQuantityInOrder.type]: (state, action) => ({
    ...state,
    singleOrder: { ...state.singleOrder, products: action.payload.products },
  }),
  [setDOYS.type]: (state, action) => ({
    ...state,
    doys: action.payload,
  }),
  [setNewProductTotal.type]: (state, action) => ({
    ...state,
    selectedProductToAddTotal: action.payload,
  }),
  [setOrderProducts.type]: (state, action) => ({
    ...state,
    productsToAddToOrder: action.payload.result,
  }),
  [setProductToAdd.type]: (state, action) => ({
    ...state,
    selectedProductToAdd: action.payload,
  }),
  [setOrderProductSearchTerm.type]: (state, action) => ({
    ...state,
    orderProductSearchTerm: action.payload,
  }),
  [setOrderPopup.type]: (state, action) => ({
    ...state,
    singleOrder: action.payload,
  }),
  [setOrderStatuses.type]: (state, action) => ({
    ...state,
    filterStatuses: action.payload,
  }),
  [ordersFiltersSearch.type]: (state, action) => ({
    ...state,
    filters: {
      ...action.payload,
    },
    orders: {
      ...state.orders,
      pagination: {
        ...state.orders.pagination,
        currentPage: 1,
        total: 0,
      },
    },
  }),
  [setOrders.type]: (state, action) => ({
    ...state,
    orders: {
      ...state.orders,
      results: action.payload.results,
      pagination: {
        ...state.orders.pagination,
        total: action.payload.total,
      },
    },
  }),
  [removeSelectedOrderFilter.type]: (state, action) => ({
    ...state,
    filters: {
      ...state?.filters,
      [action.payload.type]: '',
    },
    orders: {
      ...state.orders,
      pagination: {
        ...state.orders.pagination,
        currentPage: 1,
        total: 0,
      },
    },
  }),
  [setOrdersCurrentPage.type]: (state, action) => ({
    ...state,
    orders: {
      ...state.orders,
      pagination: {
        ...state.orders.pagination,
        currentPage: action.payload,
      },
    },
  }),
  [resetState.type]: (state, action) => ({ ...initialState }),
});

export default ordersReducer;
