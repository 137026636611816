import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';

const CommonFilterSelect = ({
  filters,
  label,
  type,
  localFilters,
  setFilters,
}) => (
  <FormControl variant="standard">
    <InputLabel id={`filter${type}Label`}>{label}</InputLabel>
    <Select
      labelId={`filter${type}Label`}
      autoWidth
      id={`filter${type}`}
      value={localFilters?.[type]}
      className="selectdropdown"
      onChange={(e) =>
        setFilters({
          ...localFilters,
          [type]: e.target.value,
        })
      }>
      {filters?.map((filter) => (
        <MenuItem key={filter?.id} value={filter?.id}>
          {filter?.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default CommonFilterSelect;
