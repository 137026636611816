import { createReducer } from '@reduxjs/toolkit';
import {
  setOfferTitleLoading,
  setOfferTitle,
  // LOGO
  setLogoImage,
  // COUNTRIES
  setCountries,
  // PREFECTURES
  setPrefectures,
  // PAYMENTS
  setPaymentMethodsLoading,
  setAllPaymentMethods,
  // SHIPPING
  setShippingMethodsLoading,
  setAllShippingMethods,
  setSingleShippingMethod,
  // HOME TABS
  setHomeTabsLoading,
  setAllHomeTabs,
  setHomeTabsSearchedProducts,
  // HOME SECTIONS
  setHomeSectionsLoading,
  setAllHomeSections,
  setHomeSectionsSearchedProducts,
  // HOME BANNERS
  setHomeBannersLoading,
  setAllHomeBanners,
  setHomeBannersSearchedProducts,
  setHomeBannerImage,
  // HOME LAST BANNERS
  setHomeLastBannersLoading,
  setAllHomeLastBanners,
  setHomeLastBannerImage,
  // KEYWORDS
  setKeyWordsLoading,
  setAllKeyWords,
  // COUPONS
  setCouponsLoading,
  setAllCoupons,
  // NEWSLETTERCOUPON
  setNewsletterCoupon,
  setNewsletterCouponLoading,
} from 'models/actions/settingsActions';
import { resetState } from 'models/actions/userActions';

const initialState = {
  singleImage: {},
  settings: {
    newsletterCoupon: {
      isActive: false,
      loading: false,
    },
    coupons: { results: [], loading: false },
    offerTitle: {
      titles: [],
      loading: false,
    },
    logo: { preview: '', data: '' },
    countries: [],
    prefectures: [],
    payment: {
      methods: [],
      loading: false,
    },
    shipping: {
      methods: [],
      loading: false,
      singleMethod: {},
    },
    homeTabs: {
      tabs: [],
      loading: false,
      searchedProducts: [],
    },
    homeSections: {
      sections: [],
      loading: false,
      searchedProducts: [],
    },
    homeBanners: {
      banners: [],
      loading: false,
      searchedProducts: [],
    },
    homeLastBanners: {
      banners: [],
      loading: false,
    },
    homeBannerImage: { preview: '', data: '' },
    homeLastBannerImage: { preview: '', data: '' },
    keyWords: {
      loading: false,
      words: [],
    },
  },
};
const settingsReducer = createReducer(initialState, {
  // LOGO
  [setLogoImage.type]: (state, action) => ({
    ...state,
    settings: {
      ...state?.settings,
      logo: action.payload,
    },
  }),
  // COUNTRIES
  [setCountries.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      countries: action.payload,
    },
  }),
  // PREFECTURES
  [setPrefectures.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      prefectures: action.payload,
    },
  }),
  // PAYMENTS
  [setPaymentMethodsLoading.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      payment: {
        ...state.settings.payment,
        loading: action.payload,
      },
    },
  }),
  [setAllPaymentMethods.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      payment: {
        ...state.settings.payment,
        methods: action.payload,
      },
    },
  }),

  // SHIPPING
  [setSingleShippingMethod.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      shipping: {
        ...state.settings.shipping,
        singleMethod: action.payload,
      },
    },
  }),
  [setShippingMethodsLoading.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      shipping: {
        ...state.settings.shipping,
        loading: action.payload,
      },
    },
  }),
  [setAllShippingMethods.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      shipping: {
        ...state.settings.shipping,
        methods: action.payload,
      },
    },
  }),

  // HOME TABS
  [setHomeTabsLoading.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      homeTabs: {
        ...state.settings.homeTabs,
        loading: action.payload,
      },
    },
  }),
  [setAllHomeTabs.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      homeTabs: {
        ...state.settings.homeTabs,
        tabs: action.payload,
      },
    },
  }),
  [setHomeTabsSearchedProducts.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      homeTabs: {
        ...state.settings.homeTabs,
        searchedProducts: action.payload,
      },
    },
  }),

  // HOME SECTIONS
  [setHomeSectionsLoading.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      homeSections: {
        ...state.settings.homeSections,
        loading: action.payload,
      },
    },
  }),
  [setAllHomeSections.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      homeSections: {
        ...state.settings.homeSections,
        sections: action.payload,
      },
    },
  }),
  [setHomeSectionsSearchedProducts.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      homeSections: {
        ...state.settings.homeSections,
        searchedProducts: action.payload,
      },
    },
  }),
  // HOME LAST BANNERS
  [setHomeLastBannersLoading.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      homeLastBanners: {
        ...state.settings.homeLastBanners,
        loading: action.payload,
      },
    },
  }),
  [setAllHomeLastBanners.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      homeLastBanners: {
        ...state.settings.homeLastBanners,
        banners: action.payload,
      },
    },
  }),
  [setHomeLastBannerImage.type]: (state, action) => ({
    ...state,
    homeLastBannerImage: action.payload,
  }),
  // HOME BANNERS
  [setHomeBannersLoading.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      homeBanners: {
        ...state.settings.homeBanners,
        loading: action.payload,
      },
    },
  }),
  [setAllHomeBanners.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      homeBanners: {
        ...state.settings.homeBanners,
        banners: action.payload,
      },
    },
  }),
  [setHomeBannersSearchedProducts.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      homeBanners: {
        ...state.settings.homeBanners,
        searchedProducts: action.payload,
      },
    },
  }),
  [setHomeBannerImage.type]: (state, action) => ({
    ...state,
    homeBannerImage: action.payload,
  }),
  // OFFER TITLE
  [setOfferTitleLoading.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      offerTitle: { ...state.settings.offerTitle, loading: action.payload },
    },
  }),
  [setOfferTitle.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      offerTitle: { ...state.settings.offerTitle, titles: action.payload },
    },
  }),
  // KEYWORDS
  [setKeyWordsLoading.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      keyWords: { ...state.settings.keyWords, loading: action.payload },
    },
  }),
  [setAllKeyWords.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      keyWords: { ...state.settings.keyWords, words: action.payload },
    },
  }),
  // COUPONS
  [setCouponsLoading.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      coupons: { ...state.settings.coupons, loading: action.payload },
    },
  }),
  [setAllCoupons.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      coupons: { ...state.settings.coupons, coupons: action.payload },
    },
  }),
  // NEWSLETTERCOUPON
  [setNewsletterCouponLoading.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      newsletterCoupon: {
        ...state.settings.newsletterCoupon,
        loading: action.payload,
      },
    },
  }),
  [setNewsletterCoupon.type]: (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      newsletterCoupon: {
        ...state.settings.newsletterCoupon,
        isActive: action.payload,
      },
    },
  }),
  [resetState.type]: (state, action) => ({ ...initialState }),
});

export default settingsReducer;
