/* eslint-disable react-hooks/exhaustive-deps */
import Grid from '@mui/material/Grid';
import HomeSectionsSettings from 'components/home/HomeSectionsSettings';
import HomeTabsSettings from 'components/home/HomeTabsSettings';
import { setGeneralLoading } from 'models/actions/generalActions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Coupons from './Coupons';
import HomeBanners from './HomeBanners';
import HomeLastBanners from './HomeLastBanners';
import KeyWords from './KeyWords';
import LogoSettings from './LogoSettings';
import NewsletterCoupon from './NewsletterCoupon';
import OffersTitle from './OffersTitle';
import PaymentMethods from './PaymentMethods';
import ShippingMethods from './ShippingMethods';
import UploadSinglePhoto from './UploadSinglePhoto';

const Settings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGeneralLoading(false));
  }, []);

  return (
    <div className="content settings-page">
      <div className="row">
        <div className="wrapper">
          <div className="page-title">Ρυθμίσεις</div>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <Grid container columnSpacing={2}>
            <Grid item sm={5} xs={12}>
              <div className="settings-container-box">
                <div className="section-title">Λογότυπο</div>
                <LogoSettings />
              </div>
            </Grid>
            <Grid item sm={5} xs={12}>
              <div className="settings-container-box">
                <div className="section-title">Upload photos</div>
                <UploadSinglePhoto />
              </div>
            </Grid>
            <Grid item sm={2} xs={12}>
              <div className="settings-container-box">
                <div className="section-title">Newsletter Coupon</div>
                <NewsletterCoupon />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <Grid container>
            <Grid item sm={12}>
              <div className="settings-container-box">
                <div className="section-title">Offers title</div>
                <OffersTitle />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <Grid container>
            <Grid item sm={12}>
              <div className="settings-container-box">
                <div className="section-title">Κουπόνια</div>
                <Coupons />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <Grid container>
            <Grid item sm={12}>
              <div className="settings-container-box">
                <div className="section-title">Λέξεις κλειδιά</div>
                <KeyWords />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <Grid container>
            <Grid item sm={12}>
              <div className="settings-container-box">
                <div className="section-title">Κεντρικό Slide</div>
                <HomeBanners />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={12}>
              <div className="settings-container-box">
                <div className="section-title">Τρόποι Πληρωμής</div>
                <PaymentMethods />
              </div>
            </Grid>
            <Grid item md={6} sm={12}>
              <div className="settings-container-box">
                <div className="section-title">Τρόποι Αποστολής</div>
                <ShippingMethods />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={12}>
              <div className="settings-container-box">
                <div className="section-title">Καρτέλες αρχικής σελίδας</div>
                <HomeTabsSettings />
              </div>
            </Grid>
            <Grid item md={6} sm={12}>
              <div className="settings-container-box">
                <div className="section-title">Περιοχές αρχικής σελίδας</div>
                <HomeSectionsSettings />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={12}>
              <div className="settings-container-box">
                <div className="section-title">Τελευταίο banner @ homepage</div>
                <HomeLastBanners />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Settings;
