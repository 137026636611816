/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import { setGeneralLoading } from 'models/actions/generalActions';
import { updateUser } from 'models/actions/userActions';
import { users } from 'models/selectors/userSelectors';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const UserPopup = ({ open, userId, handleClose, isAdministrator }) => {
  const dispatch = useDispatch();
  const allUsers = useSelector(users)?.results;
  const selectedUser = allUsers?.find((user) => user?.id === userId);
  const [userPopup, setUserPopup] = useState({ ...selectedUser, password: '' });
  const [error, setError] = useState('');

  useEffect(() => {
    dispatch(setGeneralLoading(false));

    return () => {
      dispatch(setGeneralLoading(false));
      setUserPopup({});
    };
  }, [userId]);

  const handleUserUpdate = () => {
    if (Boolean(userPopup && userPopup?.password === '')) {
      setError('Error');
    } else {
      setError('');
    }

    if ((userPopup && userPopup?.password !== '') || !isAdministrator) {
      dispatch(setGeneralLoading(true));
      if (userId !== '') {
        userPopup && userPopup?.password !== ''
          ? dispatch(
              updateUser({
                id: userId,
                password: userPopup.password,
                email: userPopup.email,
                isAdministrator,
              }),
            )
          : dispatch(
              updateUser({
                id: userId,
                isAdmin: Number(userPopup.isAdmin),
                email: userPopup.email,
                isAdministrator,
              }),
            );
        handleClose();
      }
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>Χρήστης: {userPopup?.email}</span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field more-margin-bottom">
              {isAdministrator ? (
                <FormControl>
                  <InputLabel htmlFor="password">Νέο password</InputLabel>
                  <Input
                    id="password"
                    type="password"
                    value={userPopup?.password ? userPopup?.password : ''}
                    onChange={(e) =>
                      setUserPopup({
                        ...userPopup,
                        password: e.target.value,
                      })
                    }
                  />
                  {error !== '' && (
                    <span className="error-span">Πρέπει να βάλετε κωδικό.</span>
                  )}
                </FormControl>
              ) : (
                <FormControl>
                  <FormControlLabel
                    className="user-isActive"
                    control={
                      <Switch
                        checked={Boolean(userPopup?.isAdmin) || false}
                        onChange={(e) => {
                          setUserPopup({
                            ...userPopup,
                            isAdmin: !userPopup?.isAdmin,
                          });
                        }}
                        name="checkedA"
                      />
                    }
                    label="Διαχειριστής"
                  />
                </FormControl>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handleUserUpdate} className="button add">
            ΕΝΗΜΕΡΩΣΗ
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserPopup;
