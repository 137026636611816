import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const HomeSection = ({ hs, toggleHS, toggleHSWithRemove }) => {
  const { id, title, isActive, products } = hs;

  return (
    <TableRow className="product-row extra">
      <TableCell>
        <Tooltip title={title}>
          <span>{title}</span>
        </Tooltip>
      </TableCell>
      <TableCell>{products.length}</TableCell>
      <TableCell>
        <Tooltip title="Ενημέρωση περιοχής">
          <i onClick={() => toggleHS(id)} className="icon-pencil edit" />
        </Tooltip>
        <Tooltip title="Διαγραφή περιοχής">
          <i
            onClick={() => toggleHSWithRemove(id)}
            className={`${
              isActive
                ? 'icon-toggle-on add-icon'
                : 'icon-toggle-off delete-icon'
            }`}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default HomeSection;
