import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const Brand = ({ brand, toggleBrand, toggleBrandWithRemove }) => {
  const { id, name, isActive } = brand;

  return (
    <TableRow className="product-row extra">
      <TableCell>{name}</TableCell>

      <TableCell>
        <Tooltip title="Ενημέρωση brand">
          <i onClick={() => toggleBrand(id)} className="icon-pencil edit" />
        </Tooltip>
        <Tooltip title="Διαγραφή brand">
          <i
            onClick={() => toggleBrandWithRemove(id)}
            className={`${
              isActive
                ? 'icon-toggle-on add-icon'
                : 'icon-toggle-off delete-icon'
            }`}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default Brand;
