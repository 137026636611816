import Input from '@mui/material/Input';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import formatMoney from 'library/formatMoney';
import { setGeneralLoading } from 'models/actions/generalActions';
import { removeProductFromOrder } from 'models/actions/ordersActions';
import { changeProductQuantity } from 'models/actions/productsActions';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

const OrderProduct = ({
  product: {
    productId,
    code,
    productTitle,
    imgHref,
    initialPrice,
    price,
    items,
  },
  statusId,
  orderId,
}) => {
  const dispatch = useDispatch();

  const totalsRef = useRef(null);

  return (
    <TableRow className="order-product-row">
      <TableCell>
        <img
          className="order-img"
          src={`${process.env.REACT_APP_IMAGE_URL}/${imgHref}`}
          alt={productTitle}
        />
        <Tooltip title={productTitle}>
          <span>{productTitle}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={code}>
          <span>{code}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={formatMoney.format(initialPrice)}>
          <span>{formatMoney.format(initialPrice)}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={formatMoney.format(price)}>
          <span>{formatMoney.format(price)}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={items}>
          {statusId !== 1 ? (
            <span>{items}</span>
          ) : (
            <Input
              id="product-popup-code"
              inputRef={totalsRef}
              className="filled"
              // error={errorCode}
              value={items}
              onClick={() => totalsRef.current.select()}
              onChange={(e) => {
                dispatch(setGeneralLoading(true));
                dispatch(
                  changeProductQuantity({
                    id: productId,
                    total: e.target.value,
                    orderId,
                  }),
                );
              }}
            />
          )}
        </Tooltip>
      </TableCell>
      {statusId === 1 && (
        <TableCell>
          <Tooltip title="Διαγράψτε το προϊόν.">
            <i
              className="icon-cancel-circled"
              onClick={() => {
                dispatch(setGeneralLoading(true));
                dispatch(removeProductFromOrder({ orderId, productId, items }));
              }}
            />
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

export default OrderProduct;
