import { createAction } from '@reduxjs/toolkit';

const setGeneralLoading = createAction('general/setGeneralLoading');
const toggleShowAlert = createAction('general/toggleShowAlert');

// CATEGORIES
const getAllCategories = createAction('general/getAllCategories');
const setGeneralCategories = createAction('general/setGeneralCategories');
const getCategoryDetails = createAction('general/getCategoryDetails');
const setSubCategoriesForCategoryId = createAction(
  'general/setSubCategoriesForCategoryId',
);
const setSubCatSearchTerm = createAction('general/setSubCatSearchTerm');
const getSubCategories = createAction('general/getSubCategories');
const setAvailableSubCategories = createAction(
  'general/setAvailableSubCategories',
);
const updateCategory = createAction('general/updateCategory');
const addCategory = createAction('general/addCategory');
const removeCategory = createAction('general/removeCategory');

// SUBCATEGORIES
const getAllSubCategories = createAction('general/getAllSubCategories');
const setGeneralSubCategories = createAction('general/setGeneralSubCategories');
const getSubCategoryDetails = createAction('general/getSubCategoryDetails');
const updateSubCategory = createAction('general/updateSubCategory');
const addSubCategory = createAction('general/addSubCategory');
const removeSubCategory = createAction('general/removeSubCategory');

// BRANDS
const getAllBrands = createAction('general/getAllBrands');
const setGeneralBrands = createAction('general/setGeneralBrands');
const getBrandDetails = createAction('general/getBrandDetails');
const updateBrand = createAction('general/updateBrand');
const addBrand = createAction('general/addBrand');
const removeBrand = createAction('general/removeBrand');

export {
  setGeneralLoading,
  toggleShowAlert,
  // CATEGORIES
  getAllCategories,
  setGeneralCategories,
  getCategoryDetails,
  setSubCategoriesForCategoryId,
  setSubCatSearchTerm,
  getSubCategories,
  setAvailableSubCategories,
  updateCategory,
  addCategory,
  removeCategory,
  // SUBCATEGORIES
  getAllSubCategories,
  setGeneralSubCategories,
  getSubCategoryDetails,
  updateSubCategory,
  addSubCategory,
  removeSubCategory,
  // BRANDS
  getAllBrands,
  setGeneralBrands,
  getBrandDetails,
  updateBrand,
  addBrand,
  removeBrand,
};
