import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import CommonFilterSelect from 'components/common/CommonFilterSelect';
import { setGeneralLoading } from 'models/actions/generalActions';
import { productsFiltersSearch } from 'models/actions/productsActions';
import {
  productsSelectedFilters,
  filterPricesRange,
  filterSubCategories,
  filterCategories,
  filterBrands,
} from 'models/selectors/productsSelectors';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const valuetext = (value) => `${value}€`;

const Filters = ({ setToggleValue }) => {
  const selectedFilters = useSelector(productsSelectedFilters);
  const categories = useSelector(filterCategories);
  const subCategories = useSelector(filterSubCategories);
  const brands = useSelector(filterBrands);
  const [productFilters, setProductFilters] = useState({
    selectedCategory: selectedFilters?.selectedCategory || '',
    selectedSubCategory: selectedFilters?.selectedSubCategory || '',
    selectedBrand: selectedFilters?.selectedBrand || '',
    selectedPriceRange: selectedFilters?.selectedPriceRange || [],
    selectedCode: selectedFilters?.selectedCode || '',
    selectedIsActive: selectedFilters?.selectedIsActive || '',
    selectedIsNew: selectedFilters?.selectedIsNew || '',
  });
  const pricesRange = useSelector(filterPricesRange);
  const { minprice, maxprice } = pricesRange;
  const dispatch = useDispatch();

  const {
    selectedCategory,
    selectedSubCategory,
    selectedBrand,
    selectedPriceRange,
    selectedCode,
    selectedIsActive,
    selectedIsNew,
  } = selectedFilters;

  return (
    <>
      <div className="products-filters">
        {(selectedCode !== '' ||
          selectedCategory ||
          selectedSubCategory ||
          selectedIsNew ||
          selectedBrand ||
          (selectedIsActive && selectedIsActive !== 0) ||
          selectedPriceRange?.length > 0) && (
          <Tooltip title="Καθαρισμός φίλτρων">
            <button
              className="button lightblue radius"
              onClick={() => {
                dispatch(setGeneralLoading(true));
                dispatch(
                  productsFiltersSearch({
                    selectedCategory: '',
                    selectedSubCategory: '',
                    selectedBrand: '',
                    selectedPriceRange: [],
                    selectedCode: '',
                    selectedIsActive: '',
                    selectedIsNew: '',
                  }),
                );
              }}>
              <i
                className="icon-arrows-cw"
                onClick={setToggleValue('right', false)}
              />
            </button>
          </Tooltip>
        )}
        <div className="products-filter">
          <CommonFilterSelect
            label="Κατηγορίες"
            filters={categories}
            type="selectedCategory"
            localFilters={productFilters}
            setFilters={setProductFilters}
          />
        </div>
        <div className="products-filter">
          <CommonFilterSelect
            label="Υποκατηγορίες"
            filters={subCategories}
            type="selectedSubCategory"
            localFilters={productFilters}
            setFilters={setProductFilters}
          />
        </div>
        <div className="products-filter">
          <CommonFilterSelect
            label="Brands"
            filters={brands}
            type="selectedBrand"
            localFilters={productFilters}
            setFilters={setProductFilters}
          />
        </div>
        <div className="products-filter">
          <CommonFilterSelect
            label="Ενεργό"
            filters={[
              { id: 1, name: 'ΝΑΙ' },
              { id: 2, name: 'ΟΧΙ' },
            ]}
            type="selectedIsActive"
            localFilters={productFilters}
            setFilters={setProductFilters}
          />
        </div>
        <div className="products-filter">
          <CommonFilterSelect
            label="Καινούριο"
            filters={[
              { id: 1, name: 'ΝΑΙ' },
              { id: 2, name: 'ΟΧΙ' },
            ]}
            type="selectedIsNew"
            localFilters={productFilters}
            setFilters={setProductFilters}
          />
        </div>
        <div className="products-filter">
          <FormControl>
            <InputLabel htmlFor="filterProductsByCode">
              Αναζήτηση κωδικού
            </InputLabel>
            <Input
              id="sortingSelect"
              value={productFilters?.selectedCode}
              onChange={(e) => {
                setProductFilters({
                  ...productFilters,
                  selectedCode: e.target.value,
                });
              }}
            />
          </FormControl>
        </div>
        <div className="products-filter">
          <FormControl>
            <Slider
              getAriaLabel={() => 'ευρώ'}
              value={[
                productFilters?.selectedPriceRange[0] || minprice,
                productFilters?.selectedPriceRange[1] || maxprice,
              ]}
              min={minprice}
              max={maxprice}
              onChange={(event, newValue) => {
                setProductFilters({
                  ...productFilters,
                  selectedPriceRange: newValue,
                });
              }}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
              marks={[
                {
                  value: minprice,
                  label: `${minprice}€`,
                },
                {
                  value: maxprice,
                  label: `${maxprice}€`,
                },
              ]}
            />
          </FormControl>
        </div>
      </div>
      <div className="filter-buttons">
        <button className="button delete">
          <span
            onClick={setToggleValue('right', false)}
            className="display-block">
            ΑΚΥΡΩΣΗ
          </span>
        </button>
        <button
          className="button lightblue"
          onClick={() => {
            dispatch(setGeneralLoading(true));
            dispatch(productsFiltersSearch(productFilters));
          }}>
          <span
            onClick={setToggleValue('right', false)}
            className="display-block">
            ΑΝΑΖΗΤΗΣΗ
          </span>
        </button>
      </div>
    </>
  );
};

export default Filters;
