/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  updateHomeTab,
  addHomeTab,
  getHomeTabRelatedProducts,
} from 'models/actions/settingsActions';
import { homeTabsSettings } from 'models/selectors/settingsSelectors';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const HomeTabsPopup = ({ open, htId, handleClose }) => {
  const dispatch = useDispatch();
  const homeTabs = useSelector(homeTabsSettings)?.tabs;
  const homeTabsSearchedProducts = useSelector(homeTabsSettings)
    ?.searchedProducts;
  const [prodSearchTerm, setProdSearchTerm] = useState('');
  const [homeTabsPopup, setHomeTabsPopup] = useState({
    title: '',
    products: [],
    isActive: 0,
  });
  const [error, setError] = useState('');

  useEffect(() => {
    setHomeTabsPopup(homeTabs?.find((ht) => ht?.id === htId) || homeTabsPopup);

    return () => {
      setHomeTabsPopup({
        id: '',
        title: '',
        products: [],
        isActive: 0,
      });
      dispatch(setGeneralLoading(false));
    };
  }, [htId]);

  const handlePaymentMethodUpdate = () => {
    if (homeTabsPopup && homeTabsPopup?.title === '') {
      setError('Error');

      return false;
    }

    setError('');
    dispatch(setGeneralLoading(true));
    if (htId !== '') {
      dispatch(updateHomeTab(homeTabsPopup));
    } else {
      dispatch(addHomeTab(homeTabsPopup));
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>
            {htId === '' ? 'ΔΗΜΙΟΥΡΓΙΑ ΚΑΡΤΕΛΑΣ' : homeTabsPopup?.title}
          </span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="home-tab-title">Τίτλος</InputLabel>
                <Input
                  id="home-tab-title"
                  error={error !== ''}
                  value={homeTabsPopup?.title ? homeTabsPopup?.title : ''}
                  onChange={(e) =>
                    setHomeTabsPopup({
                      ...homeTabsPopup,
                      title: e.target.value,
                    })
                  }
                />
                {error !== '' && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε τίτλο.
                  </span>
                )}
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <Autocomplete
                  multiple
                  className={
                    homeTabsPopup?.products?.length > 0 ? 'filled' : ''
                  }
                  clearOnBlur={false}
                  id="product-popup-homeTabProducts"
                  options={homeTabsSearchedProducts || []}
                  getOptionLabel={(option) => option['productTitle']}
                  value={homeTabsPopup?.products}
                  inputValue={prodSearchTerm}
                  noOptionsText="Δεν βρέθηκαν επιλογές"
                  onChange={(e, product) => {
                    setHomeTabsPopup({
                      ...homeTabsPopup,
                      products: [...product],
                    });
                  }}
                  onInputChange={(e, value) => {
                    setProdSearchTerm(value);
                    dispatch(getHomeTabRelatedProducts(value));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Σχετικά προϊόντα"
                      placeholder="Επιλογή"
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handlePaymentMethodUpdate} className="button add">
            {htId !== '' ? 'ΕΝΗΜΕΡΩΣΗ' : 'ΔΗΜΙΟΥΡΓΙΑ'}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HomeTabsPopup;
