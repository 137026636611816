/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  saveProductInDB,
  updateProductInDB,
  getProduct,
  setProductPopup,
  setProductPopupDetailsValue,
  setSelectedProductsExtra,
  getProductsPopupCategories,
  setProductsPopupCategories,
  getProductsPopupSubCategories,
  setProductsPopupSubCategories,
  getProductPopupBrands,
  setProductsPopupBrands,
  setProductImage,
  updateProductImage,
  removeProductImage,
} from 'models/actions/productsActions';
import {
  productPopupDetails,
  productPopupDetailsExtra,
  productImage,
} from 'models/selectors/productsSelectors';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RichTextEditor from 'react-rte';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ProductPopUp = ({ open, productOpenForEdit, handleClose, onChange }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState({ preview: '', data: '' });
  const productDetails = useSelector(productPopupDetails);
  const prodImage = useSelector(productImage);
  const productExtra = useSelector(productPopupDetailsExtra);
  const initialPriceRef = useRef();
  const priceRef = useRef();
  const stockRef = useRef();

  // RICH TEXT EDITOR
  const [valueLargeDescription, setValueLargeDescription] = useState(
    RichTextEditor.createEmptyValue(),
  );
  // const [value, setValueLargeDescription] = useState(
  //   RichTextEditor.createValueFromString('<p>Vaios</p>', 'html'),
  // );
  const handleOnChangeLargeDescription = (value) => {
    setValueLargeDescription(value);
    if (onChange) {
      onChange(value.toString('html'));
    }
  };

  useEffect(() => {
    if (productDetails?.product?.productLargeDescription !== '') {
      setValueLargeDescription(
        RichTextEditor.createValueFromString(
          productDetails?.product?.productLargeDescription || '<p></p>',
          'html',
        ),
      );
    }
  }, [productDetails?.product?.productLargeDescription]);

  // ERRORS VALIDATIONS
  const [errorCode, setErrorCode] = useState(false);
  const [errorProductTitle, setErrorProductTitle] = useState(false);
  const [errorProductDescription, setErrorProductDescription] = useState(false);
  const [errorInitialPrice, setErrorInitialPrice] = useState(false);
  const [errorPrice, setErrorPrice] = useState(false);
  //
  const [catSearchTerm, setCatSearchTerm] = useState('');
  const [subCatSearchTerm, setSubCatSearchTerm] = useState('');
  const [brandSearchTerm, setBrandSearchTerm] = useState('');

  const handleProductAddition = () => {
    if (
      !productDetails ||
      (productDetails && !productDetails?.product) ||
      (productDetails &&
        productDetails?.product &&
        !productDetails?.product?.code) ||
      (productDetails &&
        productDetails?.product &&
        productDetails?.product?.code === '')
    ) {
      setErrorCode(true);
    } else {
      setErrorCode(false);
    }

    if (
      !productDetails ||
      (productDetails && !productDetails?.product) ||
      (productDetails &&
        productDetails?.product &&
        !productDetails?.product?.productTitle) ||
      (productDetails &&
        productDetails?.product &&
        productDetails?.product?.productTitle === '')
    ) {
      setErrorProductTitle(true);
    } else {
      setErrorProductTitle(false);
    }

    if (
      !productDetails ||
      (productDetails && !productDetails?.product) ||
      (productDetails &&
        productDetails?.product &&
        !productDetails?.product?.productDescription) ||
      (productDetails &&
        productDetails?.product &&
        productDetails?.product?.productDescription === '')
    ) {
      setErrorProductDescription(true);
    } else {
      setErrorProductDescription(false);
    }

    if (
      !productDetails ||
      (productDetails && !productDetails?.product) ||
      (productDetails &&
        productDetails?.product &&
        productDetails?.product?.initialPrice < 0) ||
      (productDetails &&
        productDetails?.product &&
        productDetails?.product?.initialPrice < productDetails?.product?.price)
    ) {
      setErrorInitialPrice(true);
    } else {
      setErrorInitialPrice(false);
    }

    if (
      !productDetails ||
      (productDetails && !productDetails?.product) ||
      (productDetails &&
        productDetails?.product &&
        !productDetails?.product?.price) ||
      (productDetails &&
        productDetails?.product &&
        productDetails?.product?.price <= 0) ||
      (productDetails &&
        productDetails?.product &&
        productDetails?.product?.initialPrice < productDetails?.product?.price)
    ) {
      setErrorPrice(true);
    } else {
      setErrorPrice(false);
    }

    if (
      productDetails &&
      productDetails?.product &&
      productDetails?.product?.code &&
      productDetails?.code !== '' &&
      productDetails?.product?.productTitle &&
      productDetails?.product?.productTitle !== '' &&
      productDetails?.product?.productDescription &&
      productDetails?.product?.productDescription !== '' &&
      productDetails?.product?.initialPrice !== '' &&
      productDetails?.product?.price &&
      productDetails?.product?.price > 0 &&
      productDetails?.product?.price <= productDetails?.product?.initialPrice
    ) {
      dispatch(setGeneralLoading(true));
      if (productOpenForEdit === '') {
        dispatch(
          saveProductInDB({
            productDetails,
            largeDescription: valueLargeDescription.toString('html'),
            image: prodImage,
          }),
        );
      } else {
        dispatch(
          updateProductInDB({
            productDetails: {
              ...productDetails,
              product: {
                ...productDetails?.product,
                imgHref: file?.data?.name || file?.name || '',
              },
            },
            largeDescription: valueLargeDescription.toString('html'),
            image: file?.data?.name || file?.name || '',
          }),
        );
      }
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    dispatch(updateProductImage(e.target.files[0]?.name));
  };

  const uploadFile = () => {
    dispatch(setGeneralLoading(true));
    const formData = new FormData();

    formData.append('file', file);
    axios
      .post(`${process.env.REACT_APP_API}/products/admin/image`, formData)
      .then((res) => {
        dispatch(setGeneralLoading(false));
      })
      .catch((er) => console.log(er));
  };

  useEffect(() => {
    dispatch(setProductPopup({}));
    dispatch(setProductsPopupCategories([]));
    dispatch(setProductsPopupSubCategories([]));
    dispatch(setProductsPopupBrands([]));
    dispatch(setProductImage(''));

    if (productOpenForEdit !== '') {
      dispatch(setGeneralLoading(true));
      dispatch(getProduct(productOpenForEdit));
    }

    return () => {
      dispatch(setGeneralLoading(false));
      dispatch(setProductPopup({}));
      dispatch(setProductsPopupCategories([]));
      dispatch(setProductsPopupSubCategories([]));
      dispatch(setProductsPopupBrands([]));
      dispatch(setProductImage(''));
    };
  }, []);

  const onFileClick = () => {
    inputFileRef.current.click();
  };

  const inputFileRef = useRef(null);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>
            {productOpenForEdit === ''
              ? 'ΔΗΜΙΟΥΡΓΙΑ ΠΡΟΪΟΝΤΟΣ'
              : 'ΕΝΗΜΕΡΩΣΗ ΠΡΟΪΟΝΤΟΣ'}
          </span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <form>
            <div className="product-popup-fields">
              <div className="product-popup-field">
                <FormControl>
                  <Box
                    gap={2}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center">
                    <span onClick={onFileClick}>Επιλέξτε φωτό</span>
                    <Input value={file?.name || ''} readOnly />
                    <input
                      type="file"
                      accept="images/*"
                      ref={inputFileRef}
                      onChange={handleFileChange}
                      hidden
                    />

                    <button type="button" onClick={uploadFile}>
                      Upload
                    </button>
                  </Box>
                </FormControl>
              </div>
              <div className="product-popup-field">
                <div className="product-related-images">
                  {productDetails?.product?.imgHref?.length > 1 &&
                    productDetails?.product?.imgHref
                      .substr(
                        0,
                        productDetails?.product?.imgHref.lastIndexOf('#') !== -1
                          ? productDetails?.product?.imgHref.lastIndexOf('#')
                          : productDetails?.product?.imgHref.length,
                      )
                      ?.split('#')
                      ?.map((imag, index) => (
                        <div key={`${imag}_${index}`}>
                          <div>
                            <img
                              className="img-preview"
                              src={`${process.env.REACT_APP_IMAGE_URL}/${imag}`}
                              alt=""
                            />
                          </div>

                          <Tooltip title="Διαγραφή εικόνας">
                            <div>
                              <i
                                onClick={() => {
                                  inputFileRef.current.value = '';
                                  dispatch(
                                    removeProductImage({
                                      id: productDetails.product.productId,
                                      image: imag,
                                    }),
                                  );
                                }}
                                className="delete-product-image icon-cancel-circled"
                              />
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                </div>
              </div>
              <div className="product-popup-field">
                <FormControl>
                  <InputLabel htmlFor="product-popup-code">Κωδικός</InputLabel>
                  <Input
                    id="product-popup-code"
                    className={productDetails?.product?.code ? 'filled' : ''}
                    error={errorCode}
                    value={productDetails?.product?.code || ''}
                    onChange={(e) => {
                      dispatch(
                        setProductPopupDetailsValue({
                          field: 'code',
                          value: e.target.value,
                        }),
                      );
                    }}
                  />
                  {errorCode && (
                    <span className="error-span">
                      Πρέπει να συμπληρώσετε κωδικό.
                    </span>
                  )}
                </FormControl>
              </div>

              <div className="product-popup-field">
                <FormControl>
                  <InputLabel htmlFor="product-popup-title">
                    Τίτλος προϊόντος
                  </InputLabel>
                  <Input
                    className={
                      productDetails?.product?.productTitle ? 'filled' : ''
                    }
                    id="product-popup-title"
                    error={errorProductTitle}
                    value={productDetails?.product?.productTitle || ''}
                    onChange={(e) => {
                      dispatch(
                        setProductPopupDetailsValue({
                          field: 'productTitle',
                          value: e.target.value,
                        }),
                      );
                    }}
                  />
                  {errorProductTitle && (
                    <span className="error-span">
                      Πρέπει να συμπληρώσετε τίτλο.
                    </span>
                  )}
                </FormControl>
              </div>
              <div className="product-popup-field">
                <FormControl>
                  <InputLabel htmlFor="product-popup-subheader">
                    Υπότιτλος
                  </InputLabel>
                  <Input
                    id="product-popup-subheader"
                    className={
                      productDetails?.product?.productSubHeader ? 'filled' : ''
                    }
                    value={productDetails?.product?.productSubHeader || ''}
                    onChange={(e) => {
                      dispatch(
                        setProductPopupDetailsValue({
                          field: 'productSubHeader',
                          value: e.target.value,
                        }),
                      );
                    }}
                  />
                </FormControl>
              </div>
              <div className="product-popup-field more-margin-bottom">
                <FormControl>
                  <InputLabel htmlFor="product-popup-description">
                    Μικρή περιγραφή προϊόντος
                  </InputLabel>
                  <Input
                    id="product-popup-description"
                    className={
                      productDetails?.product?.productDescription
                        ? 'filled'
                        : ''
                    }
                    error={errorProductDescription}
                    value={productDetails?.product?.productDescription || ''}
                    minRows={4}
                    multiline={true}
                    maxRows={4}
                    onChange={(e) => {
                      dispatch(
                        setProductPopupDetailsValue({
                          field: 'productDescription',
                          value: e.target.value,
                        }),
                      );
                    }}
                  />
                  {errorProductDescription && (
                    <span className="error-span">
                      Πρέπει να συμπληρώσετε μικρή περιγραφή.
                    </span>
                  )}
                </FormControl>
              </div>
              <div className="product-popup-field">
                <FormControl>
                  <InputLabel
                    className="rich-label differentLabel large-description"
                    htmlFor="product-popup-largeDescription">
                    Μεγάλη περιγραφή προϊόντος
                  </InputLabel>
                  <RichTextEditor
                    value={valueLargeDescription}
                    onChange={handleOnChangeLargeDescription}
                  />
                </FormControl>
              </div>
              <div className="product-popup-field isRow">
                <FormControl>
                  <InputLabel
                    htmlFor="product-popup-initialPrice"
                    className="differentLabel">
                    Αρχική τιμή
                  </InputLabel>
                  <Input
                    id="product-popup-initialPrice"
                    inputRef={initialPriceRef}
                    error={errorInitialPrice}
                    value={productDetails?.product?.initialPrice || 0}
                    inputProps={{ min: 0 }}
                    onClick={() => initialPriceRef.current.select()}
                    onChange={(e) => {
                      dispatch(
                        setProductPopupDetailsValue({
                          field: 'initialPrice',
                          value: e.target.value,
                        }),
                      );
                    }}
                  />
                  {errorInitialPrice && (
                    <span className="error-span">
                      Η αρχική τιμή πρέπει να είναι μεγαλύτερη του 0.
                    </span>
                  )}
                </FormControl>
                <FormControl>
                  <InputLabel
                    htmlFor="product-popup-price"
                    className="differentLabel">
                    Τελική τιμή
                  </InputLabel>
                  <Input
                    id="product-popup-price"
                    inputRef={priceRef}
                    error={errorPrice}
                    value={productDetails?.product?.price || 0}
                    inputProps={{ min: 0 }}
                    onClick={() => priceRef.current.select()}
                    onChange={(e) => {
                      dispatch(
                        setProductPopupDetailsValue({
                          field: 'price',
                          value: e.target.value,
                        }),
                      );
                      dispatch(
                        setProductPopupDetailsValue({
                          field: 'initialPrice',
                          value: e.target.value,
                        }),
                      );
                    }}
                  />
                  {errorPrice && (
                    <span className="error-span">
                      Η τελική τιμή πρέπει να είναι μεγαλύτερη του 0.
                    </span>
                  )}
                </FormControl>
                <FormControl>
                  <InputLabel
                    htmlFor="product-popup-stock"
                    className="differentLabel">
                    Απόθεμα
                  </InputLabel>
                  <Input
                    id="product-popup-stock"
                    inputRef={stockRef}
                    value={productDetails?.product?.stock || 0}
                    type="number"
                    inputProps={{ min: 0 }}
                    onClick={() => stockRef.current.select()}
                    onChange={(e) => {
                      dispatch(
                        setProductPopupDetailsValue({
                          field: 'stock',
                          value: e.target.value,
                        }),
                      );
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    className="product-popup-isActive"
                    control={
                      <Switch
                        checked={
                          Boolean(productDetails?.product?.isActive) || false
                        }
                        onChange={(e) => {
                          dispatch(
                            setProductPopupDetailsValue({
                              field: 'isActive',
                              value: !productDetails?.product?.isActive,
                            }),
                          );
                        }}
                        name="checkedA"
                      />
                    }
                    label="Ενεργό"
                  />
                </FormControl>
              </div>
              <div className="product-popup-field">
                <FormControl>
                  <InputLabel
                    htmlFor="product-popup-relatedProducts"
                    className="differentLabel">
                    Σχετικά προϊόντα
                  </InputLabel>
                  <Input
                    id="product-popup-relatedProducts"
                    value={productDetails?.product?.related_products || ''}
                    type="text"
                    onChange={(e) => {
                      dispatch(
                        setProductPopupDetailsValue({
                          field: 'related_products',
                          value: e.target.value,
                        }),
                      );
                    }}
                  />
                </FormControl>
              </div>
              <div className="product-popup-field">
                <FormControl>
                  <InputLabel
                    htmlFor="product-popup-colorOptions"
                    className="differentLabel">
                    Color Options
                  </InputLabel>
                  <Input
                    id="product-popup-colorOptions"
                    value={productDetails?.product?.colorOptions || ''}
                    type="text"
                    onChange={(e) => {
                      dispatch(
                        setProductPopupDetailsValue({
                          field: 'colorOptions',
                          value: e.target.value,
                        }),
                      );
                    }}
                  />
                </FormControl>
              </div>
              <div className="product-popup-field">
                <FormControl>
                  <Autocomplete
                    multiple
                    clearOnBlur={false}
                    className={
                      productDetails?.products_categories?.length > 0
                        ? 'filled'
                        : ''
                    }
                    data-testid="product-popup-categories"
                    id="product-popup-categories"
                    options={productExtra?.categories || []}
                    getOptionLabel={(option) => `${option['name']}`}
                    value={productDetails?.products_categories || []}
                    inputValue={catSearchTerm}
                    noOptionsText="Δεν βρέθηκαν επιλογές"
                    onChange={(e, category) => {
                      dispatch(
                        setSelectedProductsExtra({
                          field: 'products_categories',
                          value: [...category],
                        }),
                      );
                    }}
                    onInputChange={(e, value) => {
                      setCatSearchTerm(value);
                      dispatch(getProductsPopupCategories(value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Κατηγορίες"
                        placeholder="Επιλογή"
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="product-popup-field">
                <FormControl>
                  <Autocomplete
                    multiple
                    clearOnBlur={false}
                    className={
                      productDetails?.products_subcategories?.length > 0
                        ? 'filled'
                        : ''
                    }
                    id="product-popup-subcategories"
                    options={productExtra?.subCategories || []}
                    getOptionLabel={(option) => `${option['name']}`}
                    value={productDetails?.products_subcategories || []}
                    inputValue={subCatSearchTerm}
                    noOptionsText="Δεν βρέθηκαν επιλογές"
                    onChange={(e, subcategory) => {
                      dispatch(
                        setSelectedProductsExtra({
                          field: 'products_subcategories',
                          value: [...subcategory],
                        }),
                      );
                    }}
                    onInputChange={(e, value) => {
                      setSubCatSearchTerm(value);
                      dispatch(getProductsPopupSubCategories(value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Υποκατηγορίες"
                        placeholder="Επιλογή"
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="product-popup-field">
                <FormControl>
                  <Autocomplete
                    multiple
                    clearOnBlur={false}
                    className={
                      productDetails?.products_brands?.length > 0
                        ? 'filled'
                        : ''
                    }
                    id="product-popup-brands"
                    options={productExtra?.brands || []}
                    getOptionLabel={(option) => `${option['name']}`}
                    value={productDetails?.products_brands || []}
                    inputValue={brandSearchTerm}
                    noOptionsText="Δεν βρέθηκαν επιλογές"
                    onChange={(e, brand) => {
                      dispatch(
                        setSelectedProductsExtra({
                          field: 'products_brands',
                          value: [...brand],
                        }),
                      );
                    }}
                    onInputChange={(e, value) => {
                      setBrandSearchTerm(value);
                      dispatch(getProductPopupBrands(value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Brands"
                        placeholder="Επιλογή"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΑΚΥΡΩΣΗ
          </button>
          <button onClick={handleProductAddition} className="button add">
            {productOpenForEdit === '' ? 'ΑΠΟΘΗΚΕΥΣΗ' : 'ΕΝΗΜΕΡΩΣΗ'}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductPopUp;
