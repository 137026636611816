/* eslint-disable react-hooks/exhaustive-deps */
import Drawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import withToggle from 'components/hocs/withToggle';
import RoutePagination from 'components/pagination/RoutePagination';
import SelectedFiltersComp from 'components/selected-filters/SelectedFilters';
import Sorting from 'components/sorting/Sorting';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  getInitialOrdersSeries,
  setOrdersCurrentPage,
  removeSelectedOrderFilter,
  reSendOrderMail,
  downloadOrdersCsv,
  setOrdersSorting,
  getDOYS,
  sendCourierTrackEmail,
} from 'models/actions/ordersActions';
import {
  adminOrders,
  ordersPagination,
  selectedOrdersFilters,
  ordersStatuses,
  ordersSorting,
} from 'models/selectors/ordersSelectors';
import {
  paymentSettings,
  shippingSettings,
} from 'models/selectors/settingsSelectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Order from './Order';
import OrderPopup from './OrderPopup';
import OrdersFilters from './OrdersFilters';

const Orders = ({ toggleValue, setToggleValue }) => {
  const pagination = useSelector(ordersPagination);
  const sorting = useSelector(ordersSorting);
  const [orderPopupOpen, setOrderPopupOpen] = useState('');
  const orders = useSelector(adminOrders);
  const filterStatuses = useSelector(ordersStatuses);
  const paymentMethods = useSelector(paymentSettings).methods;
  const shippingMethods = useSelector(shippingSettings).methods;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGeneralLoading(true));
    dispatch(getDOYS());
    dispatch(getInitialOrdersSeries());
  }, []);

  const toggleOrderOpenWithId = (orderId) => {
    setOrderPopupOpen(orderId);
  };

  const sendOrderMail = (orderId) => {
    dispatch(setGeneralLoading(true));
    dispatch(reSendOrderMail(orderId));
  };

  const sendCourierTrack = (order, courierLink, message) => {
    dispatch(setGeneralLoading(true));
    dispatch(sendCourierTrackEmail({ order, courierLink, message }));
  };

  const selectedFilters = useSelector(selectedOrdersFilters);
  const {
    selectedStatus,
    selectedName,
    selectedEmail,
    selectedDate,
    selectedOrderCode,
    selectedPaymentMethod,
    selectedShippingMethod,
  } = selectedFilters;

  return (
    <div className="content orders-page">
      <div className="row">
        <div className="wrapper">
          <div className="page-title">
            Παραγγελίες
            <Tooltip title="Κατεβάστε σε αρχείο">
              <i
                onClick={() => {
                  dispatch(setGeneralLoading(true));
                  dispatch(downloadOrdersCsv());
                }}
                className="icon-file-excel pointer"
              />
            </Tooltip>
          </div>
        </div>
      </div>
      {orderPopupOpen !== '' && (
        <OrderPopup
          orderId={orderPopupOpen}
          handleClose={() => setOrderPopupOpen('')}
        />
      )}
      {(selectedStatus !== '' ||
        selectedPaymentMethod ||
        selectedShippingMethod ||
        selectedName !== '' ||
        selectedEmail !== '' ||
        selectedDate !== '' ||
        selectedOrderCode !== '') && (
        <div className="row">
          <div className="wrapper">
            <SelectedFiltersComp
              selectedFilters={[
                {
                  label: 'Κωδικός',
                  value: selectedOrderCode,
                  condition: '&&',
                  removeFn: removeSelectedOrderFilter,
                  type: 'selectedOrderCode',
                },
                {
                  label: 'Κατάσταση',
                  value: selectedStatus,
                  condition: '&&',
                  removeFn: removeSelectedOrderFilter,
                  type: 'selectedStatus',
                  neededArray: filterStatuses,
                },
                {
                  label: 'Τρόπος πληρωμής',
                  value: selectedPaymentMethod,
                  condition: '&&',
                  removeFn: removeSelectedOrderFilter,
                  type: 'selectedPaymentMethod',
                  neededArray: paymentMethods,
                },
                {
                  label: 'Τρόπος αποστολής',
                  value: selectedShippingMethod,
                  condition: '&&',
                  removeFn: removeSelectedOrderFilter,
                  type: 'selectedShippingMethod',
                  neededArray: shippingMethods,
                },
                {
                  label: 'Όνομα',
                  value: selectedName,
                  condition: '&&',
                  removeFn: removeSelectedOrderFilter,
                  type: 'selectedName',
                },
                {
                  label: 'Email',
                  value: selectedEmail,
                  condition: '&&',
                  removeFn: removeSelectedOrderFilter,
                  type: 'selectedEmail',
                },
                {
                  label: 'Ημερομηνία',
                  value:
                    selectedDate !== ''
                      ? new Date(selectedDate).toLocaleDateString()
                      : '',
                  condition: '&&',
                  removeFn: removeSelectedOrderFilter,
                  type: 'selectedDate',
                },
              ]}
            />
          </div>
        </div>
      )}
      <Drawer
        anchor="right"
        open={toggleValue?.right}
        className="filters-drawer"
        onClose={setToggleValue('right', false)}>
        <div className="products-filters-section">
          <OrdersFilters setToggleValue={setToggleValue} />
        </div>
      </Drawer>
      {pagination.total > process.env.REACT_APP_PER_PAGE && (
        <div className="row">
          <div className="wrapper">
            <div className="sorting-section for-products">
              <>
                <RoutePagination
                  setCurrentPage={setOrdersCurrentPage}
                  pagination={pagination}
                />
                <Sorting
                  sorting={sorting}
                  sortingAction={setOrdersSorting}
                  options={[
                    { value: 1, name: 'Ανα ημερομηνία (αύξουσα)' },
                    { value: 2, name: 'Ανα ημερομηνία (φθίνουσα)' },
                  ]}
                />
              </>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="wrapper">
          <div className="products-crud-actions double">
            <span>{`(${pagination.total} παραγγελίες)`}</span>
            <button
              className="button lightblue"
              onClick={setToggleValue('right', true)}>
              ΦΙΛΤΡΑ
            </button>
          </div>
          {orders?.length > 0 ? (
            <div className="products">
              <Table className="products-grid responsive-table">
                <TableHead>
                  <TableRow>
                    <TableCell>ΚΩΔΙΚΟΣ</TableCell>
                    <TableCell>ΗΜΕΡΟΜΗΝΙΑ</TableCell>
                    <TableCell>ΟΝΟΜΑ</TableCell>
                    <TableCell>EMAIL</TableCell>
                    <TableCell>ΠΛΗΡΩΜΗ</TableCell>
                    <TableCell>ΑΠΟΣΤΟΛΗ</TableCell>
                    <TableCell>ΤΙΜΗ</TableCell>
                    <TableCell>ΤΕΜΑΧΙΑ</TableCell>
                    <TableCell>ΚΑΤΑΣΤΑΣΗ</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders?.map((order) => (
                    <Order
                      order={order}
                      key={order?.id}
                      toggleOrderPopup={toggleOrderOpenWithId}
                      sendOrderMail={sendOrderMail}
                      sendCourierTrack={sendCourierTrack}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div className="not-found">
              <h2>Δεν βρέθηκαν παραγγελίες με αυτά τα κριτήρια.</h2>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <div className="sorting-section for-products">
            {orders?.length > 0 && (
              <>
                <RoutePagination
                  setCurrentPage={setOrdersCurrentPage}
                  pagination={pagination}
                />
                <Sorting
                  sorting={sorting}
                  sortingAction={setOrdersSorting}
                  options={[
                    { value: 1, name: 'Ανα ημερομηνία (αύξουσα)' },
                    { value: 2, name: 'Ανα ημερομηνία (φθίνουσα)' },
                  ]}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withToggle(Orders);
