import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  getBusinessDetails,
  saveBusinessDetails,
} from 'models/actions/staticActions';
import { businessDetails } from 'models/selectors/staticSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const BusinessDetails = () => {
  const [pagePopupData, setPagePopupData] = useState({});
  const dispatch = useDispatch();
  const myDetails = useSelector(businessDetails);

  useEffect(() => {
    dispatch(getBusinessDetails());
  }, []);

  useEffect(() => {
    setPagePopupData(myDetails);
  }, [myDetails]);

  const handleSaveBusinessDetails = () => {
    dispatch(setGeneralLoading(true));
    dispatch(saveBusinessDetails({ data: pagePopupData }));
  };

  return (
    <div className="content general-page">
      <div className="row">
        <div className="wrapper">
          <div className="page-title">Business Details</div>
        </div>
      </div>
      <div className="row">
        <div className="wrapper small">
          <div className="product-popup-fields">
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="working-hours">Working Hours</InputLabel>
                <Input
                  id="working-hours"
                  value={pagePopupData?.hours ? pagePopupData?.hours : ''}
                  onChange={(e) =>
                    setPagePopupData({
                      ...pagePopupData,
                      hours: e.target.value,
                    })
                  }
                />
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="phone">Phone</InputLabel>
                <Input
                  id="phone"
                  value={pagePopupData?.phone ? pagePopupData?.phone : ''}
                  onChange={(e) =>
                    setPagePopupData({
                      ...pagePopupData,
                      phone: e.target.value,
                    })
                  }
                />
              </FormControl>
            </div>
            <div className="product-popup-field">
              <button
                disabled={!pagePopupData?.hours || !pagePopupData?.phone}
                onClick={handleSaveBusinessDetails}
                className="button add">
                ΑΠΟΘΗΚΕΥΣΗ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetails;
