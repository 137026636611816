const config = {
  menu: [
    { path: '/', text: 'Αρχική', icon: 'icon-home' },
    { path: '/orders', text: 'Παραγγελίες', icon: 'icon-basket' },
    { path: '/products', text: 'Προϊόντα', icon: 'icon-sitemap' },
    { path: '/categories', text: 'Κατηγορίες', icon: 'icon-tag' },
    { path: '/subcategories', text: 'Υποκατηγορίες', icon: 'icon-tags' },
    { path: '/brands', text: 'Brands', icon: 'icon-tags-1' },
    { path: '/settings', text: 'Ρυθμίσεις', icon: 'icon-cog' },
    {
      path: '/users',
      text: 'Χρήστες',
      icon: 'icon-users',
    },
    {
      path: '/static-pages',
      text: 'Στατικό περιεχόμενο',
      icon: 'icon-file-code',
    },
    {
      path: '/social-networks',
      text: 'Κοινωνικά Δίκτυα',
      icon: 'icon-facebook',
    },
    {
      path: '/business-details',
      text: 'Business Details',
      icon: 'icon-pencil',
    },
  ],
};

export default config;
