import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { setGeneralLoading } from 'models/actions/generalActions';
import React from 'react';
import { useDispatch } from 'react-redux';

const Sorting = ({ sorting, sortingAction, options }) => {
  const dispatch = useDispatch();

  return (
    <div className="sorting-container">
      <FormControl variant="standard">
        <InputLabel id="sortingCatalogProducts">Ταξινόμηση</InputLabel>
        <Select
          labelId="sortingCatalogProducts"
          autoWidth
          id="sortingSelect"
          value={sorting}
          label="Ταξινόμηση"
          onChange={(e) => {
            dispatch(setGeneralLoading(true));
            dispatch(sortingAction(e.target.value));
          }}>
          {options?.map((option) => (
            <MenuItem key={option?.value} value={option?.value}>
              {option?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Sorting;
