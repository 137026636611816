import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const ShippingMethod = ({ sm, toggleSM, toggleSMWithRemove }) => {
  const { id, name, isActive } = sm;

  return (
    <TableRow className="product-row extra">
      <TableCell>
        <Tooltip title={name}>
          <span>{name}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Ενημέρωση τρόπου αποστολής">
          <i onClick={() => toggleSM(id)} className="icon-pencil edit" />
        </Tooltip>
        <Tooltip title="Διαγραφή τρόπου αποστολής">
          <i
            onClick={() => toggleSMWithRemove(id)}
            className={`${
              isActive
                ? 'icon-toggle-on add-icon'
                : 'icon-toggle-off delete-icon'
            }`}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default ShippingMethod;
