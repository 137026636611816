import makeRequest from 'library/makeRequest';
import {
  getDashBoard,
  setDashBoard,
  setDashboardYear,
} from 'models/actions/dashboardActions';
import { setGeneralLoading } from 'models/actions/generalActions';
import { setCountries, setPrefectures } from 'models/actions/settingsActions';
import { downloadNewsletterUsers } from 'models/actions/userActions';
import { dashboardYear } from 'models/selectors/dashboardSelectors';
import {
  countriesSettings,
  prefecturesSettings,
} from 'models/selectors/settingsSelectors';
import { token } from 'models/selectors/userSelectors';
import { combineEpics, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, concatMap, tap, filter } from 'rxjs/operators';

import catchErrorOperator from './operators/catchErrorOperator';

const getDashBoardEpic = (action$, state$) =>
  action$.pipe(
    ofType(getDashBoard.type, setDashboardYear.type),
    mergeMap(() =>
      from(
        makeRequest(
          `dashboard/admin?year=${dashboardYear(state$.value)}`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap(
          ({
            newsletterUsers,
            registeredUsers,
            totalProducts,
            totalOrders,
            totalOrdersPending,
            netOrders,
            pmsmTotals,
            earningsPerMonth,
            couponsDiscountsTotal,
          }) => {
            return [
              setDashBoard({
                newsletterUsers,
                registeredUsers,
                totalProducts,
                totalOrders,
                totalOrdersPending,
                netOrders,
                pmsmTotals,
                earningsPerMonth,
                couponsDiscountsTotal,
              }),
              setGeneralLoading(false),
            ];
          },
        ),
        catchErrorOperator(true),
      ),
    ),
  );

const getAdminCountriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(getDashBoard.type),
    filter(() => countriesSettings(state$.value)?.length === 0),
    mergeMap(() =>
      from(makeRequest('countries', 'GET', '')).pipe(
        concatMap(({ countries }) => [
          setCountries(countries),
          setGeneralLoading(false),
        ]),
      ),
    ),
  );

const getAdminPrefecturesEpic = (action$, state$) =>
  action$.pipe(
    ofType(getDashBoard.type),
    filter(() => prefecturesSettings(state$.value)?.length === 0),
    mergeMap(() =>
      from(makeRequest('prefectures/all', 'GET', '')).pipe(
        concatMap(({ prefectures }) => [
          setPrefectures(prefectures),
          setGeneralLoading(false),
        ]),
      ),
    ),
  );

const downloadNewsletterUsersEpic = (action$, state$) =>
  action$.pipe(
    ofType(downloadNewsletterUsers.type),
    mergeMap(() =>
      from(
        fetch(`${process.env.REACT_APP_API}/users/admin/download/newsletter`, {
          method: 'POST',
          body: {},
          headers: {
            Authorization: `Bearer ${token(state$.value)}`,
          },
        }).then((response) => response.blob()),
      ).pipe(
        tap((payload) => {
          // 2. Create blob link to download
          const url = window.URL.createObjectURL(new Blob([payload]));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', `file.csv`);
          // 3. Append to html page
          document.body.appendChild(link);
          // 4. Force download
          link.click();
          // 5. Clean up and remove the link
          link.parentNode.removeChild(link);
        }),
        concatMap(() => [setGeneralLoading(false)]),
        catchErrorOperator(true),
      ),
    ),
  );

export {
  getDashBoardEpic,
  downloadNewsletterUsersEpic,
  getAdminCountriesEpic,
  getAdminPrefecturesEpic,
};

export default combineEpics(
  getDashBoardEpic,
  downloadNewsletterUsersEpic,
  getAdminCountriesEpic,
  getAdminPrefecturesEpic,
);
