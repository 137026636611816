import { createAction } from '@reduxjs/toolkit';

const getInitialOrdersSeries = createAction('orders/getInitialOrdersSeries');
const getOrdersStatuses = createAction('orders/getOrdersStatuses');
const setOrdersCurrentPage = createAction('orders/setOrdersCurrentPage');
const setOrderStatuses = createAction('orders/setOrderStatuses');
const ordersFiltersSearch = createAction('orders/ordersFiltersSearch');
const setOrders = createAction('orders/setOrders');
const removeSelectedOrderFilter = createAction(
  'orders/removeSelectedOrderFilter',
);
const getOrder = createAction('orders/getOrder');
const getOrders = createAction('orders/getOrders');
const cancelOrder = createAction('orders/cancelOrder');
const setOrderPopup = createAction('orders/setOrderPopup');
const getOrderPopup = createAction('orders/getOrderPopup');
const setProductToAdd = createAction('orders/setProductToAdd');
const setOrderProducts = createAction('orders/setOrderProducts');
const getOrderProducts = createAction('orders/getOrderProducts');
const setOrderProductSearchTerm = createAction(
  'orders/setOrderProductSearchTerm',
);
const addProductToOrder = createAction('orders/addProductToOrder');
const setNewProductTotal = createAction('orders/setNewProductTotal');
const removeProductFromOrder = createAction('orders/removeProductFromOrder');
const reSendOrderMail = createAction('orders/reSendOrderMail');
const downloadOrdersCsv = createAction('orders/downloadOrdersCsv');
const setOrdersSorting = createAction('orders/setOrdersSorting');
const getDOYS = createAction('orders/getDOYS');
const setDOYS = createAction('orders/setDOYS');
const sendCourierTrackEmail = createAction('orders/sendCourierTrackEmail');
const setProductNewQuantityInOrder = createAction(
  'orders/setProductNewQuantityInOrder',
);

export {
  getInitialOrdersSeries,
  getOrdersStatuses,
  setOrdersCurrentPage,
  setOrderStatuses,
  ordersFiltersSearch,
  setOrders,
  removeSelectedOrderFilter,
  getOrder,
  getOrders,
  cancelOrder,
  setOrderPopup,
  getOrderPopup,
  setProductToAdd,
  setOrderProductSearchTerm,
  getOrderProducts,
  setOrderProducts,
  addProductToOrder,
  setNewProductTotal,
  removeProductFromOrder,
  reSendOrderMail,
  downloadOrdersCsv,
  setOrdersSorting,
  setProductNewQuantityInOrder,
  getDOYS,
  setDOYS,
  sendCourierTrackEmail,
};
