import formatMoney from 'library/formatMoney';
import getCartTotals from 'library/getCartTotals';
import getCartTotalsDiscount from 'library/getCartTotalsDiscount';
import React from 'react';

const OrderTotals = ({ products, pmCost, smCost, couponDiscount }) => {
  const totalPayment =
    smCost > 0 || pmCost > 0
      ? formatMoney.format(
          ((smCost + pmCost + getCartTotals(products)) *
            (100 - couponDiscount)) /
            100,
        )
      : formatMoney.format(
          (getCartTotals(products) * (100 - couponDiscount)) / 100,
        );

  const totalCouponDiscount = formatMoney.format(
    (Number(couponDiscount) * (smCost + pmCost + getCartTotals(products))) /
      100,
  );

  return (
    <div className="cart-totals-container">
      <div className="cart-totals-row cart-totals-row-title">ΣΥΝΟΛΟ</div>
      <div className="cart-totals-row">
        <span className="cart-totals-name">Συνολική έκπτωση:</span>
        <span className="cart-totals-value">
          {formatMoney.format(
            getCartTotalsDiscount(products) +
              (Number(couponDiscount) *
                (smCost + pmCost + getCartTotals(products))) /
                100,
          )}
        </span>
      </div>
      {couponDiscount > 0 && (
        <div className="cart-totals-row">
          <span className="cart-totals-name">Εκπτωση κουπονιού:</span>
          <span className="cart-totals-value">{totalCouponDiscount}</span>
        </div>
      )}
      {smCost > 0 && (
        <div className="cart-totals-row">
          <span className="cart-totals-name">Μεταφορικά:</span>
          <span className="cart-totals-value">
            {formatMoney.format(smCost)}
          </span>
        </div>
      )}
      {pmCost > 0 && (
        <div className="cart-totals-row">
          <span className="cart-totals-name">Έξοδα πληρωμής:</span>
          <span className="cart-totals-value">
            {formatMoney.format(pmCost)}
          </span>
        </div>
      )}
      <div className="cart-totals-row bold">
        <span className="cart-totals-name">Σύνολο πληρωμής:</span>
        <span className="cart-totals-value">{totalPayment}</span>
      </div>
    </div>
  );
};

export default OrderTotals;
