/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { setGeneralLoading } from 'models/actions/generalActions';
import { updateKeyWord } from 'models/actions/settingsActions';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import RichTextEditor from 'react-rte';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const KeyWordPopup = ({ open, keyWord, handleClose, onChange }) => {
  const dispatch = useDispatch();
  const [keyWordPopupData, setKeyWordPopupData] = useState({
    id: '',
    page: '',
    keywords: '',
    slug: '',
  });
  const [error, setError] = useState('');
  const [errorKeywords, setErrorKeywords] = useState('');

  // RICH TEXT EDITOR
  const [valueLargeDescription, setValueLargeDescription] = useState(
    RichTextEditor.createEmptyValue(),
  );

  const handleOnChangeLargeDescription = (value) => {
    setValueLargeDescription(value);
    if (onChange) {
      onChange(value.toString('html'));
    }
  };

  useEffect(() => {
    const newState = {
      ...keyWordPopupData,
      ...keyWord,
    };

    setKeyWordPopupData(newState);
    setValueLargeDescription(
      RichTextEditor.createValueFromString(newState?.keywords, 'html'),
    );

    return () => {
      dispatch(setGeneralLoading(false));
      setKeyWordPopupData({ id: '', page: '', keywords: '', slug: '' });
    };
  }, [keyWord?.id]);

  useEffect(() => {
    if (keyWordPopupData?.keywords !== '') {
      setValueLargeDescription(
        RichTextEditor.createValueFromString(
          keyWordPopupData?.keywords,
          'html',
        ),
      );
    }
  }, []);

  const handleBrandUpdate = () => {
    if (keyWordPopupData && keyWordPopupData?.page === '') {
      setError('Error');

      return false;
    }

    if (valueLargeDescription.toString('html') === '<p><br></p>') {
      setErrorKeywords('Error');

      return false;
    }

    setError('');
    setErrorKeywords('');
    dispatch(setGeneralLoading(true));
    dispatch(
      updateKeyWord({
        data: {
          ...keyWordPopupData,
          keywords: valueLargeDescription.toString('html'),
        },
      }),
    );
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>{keyWordPopupData?.page}</span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field more-margin-bottom">
              <FormControl>
                <InputLabel htmlFor="page-name">Τίτλος</InputLabel>
                <Input
                  id="page-name"
                  error={error !== ''}
                  value={keyWordPopupData?.page ? keyWordPopupData?.page : ''}
                  onChange={(e) =>
                    setKeyWordPopupData({
                      ...keyWordPopupData,
                      page: e.target.value,
                    })
                  }
                />
                {error && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε όνομα.
                  </span>
                )}
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <InputLabel
                  className="rich-label differentLabel large-description"
                  htmlFor="page-popup-largeDescription">
                  Περιεχόμενο σελίδας
                </InputLabel>
                <RichTextEditor
                  value={valueLargeDescription}
                  onChange={handleOnChangeLargeDescription}
                />
                {errorKeywords && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε keywords.
                  </span>
                )}
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handleBrandUpdate} className="button add">
            ΕΝΗΜΕΡΩΣΗ
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default KeyWordPopup;
