import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const Coupon = ({ cp, toggleCoupon, toggleCouponWithRemove }) => {
  const { id, code, hours, discount, isActive } = cp;

  return (
    <TableRow className="product-row extra">
      <TableCell>
        <Tooltip title={code}>
          <span>{code}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={hours}>
          <span>{hours}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={`${discount}%`}>
          <span>{`${discount}%`}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Ενημέρωση coupon">
          <i onClick={() => toggleCoupon(id)} className="icon-pencil edit" />
        </Tooltip>
        <Tooltip title="Διαγραφή coupon">
          <i
            onClick={() => toggleCouponWithRemove(id)}
            className={`${
              isActive
                ? 'icon-toggle-on add-icon'
                : 'icon-toggle-off delete-icon'
            }`}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default Coupon;
