import { createReducer } from '@reduxjs/toolkit';
import {
  setDashBoard,
  setDashboardYear,
} from 'models/actions/dashboardActions';
import { resetState } from 'models/actions/userActions';

const initialState = {
  registeredUsers: 0,
  newsletterUsers: 0,
  totalOrders: 0,
  totalProducts: 0,
  totalOrdersPending: 0,
  netOrders: 0,
  pmsmTotals: 0,
  couponsDiscountsTotal: 0,
  dashboardYear: new Date().getFullYear(),
  earningsPerMonth: [],
};

const dashboardReducer = createReducer(initialState, {
  [setDashBoard.type]: (state, action) => ({
    ...state,
    newsletterUsers: action.payload.newsletterUsers,
    registeredUsers: action.payload.registeredUsers,
    totalOrders: action.payload.totalOrders,
    totalProducts: action.payload.totalProducts,
    totalOrdersPending: action.payload.totalOrdersPending,
    netOrders: action.payload.netOrders,
    pmsmTotals: action.payload.pmsmTotals,
    earningsPerMonth: action.payload.earningsPerMonth,
    couponsDiscountsTotal: action.payload.couponsDiscountsTotal,
  }),
  [setDashboardYear.type]: (state, action) => ({
    ...state,
    dashboardYear: action.payload,
  }),
  [resetState.type]: (state, action) => ({ ...initialState }),
});

export default dashboardReducer;
