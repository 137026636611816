import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const HomeTab = ({ ht, toggleHT, toggleHTWithRemove }) => {
  const { id, title, isActive, products } = ht;

  return (
    <TableRow className="product-row extra">
      <TableCell>
        <Tooltip title={title}>
          <span>{title}</span>
        </Tooltip>
      </TableCell>
      <TableCell>{products.length}</TableCell>
      <TableCell>
        <Tooltip title="Ενημέρωση καρτέλας">
          <i onClick={() => toggleHT(id)} className="icon-pencil edit" />
        </Tooltip>
        <Tooltip title="Διαγραφή καρτέλας">
          <i
            onClick={() => toggleHTWithRemove(id)}
            className={`${
              isActive
                ? 'icon-toggle-on add-icon'
                : 'icon-toggle-off delete-icon'
            }`}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default HomeTab;
