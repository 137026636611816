import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const HomeLastBanner = ({ hb, toggleHB, toggleHBWithRemove }) => {
  const { id, description, isActive } = hb;

  return (
    <TableRow className="product-row extra">
      <TableCell>
        <Tooltip title={description}>
          <span>{description}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Ενημέρωση slide">
          <i onClick={() => toggleHB(id)} className="icon-pencil edit" />
        </Tooltip>
        <Tooltip title="Απενεργοποίηση slide">
          <i
            onClick={() => toggleHBWithRemove(id)}
            className={`${
              isActive
                ? 'icon-toggle-on add-icon'
                : 'icon-toggle-off delete-icon'
            }`}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default HomeLastBanner;
