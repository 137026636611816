/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { setGeneralLoading } from 'models/actions/generalActions';
import { updateOfferTitle } from 'models/actions/settingsActions';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const OfferTitlePopup = ({ open, offerTitle, handleClose }) => {
  const dispatch = useDispatch();
  const [offerTitlePopupData, setOfferTitlePopupData] = useState({
    id: '',
    name: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    const newState = {
      ...offerTitlePopupData,
      ...offerTitle,
    };

    setOfferTitlePopupData(newState);

    return () => {
      dispatch(setGeneralLoading(false));
      setOfferTitlePopupData({ id: '', name: '' });
    };
  }, [offerTitle?.id]);

  const handleOfferTitleUpdate = () => {
    if (offerTitlePopupData && offerTitlePopupData?.name === '') {
      setError('Error');

      return false;
    }

    setError('');
    dispatch(setGeneralLoading(true));
    dispatch(
      updateOfferTitle({
        data: {
          ...offerTitlePopupData,
        },
      }),
    );
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>{offerTitlePopupData?.name}</span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field more-margin-bottom">
              <FormControl>
                <InputLabel htmlFor="offertitle-name">Τίτλος</InputLabel>
                <Input
                  id="offertitle-name"
                  error={error !== ''}
                  value={
                    offerTitlePopupData?.name ? offerTitlePopupData?.name : ''
                  }
                  onChange={(e) =>
                    setOfferTitlePopupData({
                      ...offerTitlePopupData,
                      name: e.target.value,
                    })
                  }
                />
                {error && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε όνομα.
                  </span>
                )}
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handleOfferTitleUpdate} className="button add">
            ΕΝΗΜΕΡΩΣΗ
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OfferTitlePopup;
