import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const KeyWord = ({ kw, toggleKW }) => {
  const { id, page } = kw;

  return (
    <TableRow className="product-row extra">
      <TableCell>
        <Tooltip title={page}>
          <span>{page}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Ενημέρωση keywords">
          <i onClick={() => toggleKW(id)} className="icon-pencil edit" />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default KeyWord;
