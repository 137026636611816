const registeredUsers = ({ dashboardReducer }) =>
  dashboardReducer.registeredUsers;

const newsletterUsers = ({ dashboardReducer }) =>
  dashboardReducer.newsletterUsers;

const totalOrders = ({ dashboardReducer }) => dashboardReducer.totalOrders;

const totalProducts = ({ dashboardReducer }) => dashboardReducer.totalProducts;

const totalOrdersPending = ({ dashboardReducer }) =>
  dashboardReducer.totalOrdersPending;

const netOrders = ({ dashboardReducer }) => dashboardReducer.netOrders;
const pmsmTotals = ({ dashboardReducer }) => dashboardReducer.pmsmTotals;
const couponsDiscountsTotal = ({ dashboardReducer }) =>
  dashboardReducer.couponsDiscountsTotal;
const dashboardYear = ({ dashboardReducer }) => dashboardReducer.dashboardYear;
const earningsPerMonth = ({ dashboardReducer }) =>
  dashboardReducer.earningsPerMonth;

export {
  registeredUsers,
  newsletterUsers,
  totalOrders,
  totalProducts,
  totalOrdersPending,
  netOrders,
  pmsmTotals,
  dashboardYear,
  earningsPerMonth,
  couponsDiscountsTotal,
};
