import { createReducer } from '@reduxjs/toolkit';
import {
  setGeneralLoading,
  toggleShowAlert,
  // categories
  setGeneralCategories,
  setSubCategoriesForCategoryId,
  setSubCatSearchTerm,
  setAvailableSubCategories,
  // subcategories
  setGeneralSubCategories,
  // brands
  setGeneralBrands,
} from 'models/actions/generalActions';
import { resetState } from 'models/actions/userActions';

const initialState = {
  loading: false,
  message: 'Alert',
  show: false,
  type: 'success',
  categories: {
    total: 0,
    results: [],
    subCategoriesForCategoryId: [],
    availableSubCategories: [],
    subCategorySearchText: '',
  },
  subCategories: {
    total: 0,
    results: [],
  },
  brands: {
    total: 0,
    results: [],
  },
};
const generalReducer = createReducer(initialState, {
  [setGeneralLoading.type]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
  [setAvailableSubCategories.type]: (state, action) => ({
    ...state,
    categories: {
      ...state.categories,
      availableSubCategories: action.payload,
    },
  }),
  [setSubCatSearchTerm.type]: (state, action) => ({
    ...state,
    categories: {
      ...state.categories,
      subCategorySearchText: action.payload,
    },
  }),
  [toggleShowAlert.type]: (state, action) => ({
    ...state,
    message: action.payload.message,
    show: action.payload.show,
    type: action.payload.type,
  }),
  [setGeneralCategories.type]: (state, action) => ({
    ...state,
    categories: {
      ...state.categories,
      total: action.payload.total,
      results: action.payload.results,
    },
  }),
  [setGeneralSubCategories.type]: (state, action) => ({
    ...state,
    subCategories: {
      ...state.subCategories,
      total: action.payload.total,
      results: action.payload.results,
    },
  }),
  [setSubCategoriesForCategoryId.type]: (state, action) => ({
    ...state,
    categories: {
      ...state.categories,
      subCategoriesForCategoryId: action.payload,
    },
  }),
  [setGeneralBrands.type]: (state, action) => ({
    ...state,
    brands: {
      ...state.brands,
      total: action.payload.total,
      results: action.payload.results,
    },
  }),
  [resetState.type]: (state, action) => ({ ...initialState }),
});

export default generalReducer;
