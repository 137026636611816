import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import {
  getNewsletterCoupon,
  setNewsletterCouponLoading,
  toggleNewsletterCoupon,
} from 'models/actions/settingsActions';
import { newsletterCouponsSettings } from 'models/selectors/settingsSelectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const NewsletterCoupon = () => {
  const dispatch = useDispatch();

  const newsletterCoupon = useSelector(newsletterCouponsSettings).isActive;
  const newsletterCouponLoading = useSelector(newsletterCouponsSettings)
    .loading;

  useEffect(() => {
    dispatch(setNewsletterCouponLoading(true));
    dispatch(getNewsletterCoupon());
  }, []);

  return !newsletterCouponLoading ? (
    <div className="product-popup-fields">
      <div className="product-popup-field">
        <FormControl>
          <FormControlLabel
            className="page-popup-isActive"
            control={
              <Switch
                checked={newsletterCoupon}
                onChange={(e) => {
                  dispatch(setNewsletterCouponLoading(true));
                  dispatch(toggleNewsletterCoupon(!newsletterCoupon));
                }}
                name="checkedNewsletterCoupon"
              />
            }
            label={newsletterCoupon ? 'Ενεργό' : 'Ανενεργό'}
          />
        </FormControl>
      </div>
    </div>
  ) : (
    <>
      <Skeleton width="60%" />
      <Skeleton variant="rectangular" height={50} />
    </>
  );
};

export default NewsletterCoupon;
