import makeRequest from 'library/makeRequest';
import {
  setGeneralLoading,
  toggleShowAlert,
} from 'models/actions/generalActions';
import {
  getInitialOrdersSeries,
  getOrdersStatuses,
  setOrderStatuses,
  ordersFiltersSearch,
  setOrders,
  setOrdersCurrentPage,
  removeSelectedOrderFilter,
  getOrders,
  cancelOrder,
  getOrderPopup,
  setOrderPopup,
  getOrderProducts,
  setOrderProducts,
  addProductToOrder,
  setProductToAdd,
  setOrderProductSearchTerm,
  setNewProductTotal,
  removeProductFromOrder,
  reSendOrderMail,
  downloadOrdersCsv,
  setOrdersSorting,
  getDOYS,
  setDOYS,
  sendCourierTrackEmail,
} from 'models/actions/ordersActions';
import {
  getAllPaymentMethods,
  getAllShippingMethods,
} from 'models/actions/settingsActions';
import { token } from 'models/selectors/userSelectors';
import { combineEpics, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, mergeMap, concatMap, withLatestFrom, tap } from 'rxjs/operators';

import catchErrorOperator from './operators/catchErrorOperator';

const getInitialOrdersSeriesEpic = (action$) =>
  action$.pipe(ofType(getInitialOrdersSeries.type), map(getOrdersStatuses));

const getOrdersStatusesEpic = (action$, state$) =>
  action$.pipe(
    ofType(getOrdersStatuses.type),
    mergeMap(() =>
      from(makeRequest('statuses/admin', 'GET', '', token(state$.value))).pipe(
        concatMap(({ data }) => {
          return [
            getAllPaymentMethods(),
            getAllShippingMethods(),
            setOrderStatuses(data),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getOrdersEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      ordersFiltersSearch.type,
      setOrderStatuses.type,
      setOrdersCurrentPage.type,
      removeSelectedOrderFilter.type,
      getOrders.type,
      setOrdersSorting.type,
    ),
    withLatestFrom(state$),
    mergeMap(
      ([
        ,
        {
          ordersReducer: {
            filters,
            sorting,
            orders: {
              pagination: { currentPage },
            },
          },
        },
      ]) => {
        const {
          selectedStatus,
          selectedName,
          selectedEmail,
          selectedDate,
          selectedOrderCode,
          selectedPaymentMethod,
          selectedShippingMethod,
        } = filters;

        return from(
          makeRequest(
            `order/admin/all?page=${currentPage}&sort=${sorting}&order_code=${selectedOrderCode}&order_status=${selectedStatus}&order_name=${selectedName}&order_date=${
              selectedDate !== '' ? new Date(selectedDate) : ''
            }&order_email=${selectedEmail}&payment_method=${selectedPaymentMethod}&shipping_method=${selectedShippingMethod}`,
            'GET',
            '',
            token(state$.value),
          ),
        ).pipe(
          concatMap((payload) => [
            setOrders(payload),
            setGeneralLoading(false),
          ]),
          catchErrorOperator(true),
        );
      },
    ),
  );

const getDOYSEpic = (action$) =>
  action$.pipe(
    ofType(getDOYS.type),
    mergeMap(() =>
      from(makeRequest('doys', 'GET', '')).pipe(
        concatMap((payload) => {
          const newPayload = payload?.map((doy) => ({
            ...doy,
            label: doy?.name,
          }));

          return [setDOYS(newPayload)];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const cancelOrderEpic = (action$, state$) =>
  action$.pipe(
    ofType(cancelOrder.type),
    mergeMap(({ payload: { orderId } }) =>
      from(
        makeRequest(
          `order/admin/setorder/cancel`,
          'POST',
          JSON.stringify({ orderId }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: payload?.message,
              show: true,
              type: 'success',
            }),
            getOrders(),
            getOrderPopup(orderId),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getOrderPopupEpic = (action$, state$) =>
  action$.pipe(
    ofType(getOrderPopup.type),
    withLatestFrom(state$),
    mergeMap(([{ payload }, { ordersReducer: { statuses } }]) =>
      from(
        makeRequest(
          `order/admin/order/${payload}`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          const {
            order: {
              order_code,
              dateCreated,
              statusId,
              paymentMethodCost,
              shippingMethodCost,
              piAfm,
              piEponymia,
              piDoy,
              piFirstName,
              piLastName,
              piAddress,
              piPhone,
              piPostCode,
              siFirstName,
              siLastName,
              siAddress,
              siPhone,
              siPostCode,
              couponDiscount,
            },
            pastStatuses,
            products,
          } = payload;

          return [
            setOrderPopup({
              orderCode: order_code,
              dateCreated: dateCreated,
              statusId: statusId,
              statusName: statuses?.find((st) => st?.id === statusId)?.name,
              paymentMethodCost: paymentMethodCost,
              shippingMethodCost: shippingMethodCost,
              piAfm: piAfm,
              piEponymia: piEponymia,
              piFirstName: piFirstName,
              piDoy: piDoy,
              piLastName: piLastName,
              piAddress: piAddress,
              piPhone: piPhone,
              piPostCode: piPostCode,
              siFirstName: siFirstName,
              siLastName: siLastName,
              siAddress: siAddress,
              siPhone: siPhone,
              siPostCode: siPostCode,
              pastStatuses: pastStatuses,
              products: products,
              couponDiscount,
            }),
            setGeneralLoading(false),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const getOrderProductsEpic = (action$, state$) =>
  action$.pipe(
    ofType(getOrderProducts.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `order/admin/products?search=${payload}`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap(({ result }) => [
          setOrderProducts({ result }),
          setGeneralLoading(false),
        ]),
        catchErrorOperator(true),
      ),
    ),
  );

const addProductToOrderEpic = (action$, state$) =>
  action$.pipe(
    ofType(addProductToOrder.type),
    mergeMap(({ payload: { total, product, orderId } }) => {
      return from(
        makeRequest(
          'order/admin/products/add',
          'POST',
          JSON.stringify({ total, product, orderId }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setOrderProducts({ result: [] }),
            setProductToAdd({}),
            setOrderProductSearchTerm(''),
            setNewProductTotal(0),
            getOrderPopup(orderId),
            setGeneralLoading(false),
            toggleShowAlert({
              message: payload?.message,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      );
    }),
  );

const removeProductFromOrderEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeProductFromOrder.type),
    mergeMap(({ payload: { orderId, productId, items } }) =>
      from(
        makeRequest(
          'order/admin/products/delete',
          'POST',
          JSON.stringify({ orderId, productId, items }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            toggleShowAlert({
              message: payload?.message,
              show: true,
              type: 'success',
            }),
            getOrderPopup(orderId),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const reSendOrderMailEpic = (action$, state$) =>
  action$.pipe(
    ofType(reSendOrderMail.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          `order/admin/sendmail?id=${payload}`,
          'GET',
          '',
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const sendCourierTrackEmailEpic = (action$, state$) =>
  action$.pipe(
    ofType(sendCourierTrackEmail.type),
    mergeMap(({ payload: { order, courierLink, message } }) =>
      from(
        makeRequest(
          `order/admin/sendcouriermail`,
          'POST',
          JSON.stringify({ order, courierLink, message }),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              setGeneralLoading(false),
              toggleShowAlert({
                message: `${payload?.error}`,
                show: true,
                type: 'error',
              }),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              show: true,
              type: 'success',
            }),
          ];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const downloadOrdersCsvEpic = (action$, state$) =>
  action$.pipe(
    ofType(downloadOrdersCsv.type),
    mergeMap(() =>
      from(
        fetch(`${process.env.REACT_APP_API}/order/admin/download`, {
          method: 'POST',
          body: {},
          headers: {
            Authorization: `Bearer ${token(state$.value)}`,
          },
        }).then((response) => response.blob()),
      ).pipe(
        tap((payload) => {
          // 2. Create blob link to download
          const url = window.URL.createObjectURL(new Blob([payload]));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', `orders.csv`);
          // 3. Append to html page
          document.body.appendChild(link);
          // 4. Force download
          link.click();
          // 5. Clean up and remove the link
          link.parentNode.removeChild(link);
        }),
        concatMap(() => [setGeneralLoading(false)]),
        catchErrorOperator(true),
      ),
    ),
  );

export {
  getInitialOrdersSeriesEpic,
  getOrdersStatusesEpic,
  getOrdersEpic,
  cancelOrderEpic,
  getOrderPopupEpic,
  getOrderProductsEpic,
  addProductToOrderEpic,
  removeProductFromOrderEpic,
  reSendOrderMailEpic,
  downloadOrdersCsvEpic,
  getDOYSEpic,
  sendCourierTrackEmailEpic,
};

export default combineEpics(
  getInitialOrdersSeriesEpic,
  getOrdersStatusesEpic,
  getOrdersEpic,
  cancelOrderEpic,
  getOrderPopupEpic,
  getOrderProductsEpic,
  addProductToOrderEpic,
  removeProductFromOrderEpic,
  reSendOrderMailEpic,
  downloadOrdersCsvEpic,
  getDOYSEpic,
  sendCourierTrackEmailEpic,
);
