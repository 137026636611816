import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const OfferTitle = ({ ot, toggleOT }) => {
  const { id, name } = ot;

  return (
    <TableRow className="product-row extra">
      <TableCell>
        <Tooltip title={name}>
          <span>{name}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Ενημέρωση offer title">
          <i onClick={() => toggleOT(id)} className="icon-pencil edit" />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default OfferTitle;
