/* eslint-disable react-hooks/exhaustive-deps */
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  getAllShippingMethods,
  setShippingMethodsLoading,
  removeShippingMethod,
  setSingleShippingMethod,
} from 'models/actions/settingsActions';
import { shippingSettings } from 'models/selectors/settingsSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ShippingMethod from './ShippingMethod';
import ShippingMethodPopup from './ShippingMethodPopup';

const ShippingMethods = () => {
  const dispatch = useDispatch();
  const [shippingMethodPopupOpen, setShippingMethodPopupOpen] = useState({
    open: false,
    shippingMethodOpenForEdit: '',
  });
  const shippingMethods = useSelector(shippingSettings)?.methods;
  const loading = useSelector(shippingSettings)?.loading;

  useEffect(() => {
    dispatch(setShippingMethodsLoading(true));
    dispatch(getAllShippingMethods());
  }, []);

  const toggleSMOpenWithId = (smId) => {
    setShippingMethodPopupOpen({
      ...shippingMethodPopupOpen,
      open: true,
      shippingMethodOpenForEdit: smId,
    });
  };

  const toggleSMWithRemove = (smId, isActive) => {
    setShippingMethodPopupOpen({
      ...shippingMethodPopupOpen,
      open: false,
      shippingMethodOpenForEdit: '',
    });
    dispatch(setGeneralLoading(true));
    dispatch(removeShippingMethod({ id: smId, isActive }));
  };

  return (
    <div>
      {shippingMethodPopupOpen?.open && (
        <ShippingMethodPopup
          open={shippingMethodPopupOpen?.open}
          smId={shippingMethodPopupOpen?.shippingMethodOpenForEdit}
          handleClose={() =>
            setShippingMethodPopupOpen({
              ...shippingMethodPopupOpen,
              open: false,
              shippingMethodOpenForEdit: '',
            })
          }
        />
      )}
      {!loading ? (
        <>
          <div className="products-crud-actions double auto">
            <span>{`(${shippingMethods.length || 0} τρόποι αποστολής)`}</span>
            <button
              className="button add"
              onClick={() => {
                setShippingMethodPopupOpen({
                  ...shippingMethodPopupOpen,
                  open: true,
                  shippingMethodOpenForEdit: '',
                });
                dispatch(setSingleShippingMethod({}));
              }}>
              ΔΗΜΙΟΥΡΓΙΑ
            </button>
          </div>
          {shippingMethods?.length > 0 ? (
            <Table className="products-grid">
              <TableHead>
                <TableRow>
                  <TableCell>ΟΝΟΜΑ</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shippingMethods?.map((sm) => (
                  <ShippingMethod
                    key={sm.id}
                    sm={sm}
                    toggleSM={toggleSMOpenWithId}
                    toggleSMWithRemove={() =>
                      toggleSMWithRemove(sm?.id, !sm?.isActive)
                    }
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="not-found">Δεν βρέθηκαν τρόποι αποστολής!</div>
          )}
        </>
      ) : (
        <>
          <Skeleton width="60%" />
          <Skeleton width="60%" />
          <Skeleton variant="rectangular" height={250} />
        </>
      )}
    </div>
  );
};

export default ShippingMethods;
