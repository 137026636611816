/* eslint-disable react-hooks/exhaustive-deps */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  getAllBrands,
  removeBrand,
  setGeneralLoading,
} from 'models/actions/generalActions';
import { allBrands } from 'models/selectors/generalSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Brand from './Brand';
import BrandPopup from './BrandPopup';

const Brands = () => {
  const dispatch = useDispatch();
  const brands = useSelector(allBrands);
  const [brandPopupOpen, setBrandPopupOpen] = useState({
    open: false,
    brandOpenForEdit: '',
  });

  useEffect(() => {
    dispatch(setGeneralLoading(true));
    dispatch(getAllBrands());
  }, []);

  const toggleBrandOpenWithId = (brandId) => {
    setBrandPopupOpen({
      ...brandPopupOpen,
      open: true,
      brandForEdit: brandId,
    });
  };

  const toggleBrandWithRemove = (brandId, isActive) => {
    setBrandPopupOpen({
      ...brandPopupOpen,
      open: false,
      brandForEdit: '',
    });
    dispatch(setGeneralLoading(true));
    dispatch(removeBrand({ id: brandId, isActive }));
  };

  return (
    <div className="content general-page">
      {brandPopupOpen?.open && (
        <BrandPopup
          open={brandPopupOpen?.open}
          brandId={brandPopupOpen?.brandForEdit}
          handleClose={() =>
            setBrandPopupOpen({
              ...brandPopupOpen,
              open: false,
              brandForEdit: '',
            })
          }
        />
      )}
      <div className="row">
        <div className="wrapper">
          <div className="page-title">Brands</div>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <div className="products-crud-actions double">
            <span>{`(${brands.total} brands)`}</span>
            <button
              className="button add"
              onClick={() =>
                setBrandPopupOpen({
                  ...brandPopupOpen,
                  open: true,
                  brandForEdit: '',
                })
              }>
              ΔΗΜΙΟΥΡΓΙΑ
            </button>
          </div>
        </div>
      </div>
      {brands.total > 0 ? (
        <>
          <div className="row">
            <div className="wrapper">
              <div className="products">
                <Table className="products-grid">
                  <TableHead>
                    <TableRow>
                      <TableCell>ΟΝΟΜΑ</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {brands?.results?.map((brand) => (
                      <Brand
                        toggleBrand={toggleBrandOpenWithId}
                        toggleBrandWithRemove={() =>
                          toggleBrandWithRemove(brand?.id, !brand?.isActive)
                        }
                        brand={brand}
                        key={brand?.id}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="not-found">Δεν βρέθηκαν brands!</div>
      )}
    </div>
  );
};

export default Brands;
