/* eslint-disable react-hooks/exhaustive-deps */
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  getAllHomeTabs,
  setHomeTabsLoading,
  removeHomeTab,
} from 'models/actions/settingsActions';
import { homeTabsSettings } from 'models/selectors/settingsSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HomeTab from './HomeTab';
import HomeTabsPopup from './HomeTabsPopup';

const HomeTabsSettings = () => {
  const dispatch = useDispatch();
  const homeTabs = useSelector(homeTabsSettings)?.tabs;
  const loading = useSelector(homeTabsSettings)?.loading;
  const [homeTabPopupOpen, setHomeTabPopupOpen] = useState({
    open: false,
    homeTabOpenForEdit: '',
  });

  useEffect(() => {
    dispatch(setHomeTabsLoading(true));
    dispatch(getAllHomeTabs());
  }, []);

  const toggleHTOpenWithId = (htId) => {
    setHomeTabPopupOpen({
      ...homeTabPopupOpen,
      open: true,
      homeTabOpenForEdit: htId,
    });
  };

  const toggleHTWithRemove = (htId, isActive) => {
    setHomeTabPopupOpen({
      ...homeTabPopupOpen,
      open: false,
      homeTabOpenForEdit: '',
    });
    dispatch(setGeneralLoading(true));
    dispatch(removeHomeTab({ id: htId, isActive }));
  };

  return (
    <div>
      {homeTabPopupOpen?.open && (
        <HomeTabsPopup
          open={homeTabPopupOpen?.open}
          htId={homeTabPopupOpen?.homeTabOpenForEdit}
          handleClose={() =>
            setHomeTabPopupOpen({
              ...homeTabPopupOpen,
              open: false,
              homeTabOpenForEdit: '',
            })
          }
        />
      )}
      {!loading ? (
        <>
          <div className="products-crud-actions double auto">
            <span>{`(${homeTabs?.length || 0} καρτέλες προϊόντων)`}</span>
            <button
              className="button add"
              onClick={() =>
                setHomeTabPopupOpen({
                  ...homeTabPopupOpen,
                  open: true,
                  homeTabOpenForEdit: '',
                })
              }>
              ΔΗΜΙΟΥΡΓΙΑ
            </button>
          </div>
          {homeTabs?.length > 0 ? (
            <Table className="products-grid">
              <TableHead>
                <TableRow>
                  <TableCell>ΤΙΤΛΟΣ</TableCell>
                  <TableCell>ΑΡΙΘΜΟΣ ΠΡΟΪΟΝΤΩΝ</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {homeTabs?.map((ht) => (
                  <HomeTab
                    key={ht.id}
                    ht={ht}
                    toggleHT={toggleHTOpenWithId}
                    toggleHTWithRemove={() =>
                      toggleHTWithRemove(ht?.id, !ht?.isActive)
                    }
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="not-found">Δεν βρέθηκαν καρτέλες!</div>
          )}
        </>
      ) : (
        <>
          <Skeleton width="60%" />
          <Skeleton width="60%" />
          <Skeleton variant="rectangular" height={250} />
        </>
      )}
    </div>
  );
};

export default HomeTabsSettings;
