/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import { setGeneralLoading } from 'models/actions/generalActions';
import { updateSocial, addSocial } from 'models/actions/staticActions';
import { socialNetworks } from 'models/selectors/staticSelectors';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const PagePopup = ({ open, pageId, handleClose, onChange }) => {
  const dispatch = useDispatch();
  const allSocialNetworks = useSelector(socialNetworks);
  const [pagePopupData, setPagePopupData] = useState({
    name: '',
    link: '',
    isActive: 0,
  });
  const [error, setError] = useState('');

  useEffect(() => {
    const newState = {
      ...pagePopupData,
      ...allSocialNetworks?.find((page) => page?.id === pageId),
    };

    setPagePopupData(newState);

    return () => {
      dispatch(setGeneralLoading(false));
      setPagePopupData({ id: '', name: '', link: '', isActive: 0 });
    };
  }, [pageId]);

  const handleBrandUpdate = () => {
    if (pagePopupData && pagePopupData?.name === '') {
      setError('Error');

      return false;
    }

    setError('');
    dispatch(setGeneralLoading(true));
    if (pageId !== '') {
      dispatch(
        updateSocial({
          data: {
            ...pagePopupData,
            id: pageId,
          },
        }),
      );
    } else {
      dispatch(
        addSocial({
          data: {
            ...pagePopupData,
          },
        }),
      );
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>
            {pageId === ''
              ? 'ΔΗΜΙΟΥΡΓΙΑ ΚΟΙΝΩΝΙΚΟΥ ΔΙΚΤΥΟΥ'
              : pagePopupData?.title}
          </span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="page-name">Τίτλος</InputLabel>
                <Input
                  id="page-name"
                  error={error !== ''}
                  value={pagePopupData?.name ? pagePopupData?.name : ''}
                  onChange={(e) =>
                    setPagePopupData({
                      ...pagePopupData,
                      name: e.target.value,
                    })
                  }
                />
                {error && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε όνομα.
                  </span>
                )}
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="page-link">Link</InputLabel>
                <Input
                  id="page-link"
                  value={pagePopupData?.link ? pagePopupData?.link : ''}
                  onChange={(e) =>
                    setPagePopupData({
                      ...pagePopupData,
                      link: e.target.value,
                    })
                  }
                />
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <FormControlLabel
                  className="page-popup-isActive"
                  control={
                    <Switch
                      checked={Boolean(pagePopupData.isActive)}
                      onChange={(e) =>
                        setPagePopupData({
                          ...pagePopupData,
                          isActive: !pagePopupData.isActive,
                        })
                      }
                      name="checkedA"
                    />
                  }
                  label={pagePopupData.isActive ? 'Ενεργό' : 'Ανενεργό'}
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handleBrandUpdate} className="button add">
            {pageId !== '' ? 'ΕΝΗΜΕΡΩΣΗ' : 'ΔΗΜΙΟΥΡΓΙΑ'}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PagePopup;
