/* eslint-disable react-hooks/exhaustive-deps */
import Drawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import withToggle from 'components/hocs/withToggle';
import RoutePagination from 'components/pagination/RoutePagination';
import SelectedFiltersComp from 'components/selected-filters/SelectedFilters';
import Filters from 'components/users/Filters';
import User from 'components/users/User';
import UserPopup from 'components/users/UserPopup';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  getUsers,
  setCurrentPagesPage,
  removeSelectedFilter,
  removeUser,
} from 'models/actions/userActions';
import { users, usersSelectedFilters } from 'models/selectors/userSelectors';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Users = ({ toggleValue, setToggleValue }) => {
  const dispatch = useDispatch();
  const [userPopupOpen, setUserPopupOpen] = useState({
    open: false,
    userOpenForEdit: '',
  });
  const totalUsers = useSelector(users)?.results;
  const pagination = useSelector(users)?.pagination;
  const selectedFilters = useSelector(usersSelectedFilters);
  const { email, isActive, birthDate } = selectedFilters;

  const toggleUserOpenWithId = (productId) => {
    setUserPopupOpen({
      ...userPopupOpen,
      open: true,
      userOpenForEdit: productId,
    });
  };

  const toggleUserWithRemove = (userId, isActive) => {
    setUserPopupOpen({
      ...userPopupOpen,
      open: false,
      userOpenForEdit: '',
    });
    dispatch(setGeneralLoading(true));
    dispatch(removeUser({ id: userId, isActive }));
  };

  useEffect(() => {
    dispatch(setCurrentPagesPage(1));
    dispatch(setGeneralLoading(true));
    dispatch(getUsers());
  }, []);

  return (
    <div className="content users-page">
      <div className="row">
        <div className="wrapper">
          <div className="page-title">Χρήστες</div>
        </div>
      </div>
      {userPopupOpen?.open && (
        <UserPopup
          open={userPopupOpen?.open}
          userId={userPopupOpen?.userOpenForEdit}
          isAdministrator={
            totalUsers?.find(
              (user) => user?.id === userPopupOpen?.userOpenForEdit,
            )?.isAdmin
          }
          handleClose={() =>
            setUserPopupOpen({
              ...userPopupOpen,
              open: false,
              userOpenForEdit: '',
            })
          }
        />
      )}
      {(email !== '' || isActive !== '' || birthDate) && (
        <div className="row">
          <div className="wrapper">
            <SelectedFiltersComp
              selectedFilters={[
                {
                  label: 'Email',
                  value: email,
                  condition: '&&',
                  removeFn: removeSelectedFilter,
                  type: 'email',
                },
                {
                  label: 'Ενεργοποιημένος',
                  value: isActive,
                  condition: '',
                  removeFn: removeSelectedFilter,
                  type: 'isActive',
                },
                {
                  label: 'Γενέθλια',
                  value: birthDate,
                  condition: '&&',
                  removeFn: removeSelectedFilter,
                  type: 'birthDate',
                },
              ]}
            />
          </div>
        </div>
      )}
      <Drawer
        anchor="right"
        open={toggleValue?.right}
        className="filters-drawer"
        onClose={setToggleValue('right', false)}>
        <div className="products-filters-section">
          <Filters setToggleValue={setToggleValue} />
        </div>
      </Drawer>
      {totalUsers?.length > 0 && (
        <div className="row">
          <div className="wrapper">
            <div className="sorting-section for-products">
              <>
                <RoutePagination
                  setCurrentPage={setCurrentPagesPage}
                  pagination={pagination}
                />
              </>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="wrapper">
          <div className="products-crud-actions double">
            <span>{`(${pagination?.total || 0} χρήστες)`}</span>
            <button
              className="button lightblue"
              onClick={setToggleValue('right', true)}>
              ΦΙΛΤΡΑ
            </button>
          </div>
          {totalUsers?.length > 0 ? (
            <div className="products">
              <Table className="products-grid responsive-table">
                <TableHead>
                  <TableRow>
                    <TableCell>EMAIL</TableCell>
                    <TableCell>ΕΝΕΡΓΟΠΟΙΗΜΕΝΟΣ</TableCell>
                    <TableCell>ΔΙΑΧΕΙΡΙΣΤΗΣ</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {totalUsers?.map((user) => (
                    <User
                      user={user}
                      key={user?.id}
                      toggleUser={toggleUserOpenWithId}
                      toggleUserWithRemove={toggleUserWithRemove}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div className="not-found">
              <h2>Δεν βρέθηκαν προϊόντα με αυτά τα κριτήρια.</h2>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <div className="sorting-section">
            {totalUsers?.length > 0 && (
              <>
                <RoutePagination
                  setCurrentPage={setCurrentPagesPage}
                  pagination={pagination}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withToggle(Users);
