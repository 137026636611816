/* eslint-disable max-len */
import { createReducer } from '@reduxjs/toolkit';
import {
  setProductPage,
  setFilterCategories,
  setProducts,
  removeSelectedFilter,
  setFilterBrands,
  setInitialPricesRange,
  setProductsSorting,
  setCurrentProductsPage,
  removeSelectedCodeFilter,
  productsFiltersSearch,
  setFilterSubCategories,
  setProductPopup,
  setProductPopupDetailsValue,
  setSelectedProductsExtra,
  setProductsPopupCategories,
  setProductsPopupSubCategories,
  setProductsPopupBrands,
  setProductImage,
  setAllProducts,
} from 'models/actions/productsActions';
import { resetState } from 'models/actions/userActions';

const initialState = {
  allProducts: [],
  productImage: '',
  productPopupDetails: {},
  productPopupDetailsExtra: {
    categories: [],
    subCategories: [],
    brands: [],
  },
  singleProduct: {},
  filterCategories: [],
  filterSubCategories: [],
  filterBrands: [],
  filterPricesRange: {},
  sorting: 1, // price asc, then price desc equals 2
  filters: {
    selectedCategory: '',
    selectedSubCategory: '',
    selectedBrand: '',
    selectedPriceRange: [],
    selectedCode: '',
    selectedIsActive: '',
    selectedIsNew: '',
  },
  catalog: {
    pagination: {
      total: 0,
      currentPage: 1,
      perPage: process.env.REACT_APP_PER_PAGE,
    },
    results: [],
  },
};

const productsReducer = createReducer(initialState, {
  [setProductImage.type]: (state, action) => ({
    ...state,
    productImage: action.payload,
  }),
  [setAllProducts.type]: (state, action) => ({
    ...state,
    allProducts: action.payload,
  }),
  [setSelectedProductsExtra.type]: (state, action) => ({
    ...state,
    productPopupDetails: {
      ...state?.productPopupDetails,
      [action?.payload?.field]: action?.payload?.value,
    },
  }),
  [setProductsPopupSubCategories.type]: (state, action) => ({
    ...state,
    productPopupDetailsExtra: {
      ...state?.productPopupDetailsExtra,
      subCategories: action?.payload,
    },
  }),
  [setProductsPopupBrands.type]: (state, action) => ({
    ...state,
    productPopupDetailsExtra: {
      ...state?.productPopupDetailsExtra,
      brands: action?.payload,
    },
  }),
  [setProductsPopupCategories.type]: (state, action) => ({
    ...state,
    productPopupDetailsExtra: {
      ...state?.productPopupDetailsExtra,
      categories: action?.payload,
    },
  }),
  [setProductPopup.type]: (state, action) => ({
    ...state,
    productPopupDetails: action.payload,
  }),
  [setProductPopupDetailsValue.type]: (state, action) => ({
    ...state,
    productPopupDetails: {
      ...state?.productPopupDetails,
      product: {
        ...state?.productPopupDetails.product,
        [action.payload.field]: action.payload.value,
      },
    },
  }),
  [setProductPage.type]: (state, action) => ({
    ...state,
    singleProduct: action.payload,
  }),
  [setFilterCategories.type]: (state, action) => ({
    ...state,
    filterCategories: action.payload,
  }),
  [setFilterSubCategories.type]: (state, action) => ({
    ...state,
    filterSubCategories: action.payload,
  }),
  [productsFiltersSearch.type]: (state, action) => ({
    ...state,
    filters: {
      ...action.payload,
    },
    catalog: {
      ...state.catalog,
      pagination: {
        ...state.catalog.pagination,
        currentPage: 1,
        total: 0,
      },
    },
  }),
  [setProducts.type]: (state, action) => ({
    ...state,
    catalog: {
      ...state.catalog,
      results: action.payload.results,
      pagination: {
        ...state.catalog.pagination,
        total: action.payload.total,
      },
    },
  }),
  [removeSelectedFilter.type]: (state, action) => ({
    ...state,
    filters: {
      ...state?.filters,
      [action.payload.type]: null,
    },
    catalog: {
      ...state.catalog,
      pagination: {
        ...state.catalog.pagination,
        currentPage: 1,
        total: 0,
      },
    },
  }),
  [removeSelectedCodeFilter.type]: (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      selectedCode: '',
    },
    catalog: {
      ...state.catalog,
      pagination: {
        ...state.catalog.pagination,
        currentPage: 1,
        total: 0,
      },
    },
  }),
  [setFilterBrands.type]: (state, action) => ({
    ...state,
    filterBrands: action.payload,
  }),
  [setInitialPricesRange.type]: (state, action) => ({
    ...state,
    filterPricesRange: action.payload,
  }),
  [setProductsSorting.type]: (state, action) => ({
    ...state,
    sorting: action.payload,
    catalog: {
      ...state.catalog,
      pagination: {
        ...state.catalog.pagination,
        currentPage: 1,
        total: 0,
      },
    },
  }),
  [setCurrentProductsPage.type]: (state, action) => ({
    ...state,
    catalog: {
      ...state.catalog,
      pagination: {
        ...state.catalog.pagination,
        currentPage: action.payload,
      },
    },
  }),
  [resetState.type]: (state, action) => ({ ...initialState }),
});

export default productsReducer;
