const loading = ({ generalReducer }) => generalReducer?.loading;
const alertMessage = ({ generalReducer }) => generalReducer.message;
const alertType = ({ generalReducer }) => generalReducer.type;
const alertShow = ({ generalReducer }) => generalReducer.show;
// CATEGORIES
const allCategories = ({ generalReducer }) => generalReducer.categories;
const allSubCategoriesForCategoryId = ({ generalReducer }) =>
  generalReducer.categories?.subCategoriesForCategoryId;
const subCategorySearchText = ({ generalReducer }) =>
  generalReducer.categories?.subCategorySearchText;
const allAvailableSubCategories = ({ generalReducer }) =>
  generalReducer.categories?.availableSubCategories;

// SUBCATEGORIES
const allSubCategories = ({ generalReducer }) => generalReducer.subCategories;

// BRANDS
const allBrands = ({ generalReducer }) => generalReducer.brands;

export {
  alertMessage,
  alertType,
  alertShow,
  loading,
  // CATEGORIES
  allCategories,
  allSubCategoriesForCategoryId,
  subCategorySearchText,
  allAvailableSubCategories,
  // SUBCATEGORIES
  allSubCategories,
  // BRANDS
  allBrands,
};
