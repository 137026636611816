import makeRequest from 'library/makeRequest';
import {
  toggleShowAlert,
  setGeneralLoading,
} from 'models/actions/generalActions';
import {
  loginUser,
  setLoggedInUser,
  logoutUser,
  resetState,
  getUsers,
  setAllUsers,
  userFiltersSearch,
  setCurrentPagesPage,
  removeSelectedFilter,
  removeUser,
  updateUser,
  emptyAction,
} from 'models/actions/userActions';
import { token } from 'models/selectors/userSelectors';
import { ofType, combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, concatMap, withLatestFrom } from 'rxjs/operators';

import catchErrorOperator from './operators/catchErrorOperator';

const loginUserEpic = (action$) =>
  action$.pipe(
    ofType(loginUser.type),
    mergeMap(({ payload }) =>
      from(makeRequest('login/admin', 'POST', JSON.stringify(payload))).pipe(
        concatMap((payload) => [
          toggleShowAlert({
            message: ``,
            type: 'error',
            show: false,
          }),
          setGeneralLoading(false),
          setLoggedInUser(payload),
        ]),
        catchErrorOperator(true),
      ),
    ),
  );

const logoutUserEpic = (action$) =>
  action$.pipe(
    ofType(logoutUser.type),
    concatMap(() => [
      setGeneralLoading(false),
      setLoggedInUser({}),
      resetState(),
    ]),
  );

const getUsersEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      getUsers.type,
      userFiltersSearch.type,
      setCurrentPagesPage.type,
      removeSelectedFilter.type,
    ),
    withLatestFrom(state$),
    mergeMap(
      ([
        ,
        {
          userReducer: {
            usersSelectedFilters: { email, isActive, birthDate },
            users: {
              pagination: { currentPage },
            },
          },
        },
      ]) =>
        from(
          makeRequest(
            `users/admin/all?birthDate=${birthDate}&email=${email}&isActive=${isActive}&page=${Number(
              currentPage,
            )}`,
            'GET',
            '',
            token(state$.value),
          ),
        ).pipe(
          concatMap((payload) => {
            if (payload?.error) {
              return [
                toggleShowAlert({
                  message: `${payload?.error}`,
                  type: 'error',
                  show: true,
                }),
                setGeneralLoading(false),
              ];
            }

            return [setAllUsers(payload), setGeneralLoading(false)];
          }),
          catchErrorOperator(true),
        ),
    ),
  );

const removeUserEpic = (action$, state$) =>
  action$.pipe(
    ofType(removeUser.type),
    mergeMap(({ payload }) =>
      from(
        makeRequest(
          'users/admin/delete',
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload?.error}`,
                type: 'error',
                show: true,
              }),
              setGeneralLoading(false),
            ];
          }

          return [getUsers(), setGeneralLoading(false)];
        }),
        catchErrorOperator(true),
      ),
    ),
  );

const updateUserEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateUser.type),
    mergeMap(({ payload }) => {
      let apiUrl = 'users/admin/update/setadmin';

      if (payload?.isAdministrator) {
        apiUrl = 'users/admin/update/changeadminpassword';
      }

      return from(
        makeRequest(
          apiUrl,
          'POST',
          JSON.stringify(payload),
          token(state$.value),
        ),
      ).pipe(
        concatMap((payload) => {
          if (payload?.error) {
            return [
              toggleShowAlert({
                message: `${payload?.error}`,
                type: 'error',
                show: true,
              }),
              setGeneralLoading(false),
            ];
          }

          return [
            setGeneralLoading(false),
            toggleShowAlert({
              message: `${payload?.message}`,
              type: 'success',
              show: true,
            }),
            !payload?.mustRelogin ? getUsers() : emptyAction(),
            payload?.mustRelogin ? logoutUser() : emptyAction(),
          ];
        }),
        catchErrorOperator(true),
      );
    }),
  );

export {
  loginUserEpic,
  logoutUserEpic,
  getUsersEpic,
  removeUserEpic,
  updateUserEpic,
};

const epics = combineEpics(
  loginUserEpic,
  logoutUserEpic,
  getUsersEpic,
  removeUserEpic,
  updateUserEpic,
);

export default epics;
