const offerTitle = ({ settingsReducer }) =>
  settingsReducer?.settings?.offerTitle;
const singleImage = ({ settingsReducer }) =>
  settingsReducer?.settings?.singleImage;
// LOGO
const logoSettings = ({ settingsReducer }) => settingsReducer?.settings?.logo;

// COUNTRIES
const countriesSettings = ({ settingsReducer }) =>
  settingsReducer?.settings?.countries;

// PREFECTURES
const prefecturesSettings = ({ settingsReducer }) =>
  settingsReducer?.settings?.prefectures;

// COUNTRIES AND PREFECTURES LIST
const countriesWithPrefectures = (state) =>
  countriesSettings(state)?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr?.country_code]: {
        ...curr,
        prefectures: prefecturesSettings(state)?.filter(
          (prefecture) => prefecture?.country_id === curr?.id,
        ),
      },
    }),
    {},
  );

// PAYMENT
const paymentSettings = ({ settingsReducer }) =>
  settingsReducer?.settings?.payment;

// SHIPPING
const shippingSettings = ({ settingsReducer }) =>
  settingsReducer?.settings?.shipping;

// HOMETABS
const homeTabsSettings = ({ settingsReducer }) =>
  settingsReducer?.settings?.homeTabs;

// HOMESECTIONS
const homeSectionsSettings = ({ settingsReducer }) =>
  settingsReducer?.settings?.homeSections;

// HOMEBANNERS
const homeBannersSettings = ({ settingsReducer }) =>
  settingsReducer?.settings?.homeBanners;

const homeBannerImage = ({ settingsReducer }) =>
  settingsReducer.homeBannerImage;

// HOMELASTBANNERS
const homeLastBannersSettings = ({ settingsReducer }) =>
  settingsReducer?.settings?.homeLastBanners;

const homeLastBannerImage = ({ settingsReducer }) =>
  settingsReducer.homeLastBannerImage;

// KEYWORDS
const keyWordsSettings = ({ settingsReducer }) =>
  settingsReducer.settings.keyWords;

// COUPONS
const couponsSettings = ({ settingsReducer }) =>
  settingsReducer.settings.coupons;

// COUPONS
const newsletterCouponsSettings = ({ settingsReducer }) =>
  settingsReducer.settings.newsletterCoupon;

export {
  singleImage,
  offerTitle,
  logoSettings,
  countriesWithPrefectures,
  // COUNTRIES
  countriesSettings,
  // PREFECTURES
  prefecturesSettings,
  // PAYMENT
  paymentSettings,
  // SHIPPING
  shippingSettings,
  // HOMETABS
  homeTabsSettings,
  // HOMESECTIONS
  homeSectionsSettings,
  // HOMEBANNERS
  homeBannersSettings,
  homeBannerImage,
  // HOMELASTBANNERS
  homeLastBannersSettings,
  homeLastBannerImage,
  // KEYWORDS
  keyWordsSettings,
  // COUPONS
  couponsSettings,
  // NEWSLETTERCOUPON
  newsletterCouponsSettings,
};
