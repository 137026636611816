import { combineEpics } from 'redux-observable';

import dashboardEpics from './dashboardEpics';
import generalEpics from './generalEpics';
import orderEpics from './orderEpics';
import productsEpics from './productsEpics';
import settingsEpics from './settingsEpics';
import staticEpics from './staticEpics';
import userEpics from './userEpics';

const rootEpics = combineEpics(
  userEpics,
  orderEpics,
  productsEpics,
  dashboardEpics,
  generalEpics,
  settingsEpics,
  staticEpics,
);

export default rootEpics;
